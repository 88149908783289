<script lang="ts">
    export let name: string;
    export let options: [string, string][];

    export let value = ''

    export let change: () => void = () => {

    }

    function onChange(e) {
        if (value === e.target.value) {
            value = ''
        } else {
            value = e.target.value
        }
        change()
    }
</script>

<div class="RadioGroup" class:check="{ options.length === 1 }" >
    {#each options as [current_value, label] }
        <label class:active="{ value === current_value }" >
            <input 
                type='radio'
                value={current_value}
                on:click={onChange}
                checked={current_value === value}
                name={name} />
            <div class="indicator">
                <div class="detail"></div>
            </div>
            {label} 
        </label>
    {/each}
</div>
