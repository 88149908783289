<script lang="ts">
	import { onMount, createEventDispatcher } from "svelte";
	import { api } from "../../Api";

	import Loader from "../../components/ui/Loader.svelte";
	import Table from "../../components/ui/Table.svelte";
	import Title from "../../components/ui/Title.svelte";
	import Paginator from "../../components/ui/Paginator.svelte";
	import Empty from "../../components/ui/Empty.svelte";

	import { TIPO_ESQUEMA, CODIGO_TIPO_ESQUEMA } from "../../Api/objects";
	import type { schema, viewSchema } from "../../Api/objects";
	import type { PaginatorSchema } from "../../components/ui/Paginator.svelte";
	import { navigate } from "svelte-navigator";
	import OperationalPopup from "../../components/ui/OperationalPopup.svelte";

	const repo = api();
	let selectedRows = 10;
	let loading = false;
	let schema: PaginatorSchema = { page: 1, pages: 0, total: 0 };
	let list_forms: viewSchema[] = [];

	// Para manejar el popup de cuando se borra una declaracion pre llenada
	let show_warning: boolean = false;
	let folioDummy: String = "";
	let message: string = "Se eliminará permanente este esquema de los borradores.\n¿Desea continuar?"

	const onSchemaChange = (updatedSchema: PaginatorSchema) => {
		loading = true;
		schema = updatedSchema;
		fetchSchemas();
	};

	const onRowsChange = (nRows) => {
		if (selectedRows !== nRows) {
			loading = true;
			selectedRows = nRows;
			fetchSchemas();
		}
	};

	// Usar la API para traer todos los esquemas
	const fetchSchemas = () => {
		loading = true;
		repo
			.get_all_forms_schema(null, null, 2024, schema.page, selectedRows)
			.then((data) => {
				// console.log(data);
				list_forms = data.results;
				schema = data.pager;
				loading = false;
			})
			.catch((err) => {
				loading = false;
			});
	};

	// Abrir un esquema segun su folio y su tipo
	const open_form = (folio: string, tipo_esquema: string) => {
		switch (tipo_esquema) {
			case TIPO_ESQUEMA.ENR:
				navigate(`/enr/${folio}`);
				break;
			case TIPO_ESQUEMA.ERA:
				navigate(`/era/${folio}`);
				break;
			case TIPO_ESQUEMA.ERG:
				navigate(`/erg/${folio}`);
				break;
			case TIPO_ESQUEMA.ERP:
				navigate(`/erp/${folio}`);
				break;
			default:
				alert("¿No entiendo que quieres hacer?");
				break;
		}
	};

	// Desencadenar la funcionalidad de eliminar una declaración
	const delete_form = (folio: string) => {
		folioDummy = folio;
		show_warning = true;
	};

	// Confirmar la eliminación de la declaración de las pendientes
	const confirmDelete = () => {
		api()
			.delete_form(String(folioDummy))
			.then(
				(_) => {
					fetchSchemas();
					show_warning = false
					folioDummy = "";
				},
				(reject) => {
					// console.log(reject);
				}
			);
	}
	
	// Cancelar la acción de eliminar la declaración
	const cancelDelete = () => {
		show_warning = false
	}

	onMount(() => {
		fetchSchemas();
	});
</script>

<OperationalPopup show={show_warning} on:closeIt={cancelDelete}>
	<div style="display: flex; justify-content: center">
		{message}
	</div>
	<div style="margin: 20px 0 20px 0;" />
	<div style="display: flex; justify-content: center; gap: 8%">
		<button on:click={confirmDelete}>Confirmar</button>
		<button on:click={cancelDelete}>Cancelar</button>
	</div>
</OperationalPopup>

<div id="CurrentStatement">
	<Title icon="fas fa-adjust">Declaraciones y constancias pendientes</Title>
	{#if loading}
		<div class="fewContent">
			<Loader />
		</div>
	{:else if list_forms.length > 0}
		<div class="table">
			<Table>
				<tr slot="header">
					<th> Tipo de declaración </th>
					<th> Tipo de esquema </th>
					<th> Fecha de inicio </th>
					<th> RFC de quién la presenta </th>
					<th> Acciones </th>
				</tr>
				{#each list_forms as schema}
					<tr>
						<td> {schema._tipo_declaracion} </td>
						<td> {schema._tipo_esquema} </td>
						<td> {new Date(schema._fecha_registro).toLocaleDateString()} </td>
						<td>{schema._rfc} </td>
						<td style="display: flex; justify-content: space-evenly;">
							<div
								class="down-button"
								style="cursor: pointer;"
								on:click={ () => open_form(schema._folio_declaracion, schema._codigo_esquema) }
							>
								<i class="fas fa-arrow-right" />
								<div class="button-label">Continuar</div>
							</div>
							<div
								class="down-button"
								style="cursor: pointer;"
								on:click={() => {
									delete_form(schema._folio_declaracion)
								}}
							>
								<i class="fas fa-trash-alt" />
								<div class="button-label">Eliminar</div>
							</div>
						</td>
					</tr>
				{/each}
			</Table>

			<Paginator {schema} {selectedRows} {onSchemaChange} {onRowsChange} />
		</div>
	{:else}
		<div class="fewContent">
			<Empty>No se encontraron registros</Empty>
		</div>
	{/if}
</div>

<style>
	.table :global(.Table) {
		margin-top: 70px;
		margin-bottom: 70px;
	}

	.down-button {
		transition-duration: 300ms;
	}

	.down-button:hover {
		color: #000000;
	}

	.fewContent {
		padding: 15em;
	}
</style>
