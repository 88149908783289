<script lang="ts">
    // Callback function for when a file is meant to be recovered. The error is handled as
    // in on_file_upload.
    export let on_recover_file: (id: string) => Promise<null | string>;

    export let deal_with_file: (e: Event, hash_object: any, hash_field_name: string, field_id: string) => Promise<void>;

    export let hash_object: any;
    export let hash_field_name: string;
    export let field_id: string;
    export let accept: string;
    export let label: string;
    export let valid: boolean = false;
</script>

<div class="input-container">
    <label for="dummy">{#if valid}<i class="fas fa-check-circle" style="margin-right: 5px; color: #50a829;"></i>{/if}{label}</label>
    <div class="InputFile">
        <label for="dummy">
            <input on:change={(e) => deal_with_file(e, hash_object, hash_field_name, field_id)} required={true} accept={accept} type="file"/>
        </label>
        <div class="actions">
            <div class="tooltip">
                <div class="show Button small">
                    <i class="far fa-file-alt"></i>
                </div>
                <div class="bubble">{`SHA-256 del documento en base 64: ${hash_object[hash_field_name]["hash"]}`}</div>
            </div>
            <div on:click={() => on_recover_file(field_id)} class="Button small">
                <i class="fas fa-file-download"></i> Visualizar
            </div>
        </div>
    </div>
</div>