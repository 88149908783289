<script lang="ts">
    import { api } from "./Api";
    import Navbar from './components/ui/Navbar.svelte';
    import type { OptionsData } from './components/ui/Navbar.svelte';

    let navbarOptions: Array<OptionsData> = [
        { display: 'Iniciar Sesión' }
    ];

    let navigatorHandler = (optionText: string) => {
        if(optionText === "Iniciar Sesión") {
            api().do_oauth()
        }
    }
</script>

<div style="height:100%;">
    <Navbar onClick={navigatorHandler} options={navbarOptions} />
    <div class="main">
        <div class="subtitle">
            Presenta tu declaración!
        </div>
    </div>
</div>

<style>
    .main {
        display:flex;
        height:100%;
        justify-content: center;
        align-items: center;
    }
</style>