<!--D Nabvar de las pantallas  -->
<script lang="ts" context="module">
	export type NavbarData = {
		name: string;
		rfc: string;
	};

	export type OptionsData = {
		display: string;
		sub?: Array<OptionsData>;
		icon?: string;
	};
</script>

<script lang="ts">
	import { onMount } from "svelte";
	import { api } from "../../Api";

	export let data: NavbarData | undefined = null; // Data del usuario logeado
	export let options: Array<OptionsData> = []; // Lista de opciones a mostar como entradas del navbar
	export let onClick = (e) => {
		// console.log(e)
	}; // Callback para el click en opciones

	let version: string | undefined = undefined;

	// onMount(async () => {
	// 	try {
	// 		version = await api().get_version();
	// 	} catch (error) {
	// 		console.log(error);
	// 	}
	// });

	import * as pjson from "../../../package.json";
</script>

<div class="Navbar">
	<img class="logo" src="/front_src_img_logos.svg" alt="logo de hacienda" />
	<navbar>
		<div style="padding: 0 0 0 16px; font-size: 12px;">v{pjson["version"]}</div>
		<!-- <div style="padding: 0 0 0 16px; font-size: 12px;">{version ? `v${version}` : "Recuperando version"}</div> -->
		<div style="display: flex;  ">
			{#each options as option}
				<div
					class="level1"
					on:click={() => {
						onClick(option.display);
					}}
				>
					{#if option.icon}
						<i class={option.icon} />
					{/if}
					{option.display}

					{#if option.sub}
						<div class="suboptions">
							{#each option.sub as sub}
								<div
									class="suboption"
									on:click|stopPropagation={() => {
										onClick(sub.display);
									}}
								>
									{#if sub.icon}
										<i class={sub.icon} />
									{/if}
									{sub.display}
								</div>
							{/each}
						</div>
					{/if}
				</div>
			{/each}
			{#if data}
				<div class="user-data">
					<div class="data">
						<div>{data.name}</div>
						<div class="rfc">{data.rfc}</div>
					</div>
					<i class="fas fa-genderless" />
				</div>
			{/if}
		</div>
	</navbar>
</div>

<!--E
<Navbar options={[{ display: 'Iniciar sesión' }]} onClick={ (se) => alert(se) } />
<br>
<br>
<Navbar
	data={{ name: 'Carlos Malanche Flores', rfc: 'MAFC930522JT7' }}
	options={[
		{ display: 'Inicio' },
		{ display: 'Declaraciones', 
			sub: [ 
				{display: 'Nueva', icon: 'fas fa-plus-circle'},
				{display: 'Pendientes', icon: 'fas fa-adjust'},
				{display: 'Pasadas', icon: 'fas fa-history'}
			] 
		},
		{ display: 'Cerrar sesión' },
	]} onClick={ (se) => alert(se) }  
/>

<br>
<br>
<br>
<br>
-->
