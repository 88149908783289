import Troncal from "./troncal";
import "jspdf-autotable";
export default class Certificado extends Troncal {
    constructor(data) {
        super();
        if (data.rfc.length == 13 && data.nombre == undefined) {
            throw "Se necesita el nombre";
        }
        if (data.rfc.length == 12 && data.denominacion_social == undefined) {
            throw "Se necesita la denominación social";
        }
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        let today = new Date();
        this.text_ext(`Fecha de emisión: ${today.toLocaleDateString("es-MX", options)}`, {
            align: "center",
            fontSize: 9,
            fontName: "Montserrat",
            fontStyle: "normal"
        });
        this.jump(7);
        this.text_ext("CERTIFICADO DEL NÚMERO DE IDENTIFICACIÓN DEL ESQUEMA REPORTABLE REVELADO", {
            align: "center",
            fontSize: 12,
            fontName: "Montserrat",
            fontStyle: "bold"
        });
        this.y += 5;
        this.jump(7);
        this.setFont("Montserrat", "normal");
        this.setFontSize(7);
        this.text_ext(`Por medio del presente, se le informa que al esquema reportable revelado a través de la declaración informativa con número de operación ${data.num_operacion}, le fue asignado el siguiente número de identificación fiscal:`, {
            align: "center",
            fontSize: 12,
            fontName: "Montserrat",
            fontStyle: "normal"
        });
        this.y += 5;
        this.jump(15);
        this.text_ext(`${data.id_operacion}`, {
            align: "center",
            fontSize: 12,
            fontName: "Montserrat",
            fontStyle: "bold"
        });
        this.y += 5;
    }
}
