<script lang="ts">
	import { onMount } from "svelte";
	import { api } from "../../Api";

	import Loader from "../../components/ui/Loader.svelte";
	import Table from "../../components/ui/Table.svelte";
	import Title from "../../components/ui/Title.svelte";
	import Paginator from "../../components/ui/Paginator.svelte";
	import Empty from "../../components/ui/Empty.svelte";
	import { RESOURCE_MIME } from "../../Api/apicore";

	import { TIPO_ESQUEMA, CODIGO_TIPO_ESQUEMA } from "../../Api/objects";
	import type { schema, viewSchema } from "../../Api/objects";
	import type { PaginatorSchema } from "../../components/ui/Paginator.svelte";

	import Copia from "../../components/pdfs/copia";
	import Acuse from "../../components/pdfs/acuse";
	import Constancia from "../../components/pdfs/constancia";
	import Certificado from "../../components/pdfs/certificado";
	import type * as cs from "cryptosat-ts";

	// Necesarios para crear la copia del esquema
	import { write as writeENR } from "../../components/enr/pdf";
	import { write as writeERA } from "../../components/era/pdf";
	import { write as writeERG } from "../../components/erg/pdf";
	import { write as writeERP } from "../../components/erp/pdf";

	const repo = api();
	let selectedRows = 10;
	let loading = false;
	let schema: PaginatorSchema = { page: 1, pages: 0, total: 0 };
	let list_schema: viewSchema[] = [];

	// Agregamos la variable para poder agregar las ultimas validaciones
	let cadenaFirmada: string;
	let cadenaOriginal: string;

	const onSchemaChange = (updatedSchema: PaginatorSchema) => {
		loading = true;
		schema = updatedSchema;
		fetchSchemas();
	};

	const onRowsChange = (nRows) => {
		if (selectedRows !== nRows) {
			loading = true;
			selectedRows = nRows;
			fetchSchemas();
		}
	};

	/* Función para obtener todas las declaraciones ya firmadas de la base de dato*/
	const fetchSchemas = () => {
		loading = true;
		repo
			// .get_all_tax(null, null, 2022, schema.page, selectedRows)
			.get_all_tax_schema(null, null, 2024, schema.page, selectedRows)
			.then((data) => {
				list_schema = data.results;
				schema = data.pager;
				loading = false;
			})
			.catch((err) => {
				// console.log(err);
				loading = false;
			});
	};

	/* Función para poder descargar el Acuse, Copia o Constancia en PDF, según los parámetros ingresados */
	const download_tax = (
		schema: viewSchema,
		folio: string,
		downloadType: string,
		codigo_tipo_esquema: string,
		nier?: string,
		ejercicio?: string,
		rfc?: string,
		complementaria?: string,
		cadenaFirmada?: string,
		cadenaOriginal?: string,
	) => {
		repo.get_form(folio).then((jsonForm) => {
			// jsonForm ya es el JsonableMap/cs.SecureMap que escribe un archivo PDF
			if (downloadType == "acuse") {
				create_from_json(schema, folio, downloadType, codigo_tipo_esquema, nier, ejercicio, rfc, complementaria, cadenaFirmada, cadenaOriginal);
			} else if (downloadType == "constancia") {
				create_from_json(schema, folio, downloadType, codigo_tipo_esquema, nier, ejercicio, rfc, complementaria, cadenaFirmada, cadenaOriginal);
			} else if (downloadType == "certificado") {
				create_from_json(schema, folio, downloadType, codigo_tipo_esquema, nier, ejercicio, rfc, complementaria, cadenaFirmada, cadenaOriginal);
			}
		});
	};

	/* Nombre del usuario que presenta las declaraciones */
	let name: string = "";

	// Esta funcion tiene parametros adicionales que no son usados por estar en una version de prueba
	async function create_from_json(
		schema: viewSchema,
		folio_esquema: string,
		downloadType: string,
		codigo_tipo_esquema: string,
		nier?: string,
		ejercicio?: string,
		rfc?: string,
		complementaria?: string,
		cadenaFirmada?: string,
		cadenaOriginal?: string
	) {
		let user_info = await api().user_info();
		let tipo_esquema: string
		name = user_info.nombre;
		let jsonableString: cs.SecureMap = await api().get_form(folio_esquema);

		if (codigo_tipo_esquema == "ERG") {
			tipo_esquema = "Generalizado"
		} else if (codigo_tipo_esquema == "ERP") {
			tipo_esquema = "Personalizado" 
		} else if (codigo_tipo_esquema == "ERA") {
			tipo_esquema = "Declaración Anual"
		} else if (codigo_tipo_esquema == "ENR") {
			tipo_esquema = "No Reportable"
		}

		if (downloadType == "acuse") {

			let doc = new Acuse({
				rfc: user_info.rfc,
				denominacion_social: user_info.rfc.length == 12 ? user_info.nombre : undefined,
				nombre: user_info.rfc.length == 13 ? user_info.nombre : undefined,
				tipo: "Normal",
				esquema: tipo_esquema,
				fecha: new Date(schema._fecha_registro),
				cadena: cadenaOriginal,
				num_operacion: folio_esquema,
				id_operacion: nier,
				ejercicio: ejercicio,
				firma: cadenaFirmada
			});

			doc.agrega_qr("https://esquemas.sat.gob.mx");
			doc.printHeader(false);
			doc.save(`${downloadType}_${schema._codigo_esquema}.pdf`);

		} else if (downloadType == "constancia") {
			let doc = new Constancia({
				rfc: user_info.rfc,
				denominacion_social: user_info.rfc.length == 12 ? user_info.nombre : undefined,
				nombre: user_info.rfc.length == 13 ? user_info.nombre : undefined,
				tipo: "Normal",
				esquema: tipo_esquema,
				fecha: new Date(schema._fecha_registro),
				cadena: cadenaOriginal,
        		num_operacion: folio_esquema,
				id_operacion: nier,
				firma: cadenaFirmada
			});
			writeENR(doc, jsonableString)
			doc.agrega_qr("https://esquemas.sat.gob.mx");
			doc.printHeader(false);
			doc.save(`${downloadType}_${schema._codigo_esquema}.pdf`);

		} else if (downloadType == "certificado") {
			let doc = new Certificado({
				rfc: user_info.rfc,
				denominacion_social: user_info.rfc.length == 12 ? user_info.nombre : undefined,
				nombre: user_info.rfc.length == 13 ? user_info.nombre : undefined,
				tipo: "Normal",
				esquema: tipo_esquema,
				fecha: new Date(schema._fecha_registro),
				cadena: cadenaOriginal,
        		num_operacion: folio_esquema,
				id_operacion: nier,
				firma: cadenaFirmada
			});
			doc.printHeader(false);
			doc.save(`${downloadType}_${schema._codigo_esquema}.pdf`);
		} 
	}

	async function download_Copia(
		schema: viewSchema,
		folio_esquema: string,
		tipo_esquema: string,
		codigo_tipo_esquema: string,
		nier?: string,
		ejercicio?: string,
		previous?: boolean,
		rfc?: string,
		complementaria?: string
	) {
		let user_info = await api().user_info();
		name = user_info.nombre;
		let jsonableString: cs.SecureMap = await api().get_form(folio_esquema);

		if (codigo_tipo_esquema == "ERG") {
			tipo_esquema = "Generalizado"
		} else if (codigo_tipo_esquema == "ERP") {
			tipo_esquema = "Personalizado" 
		} else if (codigo_tipo_esquema == "ERA") {
			tipo_esquema = "Declaración Anual"
		} else if (codigo_tipo_esquema == "ENR") {
			tipo_esquema = "No Reportable"
		}

		let doc = new Copia({
			rfc: user_info.rfc,
			denominacion_social: user_info.rfc.length == 12 ? user_info.nombre : undefined,
			nombre: user_info.rfc.length == 13 ? user_info.nombre : undefined,
			tipo: "Normal",
			esquema: tipo_esquema,
			fecha: new Date(schema._fecha_registro),
			cadena: cadenaOriginal,
      		num_operacion: folio_esquema,
			id_operacion: nier,
			ejercicio: ejercicio,
			copia: true,
			firma: "cadenaFirmada",
			previous: previous,
		});

		switch (codigo_tipo_esquema) {
			case TIPO_ESQUEMA.ENR:
				writeENR(doc, jsonableString);
				break;
			case TIPO_ESQUEMA.ERA:
				writeERA(doc, jsonableString);
				break;
			case TIPO_ESQUEMA.ERG:
				writeERG(doc, jsonableString);
				break;
			case TIPO_ESQUEMA.ERP:
				writeERP(doc, jsonableString);
				break;
			default:
				alert(`Descargando la copia`);
				break;
		}
		doc.agrega_qr("https://esquemas.sat.gob.mx");
		doc.printHeader(false);
		doc.save(`Copia_${schema._codigo_esquema}.pdf`);
	}

	onMount(() => {
		fetchSchemas();
	});
</script>

<div id="PreviousStatement">
	<Title icon="fas fa-history">Declaraciones y constancias presentadas</Title>
	{#if loading}
		<div class="fewContent">
			<Loader />
		</div>
	{:else if list_schema.length > 0}
		<div class="table" style="width:100%">
			<Table>
				<tr slot="header">
					<th style="width:20%"> Documento </th>
					<th> Número de identificación </th>
					<th> Número de operación </th>
					<th> Fecha de presentación </th>
					<th style="width:10%"> RFC </th>
					<th> Acciones (Descarga)</th>
				</tr>
				{#each list_schema as schema}
					<tr>
						{#if schema._codigo_esquema == "ENR"}
							<td> Constancia de Esquema no reportable </td>
						{:else if schema._codigo_esquema == "ERP" || schema._codigo_esquema == "ERG"}
							{#if schema._tipo_declaracion == "Complementaria"}
								<td>Declaración informativa complementaria para revelar un {schema._codigo_esquema}</td>
							{:else}
								<td>Declaración informativa para revelar un {schema._codigo_esquema}</td>
							{/if}
						{:else if schema._codigo_esquema == "ERA"}
							{#if schema._tipo_declaracion == "Complementaria"}
								<td>Declaración anual complementaria</td>
							{:else}
								<td>Declaración anual</td>
							{/if}
						{/if}

						<td> { schema._codigo_esquema == "ERA" ? "N/A" : schema._codigo_esquema == "ENR" ? "N/A" : schema._numero_identificacion_esquema} </td>
						<td> {schema._folio_declaracion} </td>
						<td> {new Date(schema._fecha_registro).toLocaleDateString()} </td>
						<td> {schema._rfc} </td>
						<td style="display: flex; justify-content: center; gap: 10%">
							{#if schema._codigo_esquema == "ENR"}
								<div
									class="down-button"
									style="cursor: pointer;"
									on:click={() => {
										download_tax(
											schema,
											schema._folio_declaracion,
											"constancia",
											schema._codigo_esquema,
											schema._numero_identificacion_esquema,
											"no-ejercicio",
											schema._rfc,
											schema._tipo_declaracion,
											schema._cadena_firmada,
											schema._cadena_original
										);
									}}
								>
									<i class="fas fa-download" />
									<div class="button-label">Constancia</div>
								</div>
							{:else if schema._codigo_esquema == "ERP" || schema._codigo_esquema == "ERG"}
								{#if schema._tipo_declaracion == "Complementaria"}
									<div
										class="down-button"
										style="cursor: pointer;"
										on:click={() => {
											download_tax(
												schema,
												schema._folio_declaracion,
												"acuse",
												schema._codigo_esquema,
												schema._numero_identificacion_esquema,
												"no-ejercicio",
												schema._rfc,
												schema._tipo_declaracion,
												schema._cadena_firmada,
												schema._cadena_original
											);
										}}
									>
										<i class="fas fa-download" />
										<div class="button-label">Acuse</div>
									</div>
									<div
										class="down-button"
										style="cursor: pointer;"
										on:click={() => {
											download_Copia(
												schema,
												schema._folio_declaracion,
												schema._tipo_esquema,
												schema._codigo_esquema,
												schema._numero_identificacion_esquema,
												"no-ejercicio",
												true,
												schema._rfc,
												schema._tipo_declaracion
											);
										}}
									>
										<i class="fas fa-download" />
										<div class="button-label">Copia</div>
									</div>
								{:else}
									<div
										class="down-button"
										style="cursor: pointer;"
										on:click={() => {
											// console.log(schema._cadena_firmada)
											download_tax(
												schema,
												schema._folio_declaracion,
												"acuse",
												schema._codigo_esquema,
												schema._numero_identificacion_esquema,
												"no-ejercicio",
												schema._rfc,
												schema._tipo_declaracion,
												schema._cadena_firmada,
												schema._cadena_original
											);
										}}
									>
										<i class="fas fa-download" />
										<div class="button-label">Acuse</div>
									</div>
									<div
										class="down-button"
										style="cursor: pointer;"
										on:click={() => {
											download_tax(
												schema,
												schema._folio_declaracion,
												"certificado",
												schema._codigo_esquema,
												schema._numero_identificacion_esquema,
												"no-ejercicio",
												schema._rfc,
												schema._tipo_declaracion,
												schema._cadena_firmada,
												schema._cadena_original
											);
										}}
									>
										<i class="fas fa-download" />
										<div class="button-label">Certificado</div>
									</div>
									<div
										class="down-button"
										style="cursor: pointer;"
										on:click={() => {
											download_Copia(
												schema,
												schema._folio_declaracion,
												schema._tipo_esquema,
												schema._codigo_esquema,
												schema._numero_identificacion_esquema,
												"no-ejercicio",
												true,
												schema._rfc,
												schema._tipo_declaracion
											);
										}}
									>
										<i class="fas fa-download" />
										<div class="button-label">Copia</div>
									</div>
								{/if}
							{:else if schema._codigo_esquema == "ERA"}
								{#if schema._tipo_declaracion == "Complementaria"}
									<div
										class="down-button"
										style="cursor: pointer;"
										on:click={() => {
											download_tax(
												schema,
												schema._folio_declaracion,
												"acuse",
												schema._codigo_esquema,
												schema._numero_identificacion_esquema,
												schema._ejercicio,
												schema._rfc,
												schema._tipo_declaracion,
												schema._cadena_firmada,
												schema._cadena_original
											);
										}}
									>
										<i class="fas fa-download" />
										<div class="button-label">Acuse</div>
									</div>
									<div
										class="down-button"
										style="cursor: pointer;"
										on:click={() => {
											download_Copia(
												schema,
												schema._folio_declaracion,
												schema._tipo_esquema,
												schema._codigo_esquema,
												schema._numero_identificacion_esquema,
												schema._ejercicio,
												true,
												schema._rfc,
												schema._tipo_declaracion
											);
										}}
									>
										<i class="fas fa-download" />
										<div class="button-label">Copia</div>
									</div>
								{:else}
									<div
										class="down-button"
										style="cursor: pointer;"
										on:click={() => {
											download_tax(
												schema,
												schema._folio_declaracion,
												"acuse",
												schema._codigo_esquema,
												schema._numero_identificacion_esquema,
												schema._ejercicio,
												schema._rfc,
												schema._tipo_declaracion,
												schema._cadena_firmada,
												schema._cadena_original
											);
										}}
									>
										<i class="fas fa-download" />
										<div class="button-label">Acuse</div>
									</div>
									<div
										class="down-button"
										style="cursor: pointer;"
										on:click={() => {
											download_Copia(
												schema,
												schema._folio_declaracion,
												schema._tipo_esquema,
												schema._codigo_esquema,
												schema._numero_identificacion_esquema,
												schema._ejercicio,
												true,
												schema._rfc,
												schema._tipo_declaracion
											);
										}}
									>
										<i class="fas fa-download" />
										<div class="button-label">Copia</div>
									</div>
								{/if}
							{/if}
						</td>
					</tr>
				{/each}
			</Table>

			<Paginator {schema} {selectedRows} {onSchemaChange} {onRowsChange} />
		</div>
	{:else}
		<div class="fewContent">
			<Empty>No se encontraron registros</Empty>
		</div>
	{/if}
</div>

<style>
	.table :global(.Table) {
		margin-top: 70px;
		margin-bottom: 70px;
	}

	.fewContent {
		padding: 15em;
	}

	/* .button-label {
    margin-top: 5px;
    cursor: pointer;
  } */

	.down-button {
		/* display: flex; */
		/* flex-direction: column; */
		/* align-items: center; */
		/* margin: 0px 5px; */
		/* padding: 5px; */
		/* border-radius: 2px; */
		/* cursor: pointer; */
		transition-duration: 300ms;
	}

	.down-button:hover {
		/* background-color: #e0e0e0; */
		color: #000000;
	}

	td {
		flex-basis: 10%;
	}
</style>