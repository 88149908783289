<script lang="ts">
    import Acuse from "./components/pdfs/acuse";

    let acuse = new Acuse({
        rfc: "GPA200101NL1",
        denominacion_social: "La Gasolinera De Prueba AGGC S.A. de C.V.",
        tipo: "Normal",
        esquema: "Generalizado",
        fecha: new Date("2021-03-21T12:01:03Z"),
        cadena: "|00000191893|2021-01-01|Normal|Generalizada",
        firma: "bYrsXTI6KsZ7PEQSZ3FxrK7C3ba2ryc4pYU2gz1n+0CWMj6XQDUjqtunHblXElmE5RyiHReYHsvLVCIt1k3nANEwrxe6kpqwffF+ED/jHsaZgamaVCOiXf7TZkXSM+nnQnIIi3kUeWz7BXv5pxYqdfj2+SlGmIOVd5sSnXPslyk=",
        sello: "xe6kpqwXElZgamaVCOmE5RyiHReYHsvLyc4pYU2oz1n+0CWMjsXTI6KsZ7PEQSZ3FxrK7C36jHVCIt1k3nANEwr+nnQnIIi3kUeWz7BffF+ED/bYrba2rsaiXf7TZkXSMXQDUjqtunHblXv5pxYqdfj2+SlGmIOVd5sSnXPslyk="
    });
    acuse.printHeader();
    let acuse_content = acuse.output("datauristring");
</script>

<iframe src={acuse_content} height="600px" width="100%" title="preview"></iframe>

<style>

</style>