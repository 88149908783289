<script lang='ts' >
  import { navigate } from "svelte-navigator";
  import { fly } from "svelte/transition";


  import Popup from "../../components/ui/PopupWrapper.svelte";
  import Button from '../../components/ui/Button.svelte';
  import CircleButton from '../../components/ui/CircleButton.svelte'
  import { DeclarationNumberRegex } from '../../utils/validattionRegex'
  import { api } from "../../Api";
  import {  CODIGO_TIPO_ESQUEMA, TIPO_DECLARACION } from '../../Api/objects'

  export let show = false
  export let onClose = () => {  }
  export let declaration = ''

  const repo = api();
  let shouldAskFolio = false

  // Variable para manejar el permiso de presentar la complementaria o no 
  let showComplementaryMessage: string = "";
  let showAllowedMessage: boolean = false;

  // Variables para manejar que ENR no necesita mostrar pantalla de carga de complementaria ni el nuevo popup por default
  export let declarationNumerENR = '' // uuid de la declaración ENR
  export let declarationTypeENR = "1" // Tipo de delcaración basado en TIPO_DECLARACION ENR (1 es normal)
  export let previuosExistsENR = false // Bandera que indica si una delcaración previa existe ENR

  let declarationNumer = '' // uuid de la declaración
  let declarationType = "0" // Tipo de delcaración basado en TIPO_DECLARACION
  let previuosExists = false // Bandera que indica si una delcaración previa existe
  let fiscalIdentityNumber = '' // Número de Indetificación Fiscal
  let loadingPreviuos = false // Bandera qu eindica si se está buscando una declaración previa
  let showConfirm = false // Bandera que indica si realemte esea borrar una declaración previa

  // Si es un esquema no reportable tomar las variables que llegan en props
  // y asi evitar todo el procesado como ocurre en los demás casos.
  $: if (declaration == "enr") {
    declarationNumer = declarationNumerENR
    declarationType = declarationTypeENR
    previuosExists = previuosExistsENR
  }

  const onNormalSelected = async () => {
    if (declarationType !== "0") return false
    try {
      loadingPreviuos = true
      declarationType = "1"
      const res = await repo.solicitude_exists(declarationType, CODIGO_TIPO_ESQUEMA[declaration.toUpperCase()], "2024")
      if(res === null) {
        // Se encontró una declaración normal previa en progreso
        navigate(`/${declaration}/${ shouldAskFolio ? declarationNumer: ''}`);
      } else {
        previuosExists = true
        declarationNumer = res
      }
      loadingPreviuos = true
    } catch (e) {
      // console.log(`${e} Err`)
    }
  }

  const onComplementarySelected = () => {
    if (declarationType !== "0") return false
    declarationType = "2"
    shouldAskFolio = true
  }

  const validateComplemetary = async () => {
    loadingPreviuos = true
    try {
      const res = await repo.solicitude_exists(declarationType, CODIGO_TIPO_ESQUEMA[declaration.toUpperCase()], "2024")
      if(res === null) {
        // Se encontró una declaración complementaria previa en progreso
        navigate(`/${declaration}/${ shouldAskFolio ? declarationNumer: ''}`);
      } else {
        previuosExists = true
        declarationNumer = res
      }
      loadingPreviuos = true
    }
    catch (e) {
      // console.log(`${e} Err`)
    }
  }

  async function complementaryValidation() {
    // let resultAllowed = await api().get_tax_schema_allow("b53b63bf-9538-486a-8c94-3552d5608ce8", "ERGASF2022012115805815");
    // console.log(declarationNumer)
    // console.log(fiscalIdentityNumber)
    let resultAllowed = await api().get_tax_schema_allow(declarationNumer, fiscalIdentityNumber);
    // console.log(resultAllowed);

    if (resultAllowed == "full_allowed") {
      showAllowedMessage = false;
      navigate(`/${declaration}?complementary=${declarationNumer}`)
    } else if (resultAllowed == "half_allowed") {
      showComplementaryMessage = "No se tiene autorizado presentar la complementaria ó la declaración original no existe";
      showAllowedMessage = true;
    } else if (resultAllowed == "not_allowed") {
      showComplementaryMessage = "No se tiene autorizado presentar la complementaria ó la declaración original no existe";
      showAllowedMessage = true;
    }
  }

  const onDeleteConfirm = () => {
    repo.delete_solicitude(declarationNumer)
      .then(res => {
        navigate(`/${declaration}/`)
      })
      // .catch(e => console.log(e))
  }

  const onContinuePreviousSolicitude =  () => {
    navigate(`/${declaration}/${declarationNumer}/`);
  } 

  // Para validar la activación de ir a la complementaria 
  let isValid: number;
  $: validDeclarationNumber = declarationNumer && declarationNumer.length;
  $: validFiscalIdentityNumber = fiscalIdentityNumber && fiscalIdentityNumber.length;
  $: if(declaration == "era") {
    isValid = validDeclarationNumber;
  } else {
    isValid = validDeclarationNumber && validFiscalIdentityNumber;
  }
</script>

<Popup bind:show={show} onClose={onClose} >
    <div slot='title' >
      { previuosExists ? `Declaración ${TIPO_DECLARACION[declarationType]}` : 'Selecciona el tipo de declaración' }
    </div>

    {#if previuosExists}
      {#if showConfirm}
        <div in:fly="{{ x: 200, duration: 1200, delay: 300 }}" out:fly="{{ x: 200, duration: 300 }}" >
          <p> Esta a punto de eliminar una declaración previa. </p>
          <p> ¿Está seguro de querer continuar? </p>

          <div class='btnsContainer' >
            <Button on:click={() => {
              onDeleteConfirm(); 
              onClose()
              navigate(`/${declaration}`)
            }}>
              Borrar 
            </Button>
            <Button on:click={() => {showConfirm = false}} > 
              Cancelar
            </Button>
          </div>
        </div>
      {:else}
        <div in:fly="{{ x: -200, duration: 1200,  delay: 300 }}" out:fly="{{ x: -200, duration: 300 }}" >
          <p> Usted ya cuenta con una declaración en curso, la cuál será guardada por un periodo de 30 días. Podrá iniciar de nuevo borrando la declaración en curso o continuar con la registrada.</p>

          <div class='btnsContainer' >
            <Button on:click={() => {showConfirm = true;}}>
              Borrar 
            </Button>
            <Button on:click={onContinuePreviousSolicitude} > 
              Continuar
            </Button>
          </div>
          
        </div>
      {/if}
    {:else}
      <div class="types">
        <!-- {#if declaration == "enr"}
          <CircleButton
            label={TIPO_DECLARACION[1]}
            on:click={onNormalSelected}
            loading={loadingPreviuos}
            icon="far fa-plus-square" 
          />
        {:else} -->
          {#if declarationType === "0" || declarationType === "1" }  
            <CircleButton
              label={TIPO_DECLARACION[1]}
              on:click={onNormalSelected}
              loading={loadingPreviuos}
              icon="far fa-plus-square" 
            />
          {/if}

          {#if declarationType === "0" || declarationType === "2" }  
            <CircleButton
              label={TIPO_DECLARACION[2]}
              on:click={onComplementarySelected}
              loading={loadingPreviuos}
              icon="far fa-plus-square" 
            />
          {/if}
        <!-- {/if} -->

      </div>

      {#if shouldAskFolio}
        {#if showAllowedMessage == true}
          <div class='folio' in:fly="{{ y: -10, delay: 500 }}">
            <p>{showComplementaryMessage}</p>
            <div class='btnsContainer' >
              <Button on:click={() => {
                showAllowedMessage = false;
              }}>
                Aceptar
              </Button>
            </div>
          </div>
        {:else if showAllowedMessage == false}
          <div class='folio' in:fly="{{ y: -10, delay: 500 }}">
            <input
              placeholder="Número de operación"
              bind:value={declarationNumer}
              pattern={DeclarationNumberRegex}
              type="text"
            >
            {#if declaration !== "era"}
              <input
                placeholder="Número de identificación Fiscal"
                bind:value={fiscalIdentityNumber}
                type="text"
              >
            {/if}
            <Button
              on:click={() => {
                complementaryValidation()
                // navigate(`/${declaration}?complementary=${declarationNumer}`)
              }}
              disabled={!isValid}
              >
              Continuar
            </Button>
            <!-- <p>Estamos trabajando para que puedas presentar las declaraciones complementarias relativas a las declaraciones anuales, esquemas generalizados o personalizados revelados con anterioridad al 2022, por favor inténtalo en unos días</p>
            <Button on:click={onClose}>
              Aceptar
            </Button> -->
          </div>
        {/if}
      {/if}
    {/if}

    

  </Popup>

  <style lang='stylus'>
    @import '../../Styles/Form/Base.styl';

    .types
      display: flex;
      justify-content: space-evenly

    .folio
      text-align: center

    input
      inputWrapper()
      display: block;
      margin: 2em auto;
      min-width: 300px

    .btnsContainer
      display: flex;
      justify-content: space-evenly
      padding-top: 3em
    p
      margin: 1em 0

  </style>