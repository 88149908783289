<script lang="ts">
	import { createEventDispatcher } from "svelte";
	import { fly, fade } from "svelte/transition";

	const dispatch = createEventDispatcher();

	export let show: boolean = false;
	
	const close = () => {
		show = false;
		dispatch("closeIt", show);
	};

	// $: console.log(show);
</script>

{#if show}
	<div transition:fade class="PopupWrapper">
		<div transition:fly="{{ delay: 300, y: -40 }}" class="container">
            <div class="close" on:click={ close } >
                <i class="far fa-times-circle"></i>
            </div>


            <h2 class='title' >
                <slot name='title'></slot>
            </h2>
    
            <div class="content">
                <slot></slot>
            </div>
		
		</div>
    </div>
{/if}

<style>


</style>
