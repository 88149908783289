<script lang="ts">
    import {onMount} from "svelte";
    import { fade } from "svelte/transition";
    import {navigate } from "svelte-navigator";

    import { api} from "../../Api";
    import Stepper from "../ui/Stepper.svelte";
    import Title from '../ui/Title.svelte'
    import Formular from "./Formular.svelte";
    import FormActions from '../ui/FormActions.svelte';

    import { jsPDF } from "jspdf";
    import { write } from "./pdf";
    import Popup from "../ui/PopupWrapper.svelte";
    import Copia from "../pdfs/copia";
    import AcuseERGP from "../pdfs/acuse_ergp";
    import * as cs from "cryptosat-ts";
    import {CODIGO_PERSONA, CODIGO_TIPO_ESQUEMA } from "../../Api/objects";

    // Obtener parametros para determinar el tipo de declaración
    const params = new URLSearchParams(window.location.search);
    const complementary = params.get("complementary");
    
    let step = 0;    

    export let declarationid: string | undefined = undefined;

    let formular: Formular;

    let doc = new jsPDF();
    let content = doc.output('datauristring');

    //acuse.printHeader();
    let acuse_content = (new jsPDF()).output("datauristring");

    let declaration: cs.SecureMap | undefined = undefined;
    let declaration_reloaded: boolean = true;
    let name: string = "";

    // Ultima validación
    let cadenaOriginal: string;
    let cadenaFirmada: string;

    async function create_from_json(json: cs.SecureMap) {
        let user_info = await api().user_info();

        name = user_info.nombre;
        let doc = new Copia({
            rfc: user_info.rfc,
            denominacion_social: user_info.rfc.length == 12 ? user_info.nombre : undefined,
            nombre: user_info.rfc.length == 13 ? user_info.nombre : undefined,
            tipo: complementary ? "Complementaria" : "Normal",
            esquema: "Generalizado",
            fecha: new Date(),
            cadena: "CADENA-SIN-VALIDEZ",
            num_operacion: declarationid,
            firma: "",
            // sello: "xe6kpqwXElZgamaVCOmE5RyiHReYHsvLyc4pYU2oz1n+0CWMjsXTI6KsZ7PEQSZ3FxrK7C36jHVCIt1k3nANEwr+nnQnIIi3kUeWz7BffF+ED/bYrba2rsaiXf7TZkXSMXQDUjqtunHblXv5pxYqdfj2+SlGmIOVd5sSnXPslyk="
        });
        write(doc, json);
        doc.printHeader(true);
        content = doc.output('datauristring');
    }

    function validation_change(value: CustomEvent<boolean>) {
        prev_view_enabled = value.detail;
    }

    let show_saved = false; // Maneja el mostrado del Popup
    let show_save_loading = false; // Manejar el letrero de carga de guardado.
    let show_loading = false; 
    let mensaje = "La declaración se ha guardado con éxito";
    let prev_view_enabled = false;

    let cer_file: FileList;
    let identification: cs.X509Certificate;
    let key_file: FileList;
    let key_ab: ArrayBuffer | undefined;
    let password: string | undefined;
    let cert_rfc: string | undefined;

    $: if(cer_file != undefined) {
        let f = cer_file[0];

        let reader = new FileReader();
        reader.readAsArrayBuffer(f);

        reader.onload = function() {
            let cert = cs.X509Certificate.from_bytes(reader.result as ArrayBuffer);
            if(cs.utils.isError(cert)) {
                // console.log(cert.display());
                show_saved = true;
                mensaje = "Ocurrió un error al leer el certificado";
            } else {
                let tmp_rfc = cert.get_rfc();
                let new_cert = cert;
                api().user_info().then(user_info => {
                    if(tmp_rfc != user_info.rfc) {
                        show_saved = true;
                        mensaje = "El RFC del certificado no corresponde con el de la sesión.";
                    } else {
                        cert_rfc = tmp_rfc;
                        identification = new_cert;
                    }
                })
            }
        };
    }

    $: if(key_file != undefined) {
        let f = key_file[0];

        let reader = new FileReader();
        reader.readAsArrayBuffer(f);

        reader.onload = function() {
            key_ab = reader.result as ArrayBuffer;
        };
    }

    function sign() {
        if(password != undefined && key_ab != undefined) {
            show_loading = true;
            let secret = cs.X509Secret.deserialize(key_ab, password);
            if(cs.utils.isError(secret)) {
                // console.log(secret.display());
                show_saved = true;
                mensaje = "La contraseña del certificado parece ser incorrecta";
            } else {
                show_saved = true;
                mensaje = "Firmando..."
                let res = cs.SignatureV1.create({
                    content: declaration,
                    identification: identification,
                    secret: secret,
                    in_place: true
                });
                if(cs.utils.isError(res)) {
                    // console.log(res.display());
                    show_saved = true;
                    mensaje = "Ocurrió un error al firmar la declaración";
                } else {
                    let now = new Date();

                    cadenaOriginal =`${cert_rfc}|${now.toISOString()}|ERG|${complementary ? "Complementaria" : "Normal"}|${declarationid}`;

                    const encoder = new TextEncoder();
                    const content = encoder.encode(cadenaOriginal);
                    const signature_bytes = identification.sign(content, secret);
                    if(cs.utils.isError(signature_bytes)) {
                        show_saved = true;
                        mensaje = "Ocurrió un error al firmar la declaración";
                    } else {
                        cadenaFirmada = cs.utils.bytes2Base64(signature_bytes);
                        
                        api().sign_form(declaration, declarationid, cadenaOriginal, cadenaFirmada).then(res => {
                            let result = res;
                            mensaje = "Se ha firmado correctamente"
                            let acuse = new AcuseERGP({
                                rfc: cert_rfc,
                                denominacion_social: cert_rfc.length == 12 ? name : undefined,
                                nombre: cert_rfc.length == 13 ? name : undefined,
                                tipo: complementary ? "Complementaria" : "Normal",
                                esquema: "Generalizado",
                                fecha: new Date(),
                                cadena: cadenaOriginal,
                                num_operacion: declarationid,
                                id_operacion: result._numero_identificacion_esquema,
                                firma: cadenaFirmada,
                                // sello: result._cadena_firmada
                            });
                            acuse.agrega_qr("https://esquemas.sat.gob.mx");
                            acuse.printHeader(false);
                            acuse_content = acuse.output("datauristring");
                            step += 1; // SIGUIENTE
                        }).catch(e => {
                            // console.error(e);
                            mensaje = "Ocurrió un error al firmar la declaración"
                            show_saved = true;
                        });
                    }
                }
            }
        }
    }

    onMount(() => {
        if (declarationid != undefined) {
            api().get_form(declarationid).then(decl =>  {
                formular.load(decl);
            }, error => {
                mensaje = "Ocurrió un error al precargar la declaración.";
                show_saved = true;
                // console.log(error);
            })
        } else if (complementary != undefined) {
            api().get_form(complementary).then(decl =>  {
                formular.load(decl);
            }, error => {
                mensaje = "Ocurrió un error al precargar la declaración.";
                show_saved = true;
                // console.log(error);
            })
        }
    });

    const onFormSave = () => {
        declaration = formular.acquire();
        show_saved = true;
        show_save_loading = true;
        api().save_form(declaration, CODIGO_TIPO_ESQUEMA.ERG , complementary, CODIGO_PERSONA.ASF ,declarationid).then(resulting_id => {
            // console.log(resulting_id);
            declarationid = resulting_id;
            show_save_loading = false;
            show_saved = true;
            mensaje = "La declaración se ha guardado con éxito";
        }, error => {
            mensaje = "Ocurrió un error al guardar la declaración";
            show_saved = true;
            // console.log(error);
        });
    }

    const onFormNext = () => {
        if(step == 0) {
            let json = formular.acquire();
            declaration = json;
            onFormSave();
            create_from_json(json).then(_ => {
                let rfcArray: Array<string> = [];
                function findRFC(obj: any) {
                    for (const [key, value] of obj.entries()) {
                        if (typeof value == "object" && value != undefined) {
                            findRFC(value)
                        } else {
                            if (key == "rfc") {
                                rfcArray.push(value)
                            }
                        }
                    }
                }

                findRFC(json)

                if (rfcArray.length == 0) {
                    mensaje = `Por favor ingresar un RFC válido`
                    show_saved = true;
                } else {
                    api().get_rfcs_validation(rfcArray).then(res => {
                        if(res.length == 0) {
                            step += 1;
                            declaration_reloaded = false;
                        } else {
                            mensaje = `Los siguientes RFC no pudieron ser validados: ${res}`
                            show_saved = true;
                        }
                    })
                }
            });
        } else {
            step += 1;
        }
    }

    /// Callback function for when a file is meant to be uploaded
    async function on_file_upload(file: File, id: string): Promise<string> {
        return new Promise((resolve, reject) => {
            if(declarationid) {
                api().upload_file(declarationid, id, file).then(file_id => {
                    mensaje = "Archivo guardado con éxito.";
                    show_saved = true;
                    resolve(file_id);
                }, reject => {
                    mensaje = "Hubo un error al intentar guardar el archivo.";
                    show_saved = true;
                    reject(mensaje);
                })
            } else {
                mensaje = "Para guardar un archivo, es necesario que primero guardes tu declaración.";
                show_saved = true;
                reject(mensaje);
            }
        });
    }

    /// Callback function for when a file is meant to be deleted
    async function on_delete_file(id: string): Promise<null> {
        return Promise.resolve(null)
    }

    /// Callback function for when a file is meant to be recovered
    async function on_recover_file(id: string): Promise<null> {
        try {
            if(declarationid) {
                let file = await api().get_file(declarationid, id);
                let link = document.createElement("a");
                link.download =file.name;
                let url = window.URL.createObjectURL(file);
                link.href = url;
                link.click();
            } else {
                return Promise.resolve(null)
            }
        } catch (err) {
            // console.log(err);
            return Promise.reject("No pudo recuperarse el archivo");
        }
    }

    $: if(formular != undefined) {
        if(!declaration_reloaded && step == 0 && declaration != undefined) {
            declaration_reloaded = true;
            formular.load(declaration);
        }
    }

    function closePopup() {
        show_saved = !show_saved;
    }
    
</script>

<Popup bind:show={show_saved} onClose={closePopup} duration={3000}>
    <div style="display: flex; justify-content: center">{show_save_loading ? "Guardando..." : mensaje}</div>
</Popup>
<div class='FormularWrapper' in:fade={{delay:100}}>
    <Title> Esquema Reportable Generalizado </Title>
    <Stepper tags={["Formulario", "Vista Preliminar", "Firma", "Acuse"]} step={step}/>
    {#if step == 0}
        <Formular bind:this={formular} on:fullValidationChange={validation_change} {on_file_upload} {on_recover_file} {on_delete_file}/>
    {:else if step == 1}
        <iframe src={`${content}#toolbar=0`} height="600px" width="100%" title="preview">
        </iframe>
    {:else if step == 2}
        <div style="display: flex; height: 100%; justify-content: center;">
            <div class="signature-holder">
                <div class="input-container">
                    <label for="dummy">Certificado (.cer 📃)</label>
                    <div class="InputFile">
                        <label for="">
                            <input bind:files={cer_file} type="file" required={true} accept=".cer"/>
                        </label>
                    </div>
                </div>
                <div class="input-container">
                    <label for="dummy">Llave privada (.key 🔑)</label>
                    <div class="InputFile">
                        <label for="">
                            <input bind:files={key_file} type="file" required={true} accept=".key"/>
                        </label>
                    </div>
                </div>
                <div class="input-container">
                    <label for="dummy">RFC asociado al certificado</label>
                    <input type="text" bind:value={cert_rfc} placeholder="RFC" disabled/>
                </div>
                <div class="input-container">
                    <label for="dummy">Contraseña de la llave privada</label>
                    <input type="password" bind:value={password} placeholder="Contraseña"/>
                </div>
                <button on:click={() => sign()}>Firmar</button>
            </div>
        </div>
    {:else if step == 3}
        <iframe src={`${acuse_content}#toolbar=0`} height="600px" width="100%" title="preview">
        </iframe>
    {/if}

    <FormActions
        nextConfig={{
            show: (step < 2),
            enabled: prev_view_enabled,
            text: (step < 2 ? "Siguiente": "Siguiente"),
            onClick: onFormNext
        }}
        prevConfig={{
            show: (step > 0),
            enabled: true,
            text: (step == 3 ? "Finalizar" : "Regresar") ,
            onClick: () =>  (step == 3 ? navigate("/") : step -= 1) 
        }}
        saveConfig={{
            show: (step == 0),
            enabled: true,
            text: "Guardar",
            onClick: onFormSave
        }}
    />
</div>

<style>
    .signature-holder {
        display: flex;
        flex-direction: column;
    }
</style>