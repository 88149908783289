<!--D Componente para tablas -->

<table class="Table" >
  <thead>
    <slot name="header" >
    </slot>
  </thead>
  <tbody >
    <slot>
    </slot>
  </tbody>
</table>


<!--E
  <Table>
    <tr slot='header' >
      <th> Folio </th>
      <th> Fecha de presentación </th>
      <th> RFC de quién entró </th>
      <th> Acciones </th>
    </tr>
    <tr >
      <td> 3002340368363 </td>
      <td> 10-20-2020 </td>
      <td> SAHE920210HDFNRV02 </td>
      <td> Acuse </td>
    </tr>
    <tr>
      <td> 3002340368363 </td>
      <td> 10-20-2020 </td>
      <td> SAHE920210HDFNRV02 </td>
      <td> Acuse </td>
    </tr>
    <tr>
      <td> 3002340368363 </td>
      <td> 10-20-2020 </td>
      <td> SAHE920210HDFNRV02 </td>
      <td> Acuse </td>
    </tr>
  </Table>
-->