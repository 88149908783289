<script lang="ts">
    import Main from "./Screens/Main.svelte";
    import { Router, Route, navigate } from "svelte-navigator";
    
    // Feature to be removed in the near future
    let selected = true;

    import Login from "./Login.svelte";
    import OAuth from "./components/OAuth.svelte";
    import {api} from "./Api";
    import { onMount } from "svelte";

    let status: "logged" | "loading" | "unlogged" = "loading";

    onMount(() => {
        api().user_info()
            .then(u_i => {
                if (u_i == null) {
                    // No hay una sesión válida
                    status = "unlogged";
                    if(window.location.pathname != "/oauth") {
                        navigate("/login");
                    }
                } else {
                    status = "logged";
                    if(window.location.pathname == "/oauth" || window.location.pathname == "/login") {
                        navigate("/");
                    }
                }
            })
            .catch(e => {
                // console.log(e);
                // Posiblemente sea buena idea meter un popup que muestre el error del inicio de sesión.
            })
    });

    function on_logged() {
        status = "logged";
        navigate("/")
    }
</script>

{#if status == "logged"}
    <Main/>
{/if}
{#if status == "unlogged"}
    <Router primary={false}>
        <Route path="/login">
            <Login/>
        </Route>
        <Route path="/oauth">
            <OAuth on:logged={on_logged}/>
        </Route>
    </Router>
{/if}

<style  global lang="stylus">

    @import "./Styles/main.styl";

    :global(*) {
        margin: 0;
        font-family: 'Montserrat', sans-serif;
    }

    :global(body) {
        height: 100vh;
        color: #3d393a;
        --text-color: #777;
        --border-color: #84828238;
        --border-color-focus: #9d2449;
        --border-color-dark: #451020;
        --shadow: #6b6b6b30;
        --gray: #999999;
        --blue: #066ddd;
    }

    :global(hr) {
        border-color: #6b6b6b0d;
    }

    :global(thead th.header) {
        background-color: transparent;
        /* color: var(--blue); */
        font-size: 1.2em;
        font-weight: 600;
        padding: 2em;
    }

    :global(thead th) {
        background-color: var(--shadow);
        font-size: 1em;
        font-weight: 100;
        padding: 0.5em 30px;
    }

    :global(thead th.empty) {
        background-color: transparent;
        color: var(--gray);
    }

    :global(thead th.empty i) {
        font-size: 2.3em;
        margin-bottom: 0.8em;
    }

   

</style>