<!--D Componente de select -->

<script>
  export let value // Value para hacer el binding
</script>

<div class="Select">
  <select name="" id="" bind:value={value}>
    <slot></slot>
  </select>
</div>

<!--E
  <Select>
    <option> 1 </option>
    <option> 2 </option>
    <option> 3 </option>
    <option> 4 </option>
    <option> 5 </option>
  </Select>
-->