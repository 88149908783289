<script lang="ts">
  import { fly } from 'svelte/transition';

  import OperationalPopup from '../../components/ui/OperationalPopup.svelte';
  import NewPop from './NewPopup.svelte'
  import Title from '../../components/ui/Title.svelte';
  import Button from '../../components/ui/Button.svelte';
  import CheckGroup from '../../components/ui/CheckGroup.svelte';
  import type { CheckGroupOption } from '../../components/ui/CheckGroup.svelte';
  
  import { api } from '../../Api';

  let chosen_declaration: "enr" | "erg" | "erp" | "era" | "";

  let complementary: boolean;
  let complemented_id: string;

  let showNewModal = false;
  let initialModal: boolean = true;
  let disabled = true;
  let loading = false

  $: if(chosen_declaration) {
    disabled = false;
  }

  let options: Array<CheckGroupOption> = [
    {
      display: "Generar una constancia de esquema no reportable",
      enabled: true,
      value: "enr"
    },
    {
      display: "Revelar un esquema reportable generalizado",
      enabled: true,
      value: "erg"
    },
    {
      display: "Revelar un esquema reportable personalizado",
      enabled: true,
      value: "erp"
    },
    {
      display: "Presentar la declaración informativa anual",
      enabled: true,
      value: "era"
    }
  ]

  function closeInitialMessage() {
    initialModal = !initialModal
  }


  // Implementando manera de que si solo se selecciona el ENR no habra siempre el modal y solo lo abra si existe una declaración pendiente

  import {  CODIGO_TIPO_ESQUEMA, TIPO_DECLARACION } from '../../Api/objects'
  import { navigate } from "svelte-navigator";

  const repo = api();
  
  let declarationNumer = '' // uuid de la declaración
  let declarationType: string; // Tipo de delcaración basado en TIPO_DECLARACION
  let previuosExists = false // Bandera que indica si una delcaración previa existe
  let loadingPreviuos = false // Bandera qu eindica si se está buscando una declaración previa
  
  async function validateENRSelection() {
  let showConfirm = false // Bandera que indica si realemte esea borrar una declaración previa
    let chosen: string = "enr"
    declarationType = "1"
    try {
      const res = await repo.solicitude_exists(declarationType, CODIGO_TIPO_ESQUEMA[chosen.toUpperCase()], "2024")
      if(res === null) {
        // Se encontró una declaración normal previa en progreso
        navigate(`/enr/${ declarationNumer}`); // Función de momento sólo para rellenar
      } else {
        previuosExists = true;
        showNewModal = true;
        declarationNumer = res;
      }
      loadingPreviuos = true
    } catch (e) {
      // console.log(`${e} Err`)
    }
  }
  
</script>
<div id="NewStatement">
  <Title size='big' > Esquemas Reportables </Title>
  
  <h2> ¿Qué deseas hacer? </h2>

  <CheckGroup options={options} bind:value={chosen_declaration} />

  <div class="tab-content" in:fly="{{ y: -10, duration: 800 }}" >
    <div class="button-container">
      <Button
        disabled={disabled}
        loading={loading}
        on:click={() => chosen_declaration == "enr" ? validateENRSelection() : showNewModal = true } >
        Comenzar
      </Button>
    </div>
  </div>

  {#if showNewModal}
    <NewPop
      declarationNumerENR={declarationNumer.length > 0 ? declarationNumer : ''}
      previuosExistsENR={previuosExists ? previuosExists : false}
      declarationTypeENR={declarationType}

      show={showNewModal}
      declaration={chosen_declaration}
      onClose={() => {
        showNewModal = !showNewModal
      }}
    />
  {/if}

  <OperationalPopup show={initialModal} on:closeIt={closeInitialMessage}>
    <div style="display: flex; justify-content: center">
      Estamos trabajando para que puedas presentar las declaraciones complementarias relativas a las declaraciones anuales, esquemas generalizados o personalizados revelados con anterioridad al 2022, por favor inténtalo en unos días
    </div>
    <div style="margin: 20px 0 20px 0;" />
    <div style="display: flex; justify-content: center; gap: 8%">
      <button on:click={closeInitialMessage}>Aceptar</button>
    </div>
  </OperationalPopup>
</div>


<style>
  h2 {
    text-align: center;
    margin: 100px 0;
    font-weight: 100;
    font-size: 2.5em;
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 4em;
  }

</style>
