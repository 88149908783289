import { jsPDF } from 'jspdf';
const ts = { text: 11, subtitle: 11.5, title: 13, date: 10, watermarkB: 60, watermark: 5.5, footer: 6.5 };
export default class jsPDFExt extends jsPDF {
    constructor(options) {
        var _a, _b, _c, _d, _e, _f, _g;
        super(options != undefined ? options.options : undefined);
        /// Agrega una oración al documento
        ///
        /// * txt: el texto por añadir
        /// * align?: Un parámetro para controlar la justificación del texto. Por defecto, no está justificado.
        this.text_ext = (txt, options) => {
            var _a, _b, _c;
            const lineHeight = this.getTextDimensions(txt, {
                maxWidth: this.max_width,
                fontSize: options === null || options === void 0 ? void 0 : options.fontSize,
            }).h * 1.15;
            let inner_options = {
                maxWidth: this.internal.pageSize.getWidth() - (((_a = options === null || options === void 0 ? void 0 : options.corners) === null || _a === void 0 ? void 0 : _a.right) || this.right) - (((_b = options === null || options === void 0 ? void 0 : options.corners) === null || _b === void 0 ? void 0 : _b.left) || this.left)
            };
            let x_left = ((_c = options === null || options === void 0 ? void 0 : options.corners) === null || _c === void 0 ? void 0 : _c.left) || this.left;
            if (options === null || options === void 0 ? void 0 : options.align) {
                inner_options["align"] = options.align;
                if (inner_options["align"] == "center") {
                    x_left = inner_options.maxWidth / 2 + x_left;
                }
                else if (inner_options["align"] == "right") {
                    x_left = inner_options.maxWidth + x_left;
                }
            }
            let prev_font_size = this.getFontSize();
            let prev_font_name = this.getFont().fontName;
            let prev_font_style = this.getFont().fontStyle;
            if ((options === null || options === void 0 ? void 0 : options.fontSize) != undefined) {
                this.setFontSize(options.fontSize);
            }
            if ((options === null || options === void 0 ? void 0 : options.fontStyle) != undefined && (options === null || options === void 0 ? void 0 : options.fontName) != undefined) {
                this.setFont(options.fontName, options.fontStyle);
            }
            this.text(txt, x_left, this.y, inner_options);
            this.setFontSize(prev_font_size);
            this.setFont(prev_font_name, prev_font_style);
            this.y += lineHeight;
        };
        /// Brinca la cantidad de milímetros especificados
        ///
        /// El parámetro across indica que al hacer un cambio de página, se seguirán contando los milímetros restantes.
        this.jump = (num, across) => {
            let limit = this.internal.pageSize.getHeight() - this.bottom;
            if (across != undefined && across) {
                if (this.y + num > limit) {
                    this.addPage();
                    this.y = this.top;
                }
                else {
                    this.y += num;
                }
            }
            else {
                while (this.y + num > limit) {
                    num -= limit - this.y;
                    this.y = this.top;
                    this.addPage();
                }
                this.y += num;
            }
        };
        this.agrega_texto_radical = (txt, x, y, max_width, align) => {
            const lineHeight = this.getTextDimensions(txt, {
                maxWidth: max_width
            }).h * 1.15;
            let options = {
                maxWidth: max_width
            };
            if (align) {
                options["align"] = align;
                if (align == "center") {
                    x = max_width / 2 + x;
                }
                else if (align == "right") {
                    x = max_width + x;
                }
            }
            this.text(txt, x, y, options);
            return y + lineHeight;
        };
        // Now, we configure de jsPDFExt
        this.left = ((_a = options === null || options === void 0 ? void 0 : options.margins) === null || _a === void 0 ? void 0 : _a.left) || 10;
        this.right = ((_b = options === null || options === void 0 ? void 0 : options.margins) === null || _b === void 0 ? void 0 : _b.right) || 10;
        this.bottom = ((_c = options === null || options === void 0 ? void 0 : options.margins) === null || _c === void 0 ? void 0 : _c.bottom) || 40; // It was 40 when there was a footer
        this.top = ((_d = options === null || options === void 0 ? void 0 : options.margins) === null || _d === void 0 ? void 0 : _d.top) || 30;
        this.y = this.top;
        this.max_width = this.internal.pageSize.getWidth() - this.left - this.right;
        this.sizes = {
            text: ((_e = options === null || options === void 0 ? void 0 : options.sizes) === null || _e === void 0 ? void 0 : _e.text) || 7,
            subtitle: ((_f = options === null || options === void 0 ? void 0 : options.sizes) === null || _f === void 0 ? void 0 : _f.subtitle) || 10,
            title: ((_g = options === null || options === void 0 ? void 0 : options.sizes) === null || _g === void 0 ? void 0 : _g.title) || 12
        };
    }
    /// Función para justificar el texto al centro de la hoja, básicamente
    ///
    /// * pdfGen: El pdf que se modificará
    /// * text: El texto que se colocará en el pdf
    /// * xStart: Coordenada x del inicio del párrafo
    /// * yStart: Principio en la coordenada y del párrafo
    /// * textWidth: Ancho del texto por justificar
    static justify(pdfGen, text, xStart, yStart, textWidth) {
        text = text.replace(/(?:\r\n|\r|\n)/g, ' ');
        text = text.replace(/ +(?= )/g, '');
        const lineHeight = pdfGen.getTextDimensions('a').h * 1.15;
        const words = text.split(' ');
        let lineNumber = 0;
        let wordsInfo = [];
        let lineLength = 0;
        for (const word of words) {
            const wordLength = pdfGen.getTextWidth(word + ' ');
            if (wordLength + lineLength > textWidth) {
                jsPDFExt.writeLine(pdfGen, wordsInfo, lineLength, lineNumber++, xStart, yStart, lineHeight, textWidth);
                wordsInfo = [];
                lineLength = 0;
            }
            wordsInfo.push({ text: word, wordLength });
            lineLength += wordLength;
        }
        if (wordsInfo.length > 0) {
            jsPDFExt.writeLastLine(wordsInfo, pdfGen, xStart, yStart, lineNumber++, lineHeight);
        }
        return lineHeight * lineNumber;
    }
    static writeLastLine(wordsInfo, pdfGen, xStart, yStart, lineNumber, lineHeight) {
        const line = wordsInfo.map(x => x.text).join(' ');
        pdfGen.text(line, xStart, yStart + lineNumber * lineHeight);
    }
    static writeLine(pdfGen, wordsInfo, lineLength, lineNumber, xStart, yStart, lineHeight, textWidth) {
        const wordSpacing = (textWidth - lineLength) / (wordsInfo.length - 1);
        let x = xStart;
        const y = yStart + lineNumber * lineHeight;
        for (const wordInfo of wordsInfo) {
            pdfGen.text(wordInfo.text, x, y);
            x += wordInfo.wordLength + wordSpacing;
        }
    }
}
