<script lang="ts" >
  export let nextConfig: ButtonConfig // Configuración del botón con llaves show, enabled, text, onClick 
  export let prevConfig: ButtonConfig // Configuración del botón con llaves show, enabled, text, onClick 
  export let saveConfig: ButtonConfig // Configuración del botón con llaves show, enabled, text, onClick 

  type ButtonConfig = {
    show: boolean,
    enabled: boolean,
    text: string,
    onClick: Function
  }

  const defaultClick = (e: MouseEvent, callbackConfig: ButtonConfig) => {
    if (callbackConfig.enabled) callbackConfig.onClick(e)
  }

</script>

<!--D Barra de accions o botones en el formulario  -->
<div class="FormActions">
  <div>
    {#if saveConfig.show}
      <div
        class="action"
        class:disabled={!saveConfig.enabled}
        on:click={ (e) => { defaultClick(e, saveConfig) }}>
        <i class="fas fa-save" />
        { saveConfig.text }
      </div>
    {/if}
  </div>
  <div>
    {#if prevConfig.show}
      <div
        class="action"
        class:disabled={!prevConfig.enabled}
        on:click={ (e) => { defaultClick(e, prevConfig) }}>
        <i class="far fa-caret-square-left" />
        { prevConfig.text }
      </div>
    {/if}
    {#if nextConfig.show}
      <div
        class="action"
        class:disabled={!nextConfig.enabled}
        on:click={ (e) => { defaultClick(e, nextConfig) }}>
        <i class="far fa-caret-square-right" />
        { nextConfig.text }
      </div>
    {/if}
  </div>
</div>

<!--E
<FormActions  showNext={true} showPrev={true} showSave={true} isNextDisabled={true} />
-->
