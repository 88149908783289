import Troncal from "./troncal";
import "jspdf-autotable";
export default class AcuseERGP extends Troncal {
    constructor(data) {
        super();
        this.agrega_tabla_incial_ERGP(data, "ACUSE DE RECIBO");
        // this.agrega_qr("https://esquemas.sat.gob.mx");
        this.jump(4);
        this.text_ext("Cadena original", { align: "left", fontSize: 11, fontName: "Montserrat", fontStyle: "bold" });
        this.jump(2);
        this.text_ext(data.cadena ? data.cadena : "", { align: "left", fontSize: 9, fontName: "Montserrat", fontStyle: "normal" });
        this.jump(7);
        this.text_ext("Firma Contribuyente", { align: "left", fontSize: 11, fontName: "Montserrat", fontStyle: "bold" });
        this.jump(2);
        this.text_ext(data.firma ? data.firma : "", { align: "left", fontSize: 9, fontName: "Montserrat", fontStyle: "normal" });
        this.jump(4);
        // this.text_ext("Sello SAT", {align: "left", fontSize: 11, fontName: "Montserrat", fontStyle: "bold"});
        // this.jump(2);
        // this.text_ext(data.sello, {align: "left", fontSize: 9, fontName: "Montserrat", fontStyle: "normal"});
        // this.jump(2);
        this.rect(this.left, this.y, this.max_width, 0.5, 'F');
        this.jump(7);
    }
}
