import RealApi from "./real";
import TestApi from "./real";
let real_api_instance = undefined;
let test_api_instance = undefined;
let test_mode = false;
export function api() {
    if (!test_mode) {
        if (real_api_instance == undefined) {
            real_api_instance = new RealApi();
        }
        return real_api_instance;
    }
    else {
        if (test_api_instance == undefined) {
            test_api_instance = new TestApi();
        }
        return test_api_instance;
    }
}
