/*
 * Servicio de Administración Tributaria, 2021
 *
 * Autor(es):
 *  - Luis Humberto Chavez Buenrostro <luis.chavezb@sat.gob.mx>
 *
 * Pequeño código que implementa metodos generales de acceso para el consumo de un
 * API por medio de los verbos HTTP.
 *
 * GET
 * El método GET  solicita una representación de un recurso específico. Las peticiones que usan el método GET sólo deben recuperar datos.
 *
 * HEAD
 * El método HEAD pide una respuesta idéntica a la de una petición GET, pero sin el cuerpo de la respuesta.
 *
 * POST
 * El método POST se utiliza para enviar una entidad a un recurso en específico, causando a menudo un cambio en el estado o efectos secundarios en el servidor.
 *
 * PUT
 * El modo PUT reemplaza todas las representaciones actuales del recurso de destino con la carga útil de la petición.
 *
 * DELETE
 * El método DELETE borra un recurso en específico.
 *
 * CONNECT
 * El método CONNECT establece un túnel hacia el servidor identificado por el recurso.
 *
 * OPTIONS
 * El método OPTIONS es utilizado para describir las opciones de comunicación para el recurso de destino.
 *
 * TRACE
 * El método TRACE  realiza una prueba de bucle de retorno de mensaje a lo largo de la ruta al recurso de destino.
 *
 * PATCH
 * El método PATCH  es utilizado para aplicar modificaciones parciales a un recurso.
 *
 * Para mas información sobre metodos HTTP visitar https://developer.mozilla.org/es/docs/Web/HTTP/Methods
 */
/**
 * Crea un FormData a partir de un objeto Json
 *
 * @param params Objeto json que contiene los parametros a manejar
 * @returns
 */
const getParams = (params) => {
    let formData = new FormData();
    const keys = Object.keys(params);
    let i = 0;
    for (i = 0; i < keys.length; i++) {
        if (Array.isArray(params[keys[i]])) {
            let j = 0;
            for (j = 0; j < params[keys[i]].length; j++) {
                formData.append(keys[i], params[keys[i]][j]);
            }
        }
        else {
            formData.append(keys[i], params[keys[i]]);
        }
    }
    return formData;
};
export var RESOURCE_MIME;
(function (RESOURCE_MIME) {
    RESOURCE_MIME["png"] = "image/png";
    RESOURCE_MIME["jpg"] = "image/jpeg";
    RESOURCE_MIME["jpeg"] = "image/jpeg";
    RESOURCE_MIME["ico"] = "image/x-icon";
    RESOURCE_MIME["svg"] = "image/svg+xml";
    RESOURCE_MIME["js"] = "application/javascript";
    RESOURCE_MIME["pdf"] = "application/pdf";
    RESOURCE_MIME["json"] = "application/json";
    RESOURCE_MIME["txt"] = "text/plain";
    RESOURCE_MIME["general"] = "application/octet-stream";
    RESOURCE_MIME["doc"] = "application/document";
})(RESOURCE_MIME || (RESOURCE_MIME = {}));
/**
 * Clase generica que implementa metodos auxiliares para consumo de un API
 */
export class ApiCore {
    constructor() {
    }
    /**
     * Realiza una llamada de tipo HTTP-GET a la url especificada y devuelve la respuesta
     * en un objeto json dentro de un tipo "Response".
     *
     * @param url Dirección url a la que se realiza la petición
     * @param publicAction Indica sí el recurso al que se desea acceder está protegido
     * @returns Devuelve una promesa con una interfaz de tipo "Response"
     */
    apiJsonGet(url, publicAction = false) {
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'GET',
                headers: Object.assign({ 'Content-Type': RESOURCE_MIME.json }),
            }).then(v => {
                try {
                    if (v.ok) {
                        v.clone().json().then(data => {
                            resolve({
                                result: data.result,
                                messageError: null,
                                status: v.status
                            });
                        });
                    }
                    else { //Error en la petición
                        reject({
                            result: null,
                            messageError: [v.statusText],
                            status: v.status
                        });
                    }
                }
                catch (ex) { //Error en el menejo de datos
                    reject({
                        result: null,
                        messageError: [JSON.stringify(ex)],
                        status: v.status
                    });
                }
            }).catch(error => {
                reject({
                    result: null,
                    messageError: [error.message],
                    status: 0
                });
            });
        });
    }
    /**
     * Realiza una llamada de tipo HTTP-POST a la url especificada y devuelve la respuesta
     * en un objeto json dentro de un tipo "Response".
     *
     * @param url Dirección url a la que se realiza la petición
     * @param params Objeto json que proporciona los parametros de la petición
     * @param publicAction Indica sí el recurso al que se desea acceder está protegido
     * @returns Devuelve una promesa con una interfaz de tipo "Response"
     */
    apiJsonPost(url, params = {}, publicAction = false) {
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'POST',
                headers: Object.assign({ 'Content-Type': RESOURCE_MIME.json }),
                body: JSON.stringify(params),
            }).then(v => {
                try {
                    if (v.ok) {
                        v.clone().json().then(data => {
                            resolve({
                                result: data.result,
                                messageError: null,
                                status: v.status
                            });
                        });
                    }
                    else { //Error en la petición
                        v.clone().json().then(data => {
                            reject({
                                result: data,
                                messageError: [v.statusText],
                                status: v.status
                            });
                        });
                    }
                }
                catch (ex) { //Error en el menejo de datos
                    reject({
                        result: null,
                        messageError: [JSON.stringify(ex)],
                        status: v.status
                    });
                }
            }).catch(error => {
                reject({
                    result: null,
                    messageError: [error.message],
                    status: 0
                });
            });
        });
    }
    /**
     * Realiza una llamada de tipo HTTP-POST/FormData a la url especificada y devuelve la respuesta
     * en un objeto json dentro de un tipo "Response".
     *
     * @param url Dirección url a la que se realiza la petición
     * @param params Objeto json que proporciona los parametros de la petición
     * @param publicAction Indica sí el recurso al que se desea acceder está protegido
     * @returns Devuelve una promesa con una interfaz de tipo "Response"
     */
    apiJsonPostForm(url, params = {}, publicAction = false) {
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'POST',
                headers: Object.assign({ /*'Content-Type': 'multipart/form-data' */}),
                body: getParams(params),
            }).then(v => {
                try {
                    if (v.ok) {
                        v.clone().json().then(data => {
                            resolve({
                                result: data.result,
                                messageError: null,
                                status: v.status
                            });
                        });
                    }
                    else { //Error en la petición
                        v.clone().json().then(data => {
                            reject({
                                result: data,
                                messageError: [v.statusText],
                                status: v.status
                            });
                        });
                    }
                }
                catch (ex) { //Error en el menejo de datos
                    reject({
                        result: null,
                        messageError: [JSON.stringify(ex)],
                        status: v.status
                    });
                }
            }).catch(error => {
                reject({
                    result: null,
                    messageError: [error.message],
                    status: 0
                });
            });
        });
    }
    /**
     * Realiza una llamada de tipo HTTP-PATCH a la url especificada y devuelve la respuesta
     * en un objeto json dentro de un tipo "Response".
     *
     * @param url Dirección url a la que se realiza la petición
     * @param params Objeto json que proporciona los parametros de la petición
     * @param publicAction Indica sí el recurso al que se desea acceder está protegido
     * @returns Devuelve una promesa con una interfaz de tipo "Response"
     */
    apiJsonPatch(url, params = {}, publicAction = false) {
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'PATCH',
                headers: Object.assign({ 'Content-Type': RESOURCE_MIME.json }),
                body: JSON.stringify(params),
            }).then(v => {
                try {
                    if (v.ok) {
                        v.clone().json().then(data => {
                            resolve({
                                result: data.result,
                                messageError: null,
                                status: v.status
                            });
                        });
                    }
                    else { //Error en la petición
                        v.clone().json().then(data => {
                            reject({
                                result: data,
                                messageError: [v.statusText],
                                status: v.status
                            });
                        });
                    }
                }
                catch (ex) { //Error en el menejo de datos
                    reject({
                        result: null,
                        messageError: [JSON.stringify(ex)],
                        status: v.status
                    });
                }
            }).catch(error => {
                reject({
                    result: null,
                    messageError: [error.message],
                    status: 0
                });
            });
        });
    }
    /**
     * Realiza una llamada de tipo HTTP-DELETE a la url especificada y devuelve la respuesta
     * en un objeto json dentro de un tipo "Response".
     *
     * @param url Dirección url a la que se realiza la petición
     * @param params Objeto json que proporciona los parametros de la petición
     * @param publicAction Indica sí el recurso al que se desea acceder está protegido
     * @returns Devuelve una promesa con una interfaz de tipo "Response"
     */
    apiJsonDelete(url, params = {}, publicAction = false) {
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'DELETE',
                headers: Object.assign({ 'Content-Type': RESOURCE_MIME.json }),
                body: JSON.stringify(params),
            }).then(v => {
                try {
                    if (v.ok) {
                        v.clone().json().then(data => {
                            resolve({
                                result: data.result,
                                messageError: null,
                                status: v.status
                            });
                        });
                    }
                    else { //Error en la petición
                        v.clone().json().then(data => {
                            reject({
                                result: data,
                                messageError: [v.statusText],
                                status: v.status
                            });
                        });
                    }
                }
                catch (ex) { //Error en el menejo de datos
                    reject({
                        result: null,
                        messageError: [JSON.stringify(ex)],
                        status: v.status
                    });
                }
            }).catch(error => {
                reject({
                    result: null,
                    messageError: [error.message],
                    status: 0
                });
            });
        });
    }
    /**
     * Realiza una llamada de tipo HTTP-GET a la url especificada y devuelve la respuesta
     * en un objeto <blob | json | texto plano> dentro de un tipo "Response".
     *
     * @param url Dirección url a la que se realiza la petición
     * @param type_mime_resource Tipo mime del recurso a obtener
     * @param publicAction Indica sí el recurso al que se desea acceder está protegido
     * @returns Devuelve una promesa con una interfaz de tipo "Response"
     */
    apiBlobGet(url, type_mime_resource = RESOURCE_MIME.general, publicAction = false) {
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'GET',
                headers: Object.assign({ 'Content-Type': type_mime_resource }),
            }).then(v => {
                try {
                    if (v.ok) {
                        let resource = null;
                        switch (type_mime_resource) {
                            case RESOURCE_MIME.txt:
                                v.clone().text().then(data => {
                                    resource = data;
                                });
                                break;
                            case RESOURCE_MIME.json:
                                v.clone().json().then(data => {
                                    resource = data;
                                });
                                break;
                            default:
                                v.clone().blob().then(data => {
                                    resource = data;
                                });
                                break;
                        }
                        resolve({
                            result: resource,
                            messageError: null,
                            status: v.status
                        });
                    }
                    else { //Error en la petición
                        reject({
                            result: null,
                            messageError: [v.statusText],
                            status: v.status
                        });
                    }
                }
                catch (ex) { //Error en el menejo de datos
                    reject({
                        result: null,
                        messageError: [JSON.stringify(ex)],
                        status: v.status
                    });
                }
            }).catch(error => {
                reject({
                    result: null,
                    messageError: [error.message],
                    status: 0
                });
            });
        });
    }
}
