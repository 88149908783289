<script lang="ts">
    import { fly, fade } from "svelte/transition"
    // Muestra el popup
    export let show = false;
    export let onClose = () => { } 
    // Duración en milisegundos
    export let duration: number | undefined = undefined;
    // Removiendo el setTimeout para ver si mejora la funcionalidad del Popup
    $: if(show) {
        if (duration) {
            setTimeout(() => {
                show = false;
            }, duration)
        }
    }

    const handleKeydown = (event) => {
        if (event.key === "Escape") {
            onClose()
        }
    }
</script>

<svelte:window on:keydown={handleKeydown}/>

{#if show}
<div transition:fade class="PopupWrapper">
    <div
        transition:fly="{{ delay: 300, y: -40 }}"
        class="container">
        <div class="close" on:click={ onClose } >
            <i class="far fa-times-circle"></i>
        </div>

        <h2 class='title' >
            <slot name='title'></slot>
        </h2>

        <div class="content">
            <slot></slot>
        </div>
    </div>
</div>
{/if}

