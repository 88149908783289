import Troncal from "./troncal";
export default class Copia extends Troncal {
    constructor(data, options) {
        super(options);
        if (data.esquema == "Generalizado" || data.esquema == "Personalizado") {
            if (data.copia) {
                if (data.previous) {
                    this.agrega_tabla_incial_ERGP(data, "");
                }
                else {
                    this.agrega_tabla_incial_ERGP(data, "COPIA DE LA");
                }
            }
            else {
                this.agrega_tabla_incial_ERGP(data, "VISTA PRELIMINAR DE LA");
            }
        }
        else if (data.esquema == "Declaración Anual") {
            if (data.previous) {
                this.agrega_tabla_incial_ERA(data, "");
            }
            else {
                this.agrega_tabla_incial_ERA(data, "VISTA PRELIMINAR DE LA");
            }
        }
        this.y += 3;
        this.setFont("Montserrat", "bold");
        this.setFontSize(12);
        this.text_ext("CONTENIDO DE LA DECLARACIÓN", { align: "center", fontSize: 12, fontName: "Montserrat", fontStyle: "bold" });
        this.y += 5;
        this.setFontSize(7);
        this.setFont("Montserrat", "normal");
    }
}
