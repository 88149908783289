<div class="tab-list">
	<slot></slot>
</div>

<style>
	.tab-list {
		/*border-bottom: 2px solid teal;*/
		flex-wrap: wrap;
		display: flex;
		margin-bottom: 30px;
	}
</style>