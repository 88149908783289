import { jsPDFExt } from "../extra";
import '../../img/logos.png';
import logosat25_b from '../../img/logosat25_b.png';
import "../../img/SATLogoHeader2022.png";
import '../../img/pdf.f.jpg';
import lowbar from '../../img/lowbar2.jpeg';
// import snake from '../../img/snake.png';
import carrillo from '../../img/Felipe-Puerto-Carrillo-Underbar.png';
import { MontserratRegular } from './fonts/Montserrat-regular';
import { MontserratSemibold } from './fonts/Montserrat-semibold';
import "qrcode-generator";
const ts = { text: 11, subtitle: 11.5, title: 13, date: 10, watermarkB: 60, watermark: 5.5, footer: 6.5 };
export default class Troncal extends jsPDFExt {
    constructor(options) {
        super(options);
        this.getImg = (path) => {
            const img = document.createElement("img");
            img.src = path;
            return img;
        };
        this.printHeader = (addWaterMark) => {
            let n = this.getNumberOfPages();
            for (let page = 0; page < n; page++) {
                this.setPage(page + 1);
                const [folio, date] = ["12345", "2020-10-01T10:00:00Z"];
                if (false) {
                    this.printWaterMark();
                }
                //this.addImage(this.getImg(logos), 'JPG', this.left, 10, 103, 13);
                this.setFontSize(7);
                this.setFont('Montserrat', 'normal');
                this.text(`Página ${page + 1} de ${n}`, (this.internal.pageSize.width - 14) / 2, 23, { maxWidth: 170 });
                this.setFontSize(ts.text);
                this.setFont('Montserrat', 'bold');
                this.addImage(logosat25_b, 'PNG', (this.internal.pageSize.width - 125) / 2, 5, 125, 13);
                // this.addImage(this.getImg(logos), 'PNG', (this.internal.pageSize.width - 103)/2, 5, 103, 18);
                /*
                this.setFontSize(ts.date);
                this.text(`Fecha de emisión: ${new Intl.DateTimeFormat('es-MX', { dateStyle: 'full' } as any).format(new Date(date))}`, 200, 15, { align: 'right' });
                if (folio) this.text(`Folio: ${folio}`, 200, 20, { align: 'right' });
                */
                if (addWaterMark) {
                    this.printWaterMark();
                }
                if (true) {
                    this.addImage(lowbar, 'jpeg', this.left, 277, 160, 10);
                    this.setTextColor('#c2992a');
                    this.setFontSize(ts.footer);
                    this.setFont('Montserrat', 'bold');
                    this.text("Av. Hidalgo No. 77, Col. Guerrero, C.P. 06300, Alcaldía Cuauhtémoc, Ciudad de México.", this.left + 3, 272, { maxWidth: 170 });
                    this.text("Marca SAT 55 627 22 728 sat.gob.mx", this.left + 3, 275, { maxWidth: 170 });
                    this.setTextColor(0);
                    this.setFontSize(ts.text);
                    this.addImage(carrillo, 'PNG', 162.5, 269.5, 37, 18);
                    // this.addImage(snake, 'PNG', 175, 260, 30, 30)
                    // this.addImage(imageData, format, x, y, width, height)
                }
            }
        };
        this.printWaterMark = () => {
            // background red
            // /*
            this.setTextColor('#e96c76');
            this.setFontSize(ts.watermarkB);
            this.setFont('Montserrat', 'bold');
            this.text("Sin Validez Oficial", this.left + 35, this.internal.pageSize.height - 60, { maxWidth: 300, angle: 45 }, 45);
            this.setTextColor(0);
            // at the bottom
            this.setFont('Montserrat', 'normal');
            this.setFontSize(ts.watermark);
            this.text("EL PRESENTE DOCUMENTO CARECE DE VALIDEZ OFICIAL, POR LO QUE NO ESTABLECE NI CREA DERECHOS DISTINTOS DE LOS CONTENIDOS EN LAS DISPOSICIONES FISCALES.", 105, 292, { align: 'center', maxWidth: 190 });
            this.setFontSize(ts.text);
            // */
        };
        /*
        agrega_texto_radical = (txt: string, x: number, y: number, max_width: number, align?: "left" | "center" | "right" | "justify") => {
            const lineHeight = this.getTextDimensions(txt, {
                maxWidth: max_width
            }).h * 1.15;
    
            let options: TextOptionsLight = {
                maxWidth: max_width
            }
    
            if (align) {
                options["align"] = align;
                if (align == "center") {
                    x = max_width / 2 + x;
                } else if (align == "right") {
                    x = max_width + x;
                }
            }
    
            this.text(txt, x, y, options);
    
            return y + lineHeight;
        }
        */
        this.agrega_leyenda_enr = (content) => {
            this.jump(4);
            this.text_ext('Este documento es emitido sin prejuzgar la veracidad de los datos asentados, ni el cumplimiento de los plazos establecidos, por lo que, quedan a salvo las facultades de la autoridad fiscal.', {
                fontSize: 8,
                fontName: "Montserrat",
                fontStyle: "normal",
                align: "left"
            });
            this.jump(3);
            this.text_ext('Para cualquier duda o aclaración llama a MarcaSAT: 55-62-72-27-28 y 07-87-74-48-87-28 para Estados Unidos de América y Canadá.', {
                fontSize: 8,
                fontName: "Montserrat",
                fontStyle: "normal",
                align: "left"
            });
            this.jump(3);
            this.text_ext('Los datos personales son incorporados y protegidos en los sistemas del Servicio de Administración Tributaria, de conformidad con las disposiciones legales en la materia.', {
                fontSize: 8,
                fontName: "Montserrat",
                fontStyle: "normal",
                align: "left"
            });
        };
        this.agrega_qr = (content) => {
            /*
            let s = qrcode.default(4, "L");
            s.addData(content);
            s.make();
    
            const img = document.createElement("img")
            img.src = s.createDataURL(20)
            this.addImage(img, 'JPG', 150, this.y + 15, 50, 50);
            let last_y = this.y + 10;
            this.setFontSize(9);
     
            last_y = this.agrega_texto_radical('Para corregir o modificar la información y documentación presentada en esta declaración informativa, visita esquemas.sat.gob.mx', this.left, last_y, 120, "left") + 5;
     
            last_y = this.agrega_texto_radical('La presentación de esta declaración no implica la aceptación o rechazo de los efectos fiscales del esquema reportable revelado, por parte del Servicio de Administración Tributaria.', this.left, last_y, 120, "left") + 5;
     
            last_y = this.agrega_texto_radical('Este acuse es emitido sin prejuzgar la veracidad de los datos asentados, ni el cumplimiento de los plazos establecidos, por lo que, quedan a salvo las facultades de la autoridad fiscal.', this.left, last_y, 120, "left") + 5;
     
            last_y = this.agrega_texto_radical('Para cualquier duda o aclaración llama a MarcaSAT: 55-62-72-27-28 y 07-87-74-48-87-28 para Estados Unidos de América y Canadá.', this.left, last_y, 120, "left") + 5;
     
            last_y = this.agrega_texto_radical('Los datos personales son incorporados y protegidos en los sistemas del Servicio de Administración Tributaria, de conformidad con las disposiciones legales en la materia.', this.left, last_y, 120, "left") + 5;
            this.y = last_y;
            */
            this.jump(4);
            this.text_ext('Para corregir o modificar la información y documentación presentada en esta declaración informativa, visita esquemas.sat.gob.mx', {
                fontSize: 8,
                fontName: "Montserrat",
                fontStyle: "normal",
                align: "left"
            });
            this.jump(3);
            this.text_ext('La presentación de esta declaración no implica la aceptación o rechazo de los efectos fiscales del esquema reportable revelado, por parte del Servicio de Administración Tributaria.', {
                fontSize: 8,
                fontName: "Montserrat",
                fontStyle: "normal",
                align: "left"
            });
            this.jump(3);
            this.text_ext('Este acuse es emitido sin prejuzgar la veracidad de los datos asentados, ni el cumplimiento de los plazos establecidos, por lo que, quedan a salvo las facultades de la autoridad fiscal.', {
                fontSize: 8,
                fontName: "Montserrat",
                fontStyle: "normal",
                align: "left"
            });
            this.jump(3);
            this.text_ext('Para cualquier duda o aclaración llama a MarcaSAT: 55-62-72-27-28 y 07-87-74-48-87-28 para Estados Unidos de América y Canadá.', {
                fontSize: 8,
                fontName: "Montserrat",
                fontStyle: "normal",
                align: "left"
            });
            this.jump(3);
            this.text_ext('Los datos personales son incorporados y protegidos en los sistemas del Servicio de Administración Tributaria, de conformidad con las disposiciones legales en la materia.', {
                fontSize: 8,
                fontName: "Montserrat",
                fontStyle: "normal",
                align: "left"
            });
        };
        this.agrega_tabla_incial_ERGP = (data, titulo) => {
            if (data.rfc.length == 13 && data.nombre == undefined) {
                throw "Se necesita el nombre";
            }
            if (data.rfc.length == 12 && data.denominacion_social == undefined) {
                throw "Se necesita la denominación social";
            }
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            let today = new Date();
            this.text_ext(`Fecha de emisión: ${today.toLocaleDateString("es-MX", options)}`, {
                align: "center",
                fontSize: 9,
                fontName: "Montserrat",
                fontStyle: "normal"
            });
            this.y += 5;
            if (titulo) {
                this.text_ext(titulo, {
                    align: "center",
                    fontSize: 12,
                    fontName: "Montserrat",
                    fontStyle: "bold"
                });
                this.y += 3;
            }
            // CONSTANCIA DE ESQUEMA NO REPORTABLE
            this.text_ext("DECLARACIÓN INFORMATIVA PARA REVELAR ESQUEMAS REPORTABLES", {
                align: "center",
                fontSize: 12,
                fontName: "Montserrat",
                fontStyle: "bold"
            });
            this.y += 5;
            this.setFont("Montserrat", "normal");
            this.setFontSize(7);
            this.autoTable({
                startY: this.y,
                margin: { top: this.top, bottom: this.bottom, left: this.left + 10 },
                columnStyles: {
                    0: { halign: 'left', font: "Montserrat", fontStyle: 'bold' },
                    1: { halign: 'right', font: "Montserrat" }
                },
                body: [
                    ["Clave en el RFC:", data.rfc],
                    [data.rfc.length == 13 ? "Nombre" : "Razón o denominación social", data.rfc.length == 13 ? data.nombre : data.denominacion_social]
                ],
            });
            this.y = this.lastAutoTable.finalY + 2;
            this.rect(this.left, this.y, this.max_width, 0.5, 'F');
            this.y += 5;
            let fecha_pres = data.fecha.toLocaleString("es-MX", options);
            let hora_pres = data.fecha.toLocaleTimeString();
            this.autoTable({
                startY: this.y,
                margin: { top: this.top, bottom: this.bottom, left: this.left + 10 },
                columnStyles: {
                    0: { halign: 'left', font: "Montserrat", fontStyle: 'bold' },
                    1: { halign: 'right', font: "Montserrat" }
                },
                body: [
                    ["Tipo de declaración informativa:", data.tipo],
                    ["Tipo de esquema reportable revelado:", data.esquema],
                    ["Fecha y hora de presentación:", `${fecha_pres}, ${hora_pres}`],
                    ["Medio de presentación:", "Internet"],
                    ["Número de operación:", data.num_operacion],
                    [data.id_operacion ? "Número de identificación del esquema reportable:" : "", data.id_operacion ? data.id_operacion : ""]
                ],
            });
            this.y = this.lastAutoTable.finalY + 2;
            this.rect(this.left, this.y, this.max_width, 0.5, 'F');
            this.y += 5;
        };
        this.agrega_tabla_incial_ENR = (data, titulo) => {
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            let today = new Date();
            let fecha_pres = data.fecha.toLocaleString("es-MX", options);
            let hora_pres = data.fecha.toLocaleTimeString();
            if (data.rfc.length == 13 && data.nombre == undefined) {
                throw "Se necesita el nombre";
            }
            if (data.rfc.length == 12 && data.denominacion_social == undefined) {
                throw "Se necesita la denominación social";
            }
            /*
            this.setFont("Montserrat", "normal");
            this.setFontSize(7);
            */
            this.text_ext(`Fecha de expedición de la constancia: ${today.toLocaleDateString("es-MX", options)}`, {
                align: "center",
                fontSize: 9,
                fontName: "Montserrat",
                fontStyle: "normal"
            });
            this.y += 5;
            /*
            this.setFontSize(9);
            this.agrega_texto(`Fecha de emisión: ${today.toLocaleDateString("es-MX", options)}`, "center");
            this.y += 5;
            */
            if (titulo) {
                this.text_ext(titulo, {
                    align: "center",
                    fontSize: 12,
                    fontName: "Montserrat",
                    fontStyle: "bold"
                });
                this.y += 3;
            }
            // CONSTANCIA DE ESQUEMA NO REPORTABLE
            // this.text_ext("DECLARACIÓN INFORMATIVA PARA REVELAR ESQUEMAS REPORTABLES", {
            //     align: "center",
            //     fontSize: 12,
            //     fontName: "Montserrat",
            //     fontStyle: "bold"
            // });
            this.y += 5;
            this.setFont("Montserrat", "normal");
            this.setFontSize(7);
            // this.autoTable({
            //     startY: this.y,
            //     margin: { top: this.top, bottom: this.bottom, left: this.left + 10 },
            //     columnStyles: {
            //         0: { halign: 'left', font: "Montserrat", fontStyle: 'bold' },
            //         1: { halign: 'right', font: "Montserrat" }
            //     },
            //     body: [
            //         ["Clave en el RFC:", data.rfc],
            //         [data.rfc.length == 13 ? "Nombre" : "Razón o denominación social", data.rfc.length == 13 ? data.nombre : data.denominacion_social]
            //     ],
            // });
            // this.y = this.lastAutoTable.finalY + 2;
            this.rect(this.left, this.y, this.max_width, 0.5, 'F');
            this.y += 5;
            this.autoTable({
                startY: this.y,
                margin: { top: this.top, bottom: this.bottom, left: this.left + 10 },
                columnStyles: {
                    0: { halign: 'left', font: "Montserrat", fontStyle: 'bold' },
                    1: { halign: 'right', font: "Montserrat" }
                },
                body: [
                    ["Hora de emisión:", `${hora_pres}`],
                    ["Número de operación:", data.num_operacion]
                ],
            });
            this.y = this.lastAutoTable.finalY + 3;
            this.rect(this.left, this.y, this.max_width, 0.5, 'F');
            this.y += 5;
        };
        this.agrega_tabla_incial_ERA = (data, titulo) => {
            // TODO: Mock info (Antes de conseguir los valores reales para el numero de operacion y el id del esquema )
            if (data.num_operacion == undefined) {
                // console.warn("Datos mock!")
                data.num_operacion = "Guardar para generar el número de Operación";
            }
            if (data.rfc.length == 13 && data.nombre == undefined) {
                throw "Se necesita el nombre";
            }
            if (data.rfc.length == 12 && data.denominacion_social == undefined) {
                throw "Se necesita la denominación social";
            }
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            let today = new Date();
            /*
            this.setFont("Montserrat", "normal");
            this.setFontSize(7);
            */
            this.text_ext(`Fecha de emisión: ${today.toLocaleDateString("es-MX", options)}`, {
                align: "center",
                fontSize: 9,
                fontName: "Montserrat",
                fontStyle: "normal"
            });
            this.y += 5;
            /*
            this.setFontSize(9);
            this.agrega_texto(`Fecha de emisión: ${today.toLocaleDateString("es-MX", options)}`, "center");
            this.y += 5;
            */
            if (titulo) {
                this.text_ext(titulo, {
                    align: "center",
                    fontSize: 12,
                    fontName: "Montserrat",
                    fontStyle: "bold"
                });
                this.y += 3;
            }
            // CONSTANCIA DE ESQUEMA NO REPORTABLE
            this.text_ext("DECLARACIÓN INFORMATIVA PARA PROPORCIONAR LOS DATOS DE LOS CONTRIBUYENTES A LOS CUALES SE LES COMERCIALIZÓ UN ESQUEMA REPORTABLE", {
                align: "center",
                fontSize: 12,
                fontName: "Montserrat",
                fontStyle: "bold"
            });
            this.y += 5;
            this.setFont("Montserrat", "normal");
            this.setFontSize(7);
            this.autoTable({
                startY: this.y,
                margin: { top: this.top, bottom: this.bottom, left: this.left + 10 },
                columnStyles: {
                    0: { halign: 'left', font: "Montserrat", fontStyle: 'bold' },
                    1: { halign: 'right', font: "Montserrat" }
                },
                body: [
                    ["Clave en el RFC:", data.rfc],
                    [data.rfc.length == 13 ? "Nombre" : "Razón o denominación social", data.rfc.length == 13 ? data.nombre : data.denominacion_social]
                ],
            });
            this.y = this.lastAutoTable.finalY + 2;
            this.rect(this.left, this.y, this.max_width, 0.5, 'F');
            this.y += 5;
            let fecha_pres = data.fecha.toLocaleString("es-MX", options);
            let hora_pres = data.fecha.toLocaleTimeString();
            this.autoTable({
                startY: this.y,
                margin: { top: this.top, bottom: this.bottom, left: this.left + 10 },
                columnStyles: {
                    0: { halign: 'left', font: "Montserrat", fontStyle: 'bold' },
                    1: { halign: 'right', font: "Montserrat" }
                },
                body: [
                    ["Tipo de declaración informativa:", data.tipo],
                    ["Ejercicio:", data.ejercicio],
                    ["Fecha y hora de presentación:", `${fecha_pres}, ${hora_pres}`],
                    ["Tipo de esquema reportable revelado:", data.esquema],
                    ["Medio de presentación:", "Internet"],
                    [data.num_operacion ? "Número de operación:" : "", data.num_operacion ? data.num_operacion : ""]
                ],
            });
            this.y = this.lastAutoTable.finalY + 2;
            this.rect(this.left, this.y, this.max_width, 0.5, 'F');
            this.y += 5;
        };
        // this.doc = new jsPDF({ filters: ["ASCIIHexEncode"] })
        this.addFileToVFS("Montserrat-Regular.ttf", MontserratRegular);
        this.addFont("Montserrat-Regular.ttf", "Montserrat", "normal");
        this.addFileToVFS("Montserrat-Semibold.ttf", MontserratSemibold);
        this.addFont("Montserrat-Semibold.ttf", "Montserrat", "bold");
    }
}
