<script>
  export let size=100
  export let message = "Cargando"
  export let load = true;
</script>
<div class="Loader">

<svg
  id="eShyt2NaD9k1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  viewBox="-5 0 238 160"
  shape-rendering="geometricPrecision"
  text-rendering="geometricPrecision"
  width={size}
  >
  <g id="eShyt2NaD9k2" display="none">
    <polygon
      id="eShyt2NaD9k3"
      points="214.700000,147.200000 149.300000,147.200000 149.300000,164.800000 171.300000,164.800000 171.300000,198.100000 192.900000,198.100000 192.900000,164.800000 214.700000,164.800000"
      fill="rgb(29,29,27)"
      stroke="none"
      stroke-width="1"/>
    <path
      id="eShyt2NaD9k4"
      d="M133.800000,147.200000L102.400000,147.200000L77.300000,198.100000C77.300000,198.100000,103.700000,198.300000,104,198.100000L111.700000,179.200000C111.700000,179.200000,126.100000,183.200000,131.500000,198.100000L158.200000,198.100000L133.800000,147.200000ZM112.500000,176.800000L118.900000,160.800000L123.800000,176.800000L112.500000,176.800000Z"
      fill="rgb(29,29,27)"
      stroke="none"
      stroke-width="1"/>
    <path
      id="eShyt2NaD9k5"
      d="M72,148.400000L72,164.200000C71.700000,163.800000,70.700000,163.400000,69.600000,163.100000C59.500000,159.500000,46.700000,159,43.700000,159.900000C41,160.800000,40.400000,162.600000,42.400000,163.700000C43.700000,164.500000,50.600000,165.600000,57.900000,167.300000C75.400000,171.300000,82,178.100000,75.500000,188.200000C70.200000,196.800000,48.900000,201.800000,19.900000,196.700000C18.200000,196.400000,17.700000,195.600000,14.100000,195.300000L14.100000,178.900000C17,179.400000,18.100000,180,19.300000,180.400000C26.500000,183.400000,40,185.300000,45.400000,184.800000C49.400000,184.400000,51.200000,182.600000,49.100000,181C48,180.100000,39.900000,179.200000,32.900000,177.700000C14.900000,173.700000,9.900000,167.100000,15.100000,157.700000C19.300000,150,33.200000,145.100000,56,146.400000C60,146.600000,64.100000,147.100000,68.100000,147.800000C69.800000,147.800000,71.200000,148.100000,72,148.400000"
      fill="rgb(29,29,27)"
      stroke="none"
      stroke-width="1"/>
  </g>
  <g id="eShyt2NaD9k6"
    ><g
      id="eShyt2NaD9k7_to"
      style="offset-path:path('M142.800003,105.100006L201.800003,105.100006L201.800003,105.100006C201.177358,64.315801,166.190256,53.771659,142.800003,46.385885');offset-rotate:0deg"
      ><path
        id="eShyt2NaD9k7"
        d="M169,105.100000C169,119.600000,157.300000,131.300000,142.800000,131.300000C128.300000,131.300000,116.600000,119.600000,116.600000,105.100000C116.600000,90.600000,128.300000,78.900000,142.800000,78.900000C157.300000,78.900000,169,90.600000,169,105.100000"
        transform="translate(-142.800003,-105.100006)"
        fill="rgb(0,86,180)"
        stroke="none"
        stroke-width="1"
      /></g
    ><g id="eShyt2NaD9k8_to" transform="translate(85.300001,105.099997)"
      ><path
        id="eShyt2NaD9k8"
        d="M111.500000,105.200000C111.500000,119.700000,99.800000,131.400000,85.300000,131.400000C70.800000,131.400000,59.100000,119.700000,59.100000,105.200000C59.100000,90.700000,70.800000,79,85.300000,79C99.700000,79,111.500000,90.700000,111.500000,105.200000"
        transform="translate(-85.300001,-105.199997)"
        fill="rgb(0,86,180)"
        stroke="none"
        stroke-width="1"
      /></g
    ><g id="eShyt2NaD9k9_to" transform="translate(142.800003,47.600002)"
      ><path
        id="eShyt2NaD9k9"
        style="mix-blend-mode:soft-light"
        d="M169,47.600000C169,62.100000,157.300000,73.800000,142.800000,73.800000C128.300000,73.800000,116.600000,62.100000,116.600000,47.600000C116.600000,33.100000,128.300000,21.400000,142.800000,21.400000C157.300000,21.500000,169,33.200000,169,47.600000"
        transform="translate(-142.800003,-47.600002)"
        fill="rgb(0,86,180)"
        stroke="none"
        stroke-width="1"
      /></g
    ><g
      id="eShyt2NaD9k10_to"
      style="offset-path:path('M85.300001,48C65.970230,27.832683,0.523417,78.656806,25.379751,105.197945C25.543687,105.258311,25.823149,105.361223,26.200003,105.500000C24.012668,104.694510,84.066351,104.473821,85.300001,105.500000');offset-rotate:0deg"
      ><path
        id="eShyt2NaD9k10"
        d="M111.500000,47.600000C111.500000,62.100000,99.800000,73.800000,85.300000,73.800000C70.800000,73.800000,59.100000,62.100000,59.100000,47.600000C59.100000,33.100000,70.800000,21.400000,85.300000,21.400000C99.700000,21.500000,111.500000,33.200000,111.500000,47.600000"
        transform="translate(-85.300001,-48)"
        fill="rgb(0,86,180)"
        stroke="none"
        stroke-width="1"
      /></g
    ></g
  ></svg>

  {#if load}
    <p>  {message} . <span class="dot1">.</span> <span class="dot2">.</span>  </p>
  {:else}
    <p>{message}</p>
  {/if}
</div>

<style>

  .Loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 50px;
  }

  p {
    text-align: center;
  }

  .dot1 {
    animation: flash 2s ease infinite normal;
  }
  .dot2 {
    animation: flash2 2s ease infinite normal;
  }

 @keyframes flash{
   0% {
     opacity: 0;
   }
   40% {
     opacity: 1;
   }
  }
  
  @keyframes flash2{
   0% {
     opacity: 0;
   }
   80% {
     opacity: 1;
   }
  }

  #eShyt2NaD9k7_to {
    animation: eShyt2NaD9k7_to__to 3000ms linear infinite normal forwards;
  }
  @keyframes eShyt2NaD9k7_to__to {
    0% {
      offset-distance: 0%;
      animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    }
    16.666667% {
      offset-distance: 33.333333%;
      animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    }
    26.666667% {
      offset-distance: 66.666667%;
      animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    }
    46.666667% {
      offset-distance: 100%;
    }
    100% {
      offset-distance: 100%;
    }
  }
  #eShyt2NaD9k8_to {
    animation: eShyt2NaD9k8_to__to 3000ms linear infinite normal forwards;
  }
  @keyframes eShyt2NaD9k8_to__to {
    0% {
      transform: translate(85.300001px, 105.099997px);
    }
    26.666667% {
      transform: translate(85.300001px, 105.099997px);
      animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    }
    46.666667% {
      transform: translate(85.300001px, 46.385876px);
    }
    100% {
      transform: translate(85.300001px, 46.385876px);
    }
  }
  #eShyt2NaD9k9_to {
    animation: eShyt2NaD9k9_to__to 3000ms linear infinite normal forwards;
  }
  @keyframes eShyt2NaD9k9_to__to {
    0% {
      transform: translate(142.800003px, 47.600002px);
    }
    7.666667% {
      transform: translate(142.800003px, 47.600002px);
      animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    }
    20% {
      transform: translate(142.800003px, 105.200002px);
    }
    100% {
      transform: translate(142.800003px, 105.200002px);
    }
  }
  #eShyt2NaD9k10_to {
    animation: eShyt2NaD9k10_to__to 3000ms linear infinite normal forwards;
  }
  @keyframes eShyt2NaD9k10_to__to {
    0% {
      offset-distance: 0%;
      animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    }
    16.666667% {
      offset-distance: 33.333333%;
      animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    }
    36.666667% {
      offset-distance: 66.666667%;
      animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
    }
    46.666667% {
      offset-distance: 100%;
    }
    100% {
      offset-distance: 100%;
    }
  }
</style>
