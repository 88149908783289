<!--D Grupo de readiobotones customizado -->
<script context="module" lang="ts" >
  export type CheckGroupOption = {
    display: string,
    value: string,
    enabled: boolean
  }
</script>

<script lang="ts">
  export let value = '' // Valor del grupo de inputs
  export let options: Array<CheckGroupOption> = [] // Lista de opciones para mostrar
</script>

<style>

</style>

<fieldset class='CheckGroup' >
  {#each options as option}
    <label class:disabled={!option.enabled} >
      <input type="radio" name="grupo" bind:group={value} value={option.value} disabled={!option.enabled}/>
        <div class="indicator">
          { option.display }
          { option.enabled ? "": " (no disponible)"}
          <div class="check">
            <div class="circle"> ✓ </div>
          </div>
        </div>
    </label>
  {/each}
</fieldset>

<!--E
  <CheckGroup 
    value={ '' }
    options={[
      { display: 'Esquema No Reportable', value: 1, enabled: true },
      { display: 'Esquema Reportable Generalizado', value: 2, enabled: true },
      { display: 'Esquema Reportable Personalizado', value: 3, enabled: false }
    ]}
  />
-->