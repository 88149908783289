<!--D Componente de paginador -->
<script context="module" lang="ts">
  export type PaginatorSchema = {
    page: number;
    pages: number;
    total: number;
  };
</script>

<script lang="ts" >
  import Select from './Select.svelte'
  export let schema: PaginatorSchema // Schema para el paginador: page, pages, total
  export let rowsOptions: number[] = [10, 20, 50]; // Opciones de número de filas
  export let selectedRows = rowsOptions[0] // Opcioón seleccionada
  
  export let onSchemaChange = (s) => { 
    // console.log(s)
  }
  export let onRowsChange = (s) => {}

  const onBackClick = () => {
    if (schema.page > 1) {
      const newSchema = Object.assign({}, schema)
      newSchema.page--;
      onSchemaChange(newSchema)
    }
  };

  const onNextClick = () => {
    if (schema.page < schema.pages) {
      const newSchema = Object.assign({}, schema)
      newSchema.page++;
      onSchemaChange(newSchema)
    }
  };

    const onSchemaChangePage = ( pag: number) => {
      if (schema.page != pag) {
        const newSchema = Object.assign({}, schema)
        newSchema.page = pag;
        onSchemaChange(newSchema)
      }
  };

  $: {
    onRowsChange(selectedRows)
  }

</script>

<div class="Paginator">
  <div class='info' >
    Mostrando
    <Select bind:value={selectedRows}>
      {#each rowsOptions as op}
        <option value={op}>{op}</option>
      {/each}
    </Select>
    <span class="font-weight-bold">de {(schema.total) || 0} registros</span>
  </div>

  <nav aria-label="Page navigation">
    <ul class="pagination mb-0 float-right">
      <li class="page-item">
        <a
          class="page-link waves-effect waves-effect"
          href="#"
          aria-label="Previous"
          on:click|preventDefault ={onBackClick}>
          <span aria-hidden="true">«</span>
        </a>
      </li>
      {#each Array((schema && schema.pages) || 1)
        .fill(0)
        .map((_, idx) => 1 + idx) as pag}
        <li class="page-item {schema.page == pag ? 'active' : ''}">
          <a
            class="page-link waves-effect waves-effect"
            href="#"
            on:click|preventDefault={(e) => onSchemaChangePage(pag)}> 
            {pag}
          </a>
        </li>
      {/each}
      <li class="page-item">
        <a
          class="page-link waves-effect waves-effect"
          href="#"
          aria-label="Next"
          on:click|preventDefault={onNextClick}>
          <span aria-hidden="true">»</span>
        </a>
      </li>
    </ul>
  </nav>
</div>

<style>
  .Paginator {
    display: flex;
  }

  .Paginator :global(.Select) {
    margin: 0 0.5em;
  }

  .Paginator .info {
    display: flex;
    flex: 1;
    align-items: center;
  }

 nav {
    display: block;
  }

  .float-right {
    float: right !important;
  }
  
  .pagination {
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
  }

  .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #9d2449;
    background-color: #fff;
    border: 1px solid #dee2e6;
    text-decoration: none;
  }

  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
  }

  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  .page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #9d2449;
    border-color: #9d2449;
  }

  .page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
  }

</style>

<!--E
<Paginator schema={{ page: 3, pages: 5, total: 0 }} />
-->
