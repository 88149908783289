<script lang="ts">
    /*
    30% {
        border-radius: 0%;
        margin: 0px;
    }
    */
</script>

<div>
    <div class="row">
        <div class="circle top-left"></div>
        <div class="circle bottom-left"></div>
    </div>
    <div class="row">
        <div class="circle top-right"></div>
        <div class="circle bottom-right"></div>
    </div>
</div>

<style>
    .row {
        float: left;
    }

    .circle {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        background-color: #2b6597;
        margin: 1px;
    }

    .bottom-left {
        animation: circle-bottom-left 7s ease-in-out infinite;
    }

    .top-left {
        animation: circle-top-left 7s ease-in-out infinite;
    }

    .bottom-right {
        animation: circle-bottom-right 7s ease-in-out infinite;
    }

    .top-right {
        animation: circle-top-right 7s ease-in-out infinite;
    }

    @keyframes circle-bottom-left {
        0% {
            transform: rotate(0deg);
        }
        30% {
            border-radius: 0%;
            margin: 0px;
            transform: rotate(360deg);
        }
        40% {
            border-radius: 0%;
            border-bottom-left-radius: 100%;
            margin: 0px;
        }
        60% {
            border-radius: 0%;
            border-bottom-left-radius: 100%;
            margin: 0px;
        }
        70% {
            border-radius: 0%;
            margin: 0px;
        }
        80% {
            border-radius: 50%;
            margin: 1px;
            transform: rotate(360deg);
        }
    }

    @keyframes circle-top-left {
        0% {
            transform: rotate(0deg);
        }
        30% {
            border-radius: 0%;
            margin: 0px;
            transform: rotate(360deg);
        }
        40% {
            border-radius: 0%;
            border-top-left-radius: 100%;
            margin: 0px;
        }
        60% {
            border-radius: 0%;
            border-top-left-radius: 100%;
            margin: 0px;
        }
        70% {
            border-radius: 0%;
            margin: 0px;
        }
        80% {
            border-radius: 50%;
            margin: 1px;
            transform: rotate(360deg);
        }
    }

    @keyframes circle-top-right {
        0% {
            transform: rotate(0deg);
        }
        30% {
            border-radius: 0%;
            margin: 0px;
            transform: rotate(360deg);
        }
        40% {
            border-radius: 0%;
            border-top-right-radius: 100%;
            margin: 0px;
        }
        60% {
            border-radius: 0%;
            border-top-right-radius: 100%;
            margin: 0px;
        }
        70% {
            border-radius: 0%;
            margin: 0px;
        }
        80% {
            border-radius: 50%;
            margin: 1px;
            transform: rotate(360deg);
        }
    }

    @keyframes circle-bottom-right {
        0% {
            transform: rotate(0deg);
        }
        30% {
            border-radius: 0%;
            margin: 0px;
            transform: rotate(360deg);
        }
        40% {
            border-radius: 0%;
            border-bottom-right-radius: 100%;
            margin: 0px;
        }
        60% {
            border-radius: 0%;
            border-bottom-right-radius: 100%;
            margin: 0px;
        }
        70% {
            border-radius: 0%;
            margin: 0px;
        }
        80% {
            border-radius: 50%;
            margin: 1px;
            transform: rotate(360deg);
        }
    }
</style>