import Troncal from "./troncal";
export default class CopiaENR extends Troncal {
    constructor(data, options) {
        super(options);
        this.agrega_tabla_incial_ENR(data, "VISTA PRELIMINAR DE LA"); //Omitir tras validación!
        this.y += 3;
        this.setFont("Montserrat", "bold");
        this.setFontSize(12);
        this.text_ext("CONTENIDO DE LA DECLARACIÓN", { align: "center", fontSize: 12, fontName: "Montserrat", fontStyle: "bold" });
        this.y += 5;
        this.setFontSize(7);
        this.setFont("Montserrat", "normal");
    }
}
