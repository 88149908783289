<script lang="ts">
    import { createEventDispatcher } from "svelte";
    export let name: string;
    export let options: [string, string][];

    const dispatch = createEventDispatcher();

    export let values = []

    function onChange (e) {
        const value = e.target.value
        const isIndex = values.findIndex((el) => el === value)
        const tmp = values.slice(0)
      
        if( isIndex !== -1 ) {
            tmp.splice(isIndex, 1)
        } else {
            tmp.push(value)
        }
        values = tmp

        dispatch('keyup', {});
    }
</script>

<div class="CheckboxGroup">
    {#each options as [value, label] }
        <label class:active="{ values.includes(value) }" >
            <input 
                type='checkbox'
                value={value}
                on:change={onChange}
                name={name} />
            <div class="indicator">
                <div class="detail"></div>
            </div>
            {label} 
        </label>
    {/each}
</div>

