<!--D Indicador de elementos vacios -->
<script>
  export let icon = 'fas fa-circle' // Icono a mostrar en base a font awesome
  export let size = ""
</script>

<div class={`Empty ${size}`}>
  
  <div class="icon">
    <div class="l1">
      <div class="l2">
        <div class="l3">
          <i class={icon}></i>
        </div>
      </div>
    </div>
  </div>
  <div class="message">
    <slot></slot>
  </div>
</div>


<!--E
<Empty> No se encontraron registros </Empty>
-->