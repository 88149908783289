<!--D Botón en forma de círculo  -->
<script>
      import { fly, fade } from "svelte/transition";

    export let label = '' // Label del botón
    export let loading = false // Bandera para loading
    export let icon = 'fas fa-paste' // icon based in font awesome class
</script>

<div class='CircleButton'
    on:click in:fade out:fly="{{ y: -10 }}" >
    <div class="label"> { label } </div>
    <div class="btn">
        {#if loading}
            <i class="fas fa-spinner"></i>
        {:else}
            <i class={icon}></i>
        {/if}
    </div>
</div>