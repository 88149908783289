<script lang="ts">
    import { onMount, createEventDispatcher } from "svelte";
    import { navigate } from "svelte-navigator";
    import { api } from "../Api";
    import Loading from "./Loading.svelte";

    let status: "failed" | "loading" = "loading";

    const dispatcher = createEventDispatcher();

    onMount(() => {
        api().login().then(res => {
            if(res == null) {
                // Failed login
                status = "failed";
            } else {
                dispatcher("logged");
            }
        }, r => {
            status = "failed";
            setTimeout(() => {
                navigate("/login");
            }, 5000);
        })
    });
</script>

{#if status == "failed"}
    <div>
        Ocurrió un error, y no fue posible iniciar sesión.
    </div>
{:else}
    <div style="display: flex; flex-direction:column; justify-content: center; align-items: center; height:100%;">
        <Loading/>
        <div style="margin-top: 40px;">
            Validando...
        </div>
    </div>
{/if}