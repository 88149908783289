import "jspdf-autotable";
// Función auxiliar para añadir índices a los arreglos, y hacer una especie de zip
function* arrify_duo(array_1, array_2) {
    let i = 0;
    let it1 = array_1.values(), it2 = array_2.values();
    let result1 = it1.next(), result2 = it2.next();
    while (!result1.done || !result2.done) {
        yield [i++, result1.value, result2.value];
        result1 = it1.next(), result2 = it2.next();
    }
}
function create_bar(doc, starting_page, starting_y, offset, color) {
    if (starting_page == doc.getCurrentPageInfo().pageNumber) {
        // Sólo añadimos un rectángulo en esta página
        doc.setFillColor(color[0], color[1], color[2]);
        doc.rect(doc.left + offset - 2, starting_y - 2, 1, doc.y - starting_y - 6, 'F');
    }
    else {
        // Pasó al menos una página de por medio, hay que llenar todas con la barrita
        let previous_page = doc.getCurrentPageInfo().pageNumber;
        for (let i = starting_page; i <= previous_page; i++) {
            doc.setPage(i);
            doc.setFillColor(color[0], color[1], color[2]);
            if (i == starting_page) {
                // Primer página del loop, y no será la única seguro.
                let remaining = doc.internal.pageSize.getHeight() - doc.bottom - starting_y;
                doc.rect(doc.left + offset - 2, starting_y - 2, 1, remaining, 'F');
            }
            else if (i == previous_page) {
                // Última página
                let remaining = doc.y - doc.top;
                doc.rect(doc.left + offset - 2, doc.top - 2, 1, remaining - 6, 'F');
            }
            else {
                // Páginas intermedias
                let remaining = doc.internal.pageSize.getHeight() - doc.top - doc.bottom;
                doc.rect(doc.left + offset - 2, doc.top - 2, 1, remaining - 6, 'F');
            }
        }
    }
}
export function write(doc, final_json) {
    let font_name = doc.getFont().fontName;
    let root = final_json.into_obj();
    let starting = {
        asefis: {
            __starting_y__: 0,
            __page__: 0
        },
        replegal_asefis: {
            __starting_y__: 0,
            __page__: 0
        },
        contribuyente: {
            __starting_y__: 0,
            __page__: 0
        },
        replegal_contribuyente: {
            __starting_y__: 0,
            __page__: 0
        },
        motivo: {
            __starting_y__: 0,
            __page__: 0
        },
        __starting_y__: 0,
        __page__: 0
    };
    if (root != undefined) {
        starting["__page__"] = doc.getCurrentPageInfo().pageNumber;
        starting["__starting_y__"] = doc.y;
        doc.text_ext(`Esquema No Reportable`, { align: "center", fontSize: 11, fontName: "Montserrat", fontStyle: "bold", corners: { left: doc.left + 0 } });
        doc.setFontSize(7);
        doc.setFont("Montserrat", "normal");
        doc.jump(5);
        doc.autoTable({
            startY: doc.y,
            margin: { top: doc.top, bottom: doc.bottom, left: doc.left + 0, right: doc.right },
            columnStyles: {
                0: { halign: 'left', font: font_name, fontSize: 8, fontStyle: 'bold' },
                1: { halign: 'right', font: font_name, fontSize: 8 }
            },
            body: [
                [`Fecha en que se pone a disposición el esquema reportable o se realiza el primer hecho o acto jurídico que forme parte del esquema`, root["fecha"] != undefined ? root["fecha"] : "No Aplica"],
            ]
        });
        doc.y = doc.lastAutoTable.finalY + 10;
        create_bar(doc, starting["__page__"], starting["__starting_y__"], 0, [157, 36, 73]);
        if (root["asefis"] != undefined) {
            starting["asefis"]["__page__"] = doc.getCurrentPageInfo().pageNumber;
            starting["asefis"]["__starting_y__"] = doc.y;
            doc.text_ext(`Asesor fiscal que expide la constancia`, { align: "center", fontSize: 11, fontName: "Montserrat", fontStyle: "bold", corners: { left: doc.left + 3 } });
            doc.setFontSize(7);
            doc.setFont("Montserrat", "normal");
            doc.jump(5);
            doc.autoTable({
                startY: doc.y,
                margin: { top: doc.top, bottom: doc.bottom, left: doc.left + 3, right: doc.right },
                columnStyles: {
                    0: { halign: 'left', font: font_name, fontSize: 8, fontStyle: 'bold' },
                    1: { halign: 'right', font: font_name, fontSize: 8 }
                },
                body: [
                    [`Clave en el RFC`, root["asefis"]["rfc"] != undefined ? root["asefis"]["rfc"] : "No Aplica"],
                ]
            });
            doc.y = doc.lastAutoTable.finalY + 10;
            create_bar(doc, starting["asefis"]["__page__"], starting["asefis"]["__starting_y__"], 3, [157, 36, 73]);
        }
        if (root["replegal_asefis"] != undefined) {
            starting["replegal_asefis"]["__page__"] = doc.getCurrentPageInfo().pageNumber;
            starting["replegal_asefis"]["__starting_y__"] = doc.y;
            doc.text_ext(`Representante legal del asesor fiscal`, { align: "center", fontSize: 11, fontName: "Montserrat", fontStyle: "bold", corners: { left: doc.left + 3 } });
            doc.setFontSize(7);
            doc.setFont("Montserrat", "normal");
            doc.jump(5);
            doc.autoTable({
                startY: doc.y,
                margin: { top: doc.top, bottom: doc.bottom, left: doc.left + 3, right: doc.right },
                columnStyles: {
                    0: { halign: 'left', font: font_name, fontSize: 8, fontStyle: 'bold' },
                    1: { halign: 'right', font: font_name, fontSize: 8 }
                },
                body: [
                    [`Clave en el RFC`, root["replegal_asefis"]["rfc"] != undefined ? root["replegal_asefis"]["rfc"] : "No Aplica"],
                ]
            });
            doc.y = doc.lastAutoTable.finalY + 10;
            create_bar(doc, starting["replegal_asefis"]["__page__"], starting["replegal_asefis"]["__starting_y__"], 3, [157, 36, 73]);
        }
        if (root["contribuyente"] != undefined) {
            starting["contribuyente"]["__page__"] = doc.getCurrentPageInfo().pageNumber;
            starting["contribuyente"]["__starting_y__"] = doc.y;
            doc.text_ext(`Contribuyente que recibe la constancia`, { align: "center", fontSize: 11, fontName: "Montserrat", fontStyle: "bold", corners: { left: doc.left + 3 } });
            doc.setFontSize(7);
            doc.setFont("Montserrat", "normal");
            doc.jump(5);
            doc.autoTable({
                startY: doc.y,
                margin: { top: doc.top, bottom: doc.bottom, left: doc.left + 3, right: doc.right },
                columnStyles: {
                    0: { halign: 'left', font: font_name, fontSize: 8, fontStyle: 'bold' },
                    1: { halign: 'right', font: font_name, fontSize: 8 }
                },
                body: [
                    [`Clave en el RFC`, root["contribuyente"]["rfc"] != undefined ? root["contribuyente"]["rfc"] : "No Aplica"],
                ]
            });
            doc.y = doc.lastAutoTable.finalY + 10;
            create_bar(doc, starting["contribuyente"]["__page__"], starting["contribuyente"]["__starting_y__"], 3, [157, 36, 73]);
        }
        if (root["replegal_contribuyente"] != undefined) {
            starting["replegal_contribuyente"]["__page__"] = doc.getCurrentPageInfo().pageNumber;
            starting["replegal_contribuyente"]["__starting_y__"] = doc.y;
            doc.text_ext(`Representante legal del contribuyente`, { align: "center", fontSize: 11, fontName: "Montserrat", fontStyle: "bold", corners: { left: doc.left + 3 } });
            doc.setFontSize(7);
            doc.setFont("Montserrat", "normal");
            doc.jump(5);
            doc.autoTable({
                startY: doc.y,
                margin: { top: doc.top, bottom: doc.bottom, left: doc.left + 3, right: doc.right },
                columnStyles: {
                    0: { halign: 'left', font: font_name, fontSize: 8, fontStyle: 'bold' },
                    1: { halign: 'right', font: font_name, fontSize: 8 }
                },
                body: [
                    [`Clave en el RFC`, root["replegal_contribuyente"]["rfc"] != undefined ? root["replegal_contribuyente"]["rfc"] : "No Aplica"],
                ]
            });
            doc.y = doc.lastAutoTable.finalY + 10;
            create_bar(doc, starting["replegal_contribuyente"]["__page__"], starting["replegal_contribuyente"]["__starting_y__"], 3, [157, 36, 73]);
        }
        if (root["motivo"] != undefined) {
            starting["motivo"]["__page__"] = doc.getCurrentPageInfo().pageNumber;
            starting["motivo"]["__starting_y__"] = doc.y;
            doc.text_ext(`Motivo por el que el esquema no es reportable`, { align: "center", fontSize: 11, fontName: "Montserrat", fontStyle: "bold", corners: { left: doc.left + 3 } });
            doc.setFontSize(7);
            doc.setFont("Montserrat", "normal");
            doc.jump(5);
            doc.autoTable({
                startY: doc.y,
                margin: { top: doc.top, bottom: doc.bottom, left: doc.left + 3, right: doc.right },
                columnStyles: {
                    0: { halign: 'left', font: font_name, fontSize: 8, fontStyle: 'bold' },
                    1: { halign: 'right', font: font_name, fontSize: 8 }
                },
                body: [
                    [`Motivo señalado`, (root["motivo"]["tipo_motivo"] != undefined && root["motivo"]["tipo_motivo"].length > 0) ? { "M02": "El esquema no supera el monto máximo establecido en el Acuerdo Secretarial, publicado en el Diario Oficial de la Federación el 2 de febrero de 2021", "M03": "Existe un impedimento legal para su revelación", "M01": "El esquema genera o generará, directa o indirectamente, la obtención de un beneficio fiscal en México, pero no es reportable de conformidad con el artículo 199 del Código Fiscal de la Federación" }[root["motivo"]["tipo_motivo"]] : "No Aplica"],
                    [`${root["motivo"]["motivo"] == "M01" || root["motivo"]["motivo"] == "M02" ? "Justifique y motive las razones por las cuales el esquema no es reportable" : (root["motivo"]["motivo"] == "M03" ? "Justifique y motive las razones por las cuales considera que existe un impedimento legal" : "Justificación")}`, root["motivo"]["justificacion"] != undefined ? root["motivo"]["justificacion"] : "No Aplica"],
                    [`Documento que originó el impedimento legal manfiestado`, root["motivo"]["doc_justificacion"] != undefined ? `Nombre: ${root["motivo"]["doc_justificacion"]["filename"]}\nHash: ${root["motivo"]["doc_justificacion"]["hash"]}` : "No Aplica"],
                    [`Indicar el importe del beneficio fiscal que se genera o generará, directa o indirectamente, en México`, root["motivo"]["monto_bebeficio_fiscal"] != undefined ? root["motivo"]["monto_bebeficio_fiscal"] : "No Aplica"],
                ]
            });
            doc.y = doc.lastAutoTable.finalY + 10;
            create_bar(doc, starting["motivo"]["__page__"], starting["motivo"]["__starting_y__"], 3, [157, 36, 73]);
        }
        doc.autoTable({
            startY: doc.y,
            margin: { top: doc.top, bottom: doc.bottom, left: doc.left + 0, right: doc.right },
            columnStyles: {
                0: { halign: 'left', font: font_name, fontSize: 8, fontStyle: 'bold' },
                1: { halign: 'right', font: font_name, fontSize: 8 }
            },
            body: [
                [`A efecto de dar cumplimiento con lo dispuesto en el artículo 199 del Código Fiscal de la Federación, en mi carácter de asesor fiscal, manifiesto bajo protesta de decir verdad, que se expide la siguiente constancia sin dolo ni mala fe, y que se tiene conocimiento de que en el supuesto de que la autoridad fiscal determine que el esquema objeto de la constancia sí es reportable, procederán las sanciones correspondientes conforme al Código Fiscal de la Federación.`, (root["protesta_verdad"] != undefined && root["protesta_verdad"].length > 0) ? { "S": "Sí" }[root["protesta_verdad"]] : "No Aplica"],
            ]
        });
        doc.y = doc.lastAutoTable.finalY + 10;
        create_bar(doc, starting["__page__"], starting["__starting_y__"], 0, [157, 36, 73]);
    }
}
