<!--D Titulo de sección -->
<script lang="ts">
  export let icon = 'far fa-copy' // Clase del ícono a usar de FontAwesome
  export let color: "red" | "gold" = "gold" // Color de fondo del ícono
  export let size: "big" | "normal" | "small" = "normal" // Tamaño del título

</script>

<div class={`Title ${size}`}>
  <div class={`icon ${color}`}>
    <div class="l1">
      <div class="l2">
        <div class="l3">
          <i class={icon}></i>
        </div>
      </div>
    </div>
  </div>
  <div class="text">
    <slot></slot>
  </div>
</div>

<!--E
<Title size='big'> Nueva Declaración </Title>
<Title icon='fas fa-adjust' color='red'> Declaraciones pendientes </Title>
-->