<!--D Botón de caja  -->
<script lang="ts">
  export let disabled: boolean = false // Bandera para habilitación del botón
  export let size: "large" | "normal" | "small" = "normal" // Tamaño del botón
  export let loading: boolean = false // Bandera para setear loading del botón
</script>

<button 
  class={`Button ${size}`}
  class:disabled={disabled}
  class:loading={loading}
  on:click
  disabled={disabled || loading} >
  {#if loading}
    <i class="fas fa-circle-notch"></i>
  {:else}
    <slot></slot>
  {/if}
</button>

<!--E
  <Button size='large' on:click={ () => {alert('Hey!')}} > Enviar </Button>
  <Button disabled > Presentar </Button>
  <Button size='small' loading> Presentar </Button>
-->