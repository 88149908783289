<script lang="ts">
    /* Este es un documento AUTOGENERADO, BEEP BOOP. Sólo haga cambios si sabe
    ** lo que está haciendo, pues fácilmente puede romperse la
    ** funcionalidad */
    
    import { createEventDispatcher } from "svelte";
    import * as cs from "cryptosat-ts";
    import {Tabs, TabList, Tab, TabPanel, RadioGroup, CheckboxGroup, FileInput} from '../extra/index';
    
    const dispatch = createEventDispatcher();
    
    enum FieldStatus {
        Unchecked = 0, // Estado al inicio
        Failed = 1, // Estado tras fallar la validación
        Irrelevant = 2, // Estado tras ser oculto
        Correct = 3 // Estado correcto
    }
    
    // Variable para comparaciones sin sentido
    const agctiftw: any = "agctiftw";
    // Valor histérico de la validez del cuestionario
    let hysteric_filled = false;
    
    // Función auxiliar para añadir índices a los arreglos, y hacer una especie de zip
    function *arrify_duo(array_1: any[], array_2: any[]): IterableIterator<[number, any, any]> {
        let i = 0;
        let it1 = array_1.values(), it2 = array_2.values();
        let result1 = it1.next(), result2 = it2.next();
        while (!result1.done || !result2.done) {
            yield [i++, result1.value, result2.value];
            result1 = it1.next(), result2 = it2.next();
        }
    }
    
    function *arrify_trio(array_1: any[], array_2: any[], array_3: any[]): IterableIterator<[number, any, any, any]> {
        let i = 0;
        let it1 = array_1.values(), it2 = array_2.values(), it3 = array_3.values();
        let result1 = it1.next(), result2 = it2.next(), result3 = it3.next();
        while (!result1.done || !result2.done || !result3.done) {
            yield [i++, result1.value, result2.value, result3.value];
            result1 = it1.next(), result2 = it2.next(), result3 = it3.next();
        }
    }
    
    function *arrify_quad(array_1: any[], array_2: any[], array_3: any[], array_4: any[]): IterableIterator<[number, any, any, any, any]> {
        let i = 0;
        let it1 = array_1.values(), it2 = array_2.values(), it3 = array_3.values(), it4 = array_4.values();
        let result1 = it1.next(), result2 = it2.next(), result3 = it3.next(), result4 = it4.next();
        while (!result1.done && !result2.done && !result3.done && !result4.done) {
            yield [i++, result1.value, result2.value, result3.value, result4.value];
            result1 = it1.next(), result2 = it2.next(), result3 = it3.next(), result4 = it4.next();
        }
    }
    
    function *arrify_penta(array_1: any[], array_2: any[], array_3: any[], array_4: any[], array_5: any[]): IterableIterator<[number, any, any, any, any, any]> {
        let i = 0;
        let it1 = array_1.values(), it2 = array_2.values(), it3 = array_3.values(), it4 = array_4.values(), it5 = array_5.values();
        let result1 = it1.next(), result2 = it2.next(), result3 = it3.next(), result4 = it4.next(), result5 = it5.next();
        while (!result1.done && !result2.done && !result3.done && !result4.done && !result5.done) {
            yield [i++, result1.value, result2.value, result3.value, result4.value, result5.value];
            result1 = it1.next(), result2 = it2.next(), result3 = it3.next(), result4 = it4.next(), result5 = it5.next();
        }
    }
    
    // Helper function that returns true if all levels have either FieldStatus.Correct or FieldStatus.Irrelevant
    const status_checker = (obj: any): boolean => {
        if(obj == undefined) {
            return true;
        } else {
            if(obj === FieldStatus.Correct) {
                return true
            } else if (obj === FieldStatus.Irrelevant){
                return true
            } else if (obj === FieldStatus.Failed) {
                return false
            } else if (obj === FieldStatus.Unchecked) {
                return false
            } else if(Object.prototype.toString.call(obj) === "[object Array]"){
                for(let value of obj) {
                    if(!status_checker(value)) {
                        return false;
                    }
                }
                return true;
            } else {
                for (const [key, value] of Object.entries(obj)) {
                    if(!status_checker(value)){
                        return false;
                    }
                }
                return true;
            }
        }
    }
    
    function full_validation_change(new_status: boolean) {
        dispatch("fullValidationChange", new_status);
    }
    
    export function acquire(): cs.SecureMap {
        final_json = mapify();
        return final_json;
    }
    
    function mapify(): cs.SecureMap {
        return new cs.SecureMap([
            ["fecha", root["fecha"]],
            ["asefis", root["asefis"] != undefined ? new cs.SecureMap([
                ["rfc", root["asefis"]["rfc"]],
            ]) : undefined],
            ["replegal_asefis", root["replegal_asefis"] != undefined ? new cs.SecureMap([
                ["rfc", root["replegal_asefis"]["rfc"]],
            ]) : undefined],
            ["contribuyente", root["contribuyente"] != undefined ? new cs.SecureMap([
                ["rfc", root["contribuyente"]["rfc"]],
            ]) : undefined],
            ["replegal_contribuyente", root["replegal_contribuyente"] != undefined ? new cs.SecureMap([
                ["rfc", root["replegal_contribuyente"]["rfc"]],
            ]) : undefined],
            ["motivo", root["motivo"] != undefined ? new cs.SecureMap([
                ["tipo_motivo", root["motivo"]["tipo_motivo"]],
                ["justificacion", root["motivo"]["justificacion"]],
                ["doc_justificacion", root["motivo"]["doc_justificacion"] != undefined ? new cs.SecureMap([
                    ["filename", root["motivo"]["doc_justificacion"]["filename"]],
                    ["id", root["motivo"]["doc_justificacion"]["id"]],
                    ["hash", root["motivo"]["doc_justificacion"]["hash"]],
                ]) : undefined],
                ["monto_bebeficio_fiscal", root["motivo"]["monto_bebeficio_fiscal"]],
            ]) : undefined],
            ["protesta_verdad", root["protesta_verdad"]],
        ]);
    }
    
    export function load(content: cs.SecureMap) {
        let candidate = cs.JsonValue.into_obj(content as cs.JsonValue);
        
    
        // We run, with the candidate, the status tree update
        status = {
            fecha: candidate?.["fecha"] != undefined ? (validation["fecha"].is_valid(candidate["fecha"]) ? FieldStatus.Correct : FieldStatus.Failed) : status?.["fecha"],
            asefis: {
                rfc: candidate?.["asefis"]?.["rfc"] != undefined ? (validation["asefis"]["rfc"].is_valid(candidate["asefis"]["rfc"]) ? FieldStatus.Correct : FieldStatus.Failed) : status?.["asefis"]?.["rfc"],
            },
            replegal_asefis: {
                rfc: candidate?.["replegal_asefis"]?.["rfc"] != undefined ? (validation["replegal_asefis"]["rfc"].is_valid(candidate["replegal_asefis"]["rfc"]) ? FieldStatus.Correct : FieldStatus.Failed) : status?.["replegal_asefis"]?.["rfc"],
            },
            contribuyente: {
                rfc: candidate?.["contribuyente"]?.["rfc"] != undefined ? (validation["contribuyente"]["rfc"].is_valid(candidate["contribuyente"]["rfc"]) ? FieldStatus.Correct : FieldStatus.Failed) : status?.["contribuyente"]?.["rfc"],
            },
            replegal_contribuyente: {
                rfc: candidate?.["replegal_contribuyente"]?.["rfc"] != undefined ? (validation["replegal_contribuyente"]["rfc"].is_valid(candidate["replegal_contribuyente"]["rfc"]) ? FieldStatus.Correct : FieldStatus.Failed) : status?.["replegal_contribuyente"]?.["rfc"],
            },
            motivo: {
                tipo_motivo: candidate?.["motivo"]?.["tipo_motivo"] != undefined ? (validation["motivo"]["tipo_motivo"].is_valid(candidate["motivo"]["tipo_motivo"]) ? FieldStatus.Correct : FieldStatus.Failed) : status?.["motivo"]?.["tipo_motivo"],
                justificacion: candidate?.["motivo"]?.["justificacion"] != undefined ? (validation["motivo"]["justificacion"].is_valid(candidate["motivo"]["justificacion"]) ? FieldStatus.Correct : FieldStatus.Failed) : status?.["motivo"]?.["justificacion"],
                doc_justificacion: candidate?.["motivo"]?.["doc_justificacion"]?.["id"] != undefined ? FieldStatus.Correct : status["motivo"]["doc_justificacion"],
                monto_bebeficio_fiscal: candidate?.["motivo"]?.["monto_bebeficio_fiscal"] != undefined ? (validation["motivo"]["monto_bebeficio_fiscal"].is_valid(candidate["motivo"]["monto_bebeficio_fiscal"]) ? FieldStatus.Correct : FieldStatus.Failed) : status?.["motivo"]?.["monto_bebeficio_fiscal"],
            },
            protesta_verdad: candidate?.["protesta_verdad"] != undefined ? (validation["protesta_verdad"].is_valid(candidate["protesta_verdad"]) ? FieldStatus.Correct : FieldStatus.Failed) : status?.["protesta_verdad"],
        };
        
        root = candidate;
    }
    
    
    let final_json: cs.SecureMap;
    
    let root = {
        fecha: undefined,
        asefis: {
            rfc: undefined,
        },
        replegal_asefis: {
            rfc: undefined,
        },
        contribuyente: {
            rfc: undefined,
        },
        replegal_contribuyente: {
            rfc: undefined,
        },
        motivo: {
            tipo_motivo: undefined,
            justificacion: undefined,
            doc_justificacion: {
                filename: undefined,
                id: undefined,
                hash: undefined,
            },
            monto_bebeficio_fiscal: undefined,
        },
        protesta_verdad: undefined,
    }
    
    let validation = {
        fecha: new cs.Validator(cs.JsonValue.from_obj({"type":"string","format":"date"})),
        asefis: {
            rfc: new cs.Validator(cs.JsonValue.from_obj({"type":"string","pattern":"^([A-ZÑ]|\\&){3,4}[0-9]{2}(0[1-9]|1[0-2])([12][0-9]|0[1-9]|3[01])[A-Z0-9]{3}$"})),
        },
        replegal_asefis: {
            rfc: new cs.Validator(cs.JsonValue.from_obj({"type":"string","pattern":"^([A-ZÑ]){4}[0-9]{2}(0[1-9]|1[0-2])([12][0-9]|0[1-9]|3[01])[A-Z0-9]{3}$"})),
        },
        contribuyente: {
            rfc: new cs.Validator(cs.JsonValue.from_obj({"type":"string","pattern":"^([A-ZÑ]|\\&){3,4}[0-9]{2}(0[1-9]|1[0-2])([12][0-9]|0[1-9]|3[01])[A-Z0-9]{3}$"})),
        },
        replegal_contribuyente: {
            rfc: new cs.Validator(cs.JsonValue.from_obj({"type":"string","pattern":"^([A-ZÑ]){4}[0-9]{2}(0[1-9]|1[0-2])([12][0-9]|0[1-9]|3[01])[A-Z0-9]{3}$"})),
        },
        motivo: {
            tipo_motivo: new cs.Validator(cs.JsonValue.from_obj({"enum":["M01","M02","M03"]})),
            justificacion: new cs.Validator(cs.JsonValue.from_obj({"type":"string"})),
            doc_justificacion: new cs.Validator(cs.JsonValue.from_obj({"type":"file","extensions":["pdf"]})),
            monto_bebeficio_fiscal: new cs.Validator(cs.JsonValue.from_obj({"type":"integer"})),
        },
        protesta_verdad: new cs.Validator(cs.JsonValue.from_obj({"enum":["S"]})),
    }
    
    let status = {
        fecha: FieldStatus.Unchecked,
        asefis: {
            rfc: FieldStatus.Unchecked,
        },
        replegal_asefis: {
            rfc: FieldStatus.Unchecked,
        },
        contribuyente: {
            rfc: FieldStatus.Unchecked,
        },
        replegal_contribuyente: {
            rfc: FieldStatus.Unchecked,
        },
        motivo: {
            tipo_motivo: FieldStatus.Unchecked,
            justificacion: FieldStatus.Unchecked,
            doc_justificacion: FieldStatus.Unchecked,
            monto_bebeficio_fiscal: FieldStatus.Unchecked,
        },
        protesta_verdad: FieldStatus.Unchecked,
    }
    
    let conditions = {
        asefis: {
        },
        replegal_asefis: {
        },
        contribuyente: {
        },
        replegal_contribuyente: {
        },
        motivo: {
            all_of: [new cs.Validator(cs.JsonValue.from_obj({
            "type": "object",
            "properties": {
                "tipo_motivo": {
                    "const": "M01"
                }
            },
            "required": [
                "tipo_motivo"
            ]
        })),new cs.Validator(cs.JsonValue.from_obj({
            "type": "object",
            "properties": {
                "tipo_motivo": {
                    "const": "M02"
                }
            },
            "required": [
                "tipo_motivo"
            ]
        })),new cs.Validator(cs.JsonValue.from_obj({
            "type": "object",
            "properties": {
                "tipo_motivo": {
                    "const": "M03"
                }
            },
            "required": [
                "tipo_motivo"
            ]
        }))],
        },
        all_of: [new cs.Validator(cs.JsonValue.from_obj({
        "type": "object",
        "properties": {
            "asefis": {
                "type": "object",
                "properties": {
                    "rfc": {
                        "type": "string",
                        "minLength": 12,
                        "maxLength": 12
                    }
                },
                "required": [
                    "rfc"
                ]
            }
        },
        "required": [
            "asefis"
        ]
    })),new cs.Validator(cs.JsonValue.from_obj({
        "type": "object",
        "properties": {
            "contribuyente": {
                "type": "object",
                "properties": {
                    "rfc": {
                        "type": "string",
                        "minLength": 12,
                        "maxLength": 12
                    }
                },
                "required": [
                    "rfc"
                ]
            }
        },
        "required": [
            "contribuyente"
        ]
    }))],
    }
    
    
    let evaluations: any;
    
    $: if(root != undefined) {
        evaluations = {
            asefis: {
            },
            replegal_asefis: {
            },
            contribuyente: {
            },
            replegal_contribuyente: {
            },
            motivo: {
                all_of: root["motivo"] != undefined ? conditions["motivo"]["all_of"].map(tmp => tmp.is_valid(cs.JsonValue.from_obj(root["motivo"]))) : [],
            },
            all_of: root != undefined ? conditions["all_of"].map(tmp => tmp.is_valid(cs.JsonValue.from_obj(root))) : [],
        }
    
        let curr_status = status_checker(status);
        if(curr_status != hysteric_filled) {
            hysteric_filled = curr_status;
            full_validation_change(curr_status);
        }
    }
    
    function _arrayBufferToBase64( buffer: ArrayBuffer ) {
        var binary = '';
        var bytes = new Uint8Array( buffer );
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
        }
        return window.btoa( binary );
    }
    
    async function deal_with_file(e: Event, hash_object: any, hash_field_name: string, field_id: string): Promise<FieldStatus> {
        try {
            let target = e.target as any;
            if(target.files.length > 0) {
                let file = target.files[0];
                let data = await new Response(file).arrayBuffer();
                let hash = _arrayBufferToBase64(await window.crypto.subtle.digest('SHA-256', data));
                let res = await on_file_upload(file, field_id);
                
                hash_object[hash_field_name]["filename"] = file.name;
                hash_object[hash_field_name]["id"] = res;
                hash_object[hash_field_name]["hash"] = hash;
    
                root = root;
                return Promise.resolve(FieldStatus.Correct);
            }
        } catch(err) {
            // console.log(err);
            (e.target as any).value = '';
            return Promise.resolve(FieldStatus.Failed);
        }
    }
    
    async function delete_file(target: {filename: string, id: string, hash: string} | undefined): Promise<null> {
        try {
            if(target != undefined){
                await on_delete_file(target["id"]);
            }
        } catch(err) {
            // console.log(err);
            return Promise.reject();
        }
    }
    
    // Callback function for when a file is meant to be uploaded. If a string is returned,
    // it is just logged back to the console, and the file is removed from the input.
    export let on_file_upload: (file: File, id: string) => Promise<string>;
    
    // Callback function for when a file is meant to be recovered. The error is handled as
    // in on_file_upload.
    export let on_recover_file: (id: string) => Promise<null>;
    
    // Callback function for when a file is meant to be deleted. The error is handled as
    // in on_file_upload.
    export let on_delete_file: (id: string) => Promise<null>;
    
</script>


<div class="root-level-0">
    <div class="group-label">
        <div>Esquema No Reportable</div>
    </div>
    <div class="input-container">
        <label for="dummy">{#if status_checker(status["fecha"])}<i class="fas fa-check-circle" style="margin-right: 5px; color: #50a829;"></i>{/if}{`Fecha en que se pone a disposición el esquema reportable o se realiza el primer hecho o acto jurídico que forme parte del esquema`}</label>
        <input class:valid={status["fecha"] === FieldStatus.Correct} bind:value={root["fecha"]} on:change={() => {
            if(root["fecha"] != undefined) {
                status["fecha"] = validation["fecha"].is_valid(root["fecha"]) ? FieldStatus.Correct : FieldStatus.Failed;
            } else {
                status["fecha"] = FieldStatus.Unchecked;
            }
        }} type="date" class:invalid={status["fecha"] === FieldStatus.Failed}/>
    </div>
    <div class="root-level-1">
        <div class="group-label">
            <div>Asesor fiscal que expide la constancia</div>
        </div>
        <div class="input-container">
            <label for="dummy">{#if status_checker(status["asefis"]["rfc"])}<i class="fas fa-check-circle" style="margin-right: 5px; color: #50a829;"></i>{/if}{`Clave en el RFC`}</label>
            <input class:invalid={status["asefis"]["rfc"] === FieldStatus.Failed} class:valid={status["asefis"]["rfc"] === FieldStatus.Correct} on:keyup={() => {
                if(root["asefis"]["rfc"] != undefined) {
                    status["asefis"]["rfc"] = validation["asefis"]["rfc"].is_valid(root["asefis"]["rfc"]) ? FieldStatus.Correct : FieldStatus.Failed;
                } else {
                    status["asefis"]["rfc"] = FieldStatus.Unchecked;
                }
            }} type="text" bind:value={root["asefis"]["rfc"]}/>
        </div>
    </div>
    {#if (evaluations["all_of"][0] && (((root["replegal_asefis"] == undefined ? root["replegal_asefis"] = { rfc: undefined,} : null) !== undefined) && ((status["replegal_asefis"] == undefined ? status["replegal_asefis"] = { rfc: FieldStatus.Unchecked,} : null) !== undefined))) || (((root["replegal_asefis"] != undefined ? root["replegal_asefis"] = undefined : undefined) === agctiftw) || ((status["replegal_asefis"] != undefined ? status["replegal_asefis"] = undefined : undefined) === agctiftw))}
        <div class="root-level-1">
            <div class="group-label">
                <div>Representante legal del asesor fiscal</div>
            </div>
            <div class="input-container">
                <label for="dummy">{#if status_checker(status["replegal_asefis"]["rfc"])}<i class="fas fa-check-circle" style="margin-right: 5px; color: #50a829;"></i>{/if}{`Clave en el RFC`}</label>
                <input type="text" class:valid={status["replegal_asefis"]["rfc"] === FieldStatus.Correct} bind:value={root["replegal_asefis"]["rfc"]} on:keyup={() => {
                    if(root["replegal_asefis"]["rfc"] != undefined) {
                        status["replegal_asefis"]["rfc"] = validation["replegal_asefis"]["rfc"].is_valid(root["replegal_asefis"]["rfc"]) ? FieldStatus.Correct : FieldStatus.Failed;
                    } else {
                        status["replegal_asefis"]["rfc"] = FieldStatus.Unchecked;
                    }
                }} class:invalid={status["replegal_asefis"]["rfc"] === FieldStatus.Failed}/>
            </div>
        </div>
    {/if}
    <div class="root-level-1">
        <div class="group-label">
            <div>Contribuyente que recibe la constancia</div>
        </div>
        <div class="input-container">
            <label for="dummy">{#if status_checker(status["contribuyente"]["rfc"])}<i class="fas fa-check-circle" style="margin-right: 5px; color: #50a829;"></i>{/if}{`Clave en el RFC`}</label>
            <input class:invalid={status["contribuyente"]["rfc"] === FieldStatus.Failed} on:keyup={() => {
                if(root["contribuyente"]["rfc"] != undefined) {
                    status["contribuyente"]["rfc"] = validation["contribuyente"]["rfc"].is_valid(root["contribuyente"]["rfc"]) ? FieldStatus.Correct : FieldStatus.Failed;
                } else {
                    status["contribuyente"]["rfc"] = FieldStatus.Unchecked;
                }
            }} type="text" bind:value={root["contribuyente"]["rfc"]} class:valid={status["contribuyente"]["rfc"] === FieldStatus.Correct}/>
        </div>
    </div>
    {#if (evaluations["all_of"][1] && (((root["replegal_contribuyente"] == undefined ? root["replegal_contribuyente"] = { rfc: undefined,} : null) !== undefined) && ((status["replegal_contribuyente"] == undefined ? status["replegal_contribuyente"] = { rfc: FieldStatus.Unchecked,} : null) !== undefined))) || (((root["replegal_contribuyente"] != undefined ? root["replegal_contribuyente"] = undefined : undefined) === agctiftw) || ((status["replegal_contribuyente"] != undefined ? status["replegal_contribuyente"] = undefined : undefined) === agctiftw))}
        <div class="root-level-1">
            <div class="group-label">
                <div>Representante legal del contribuyente</div>
            </div>
            <div class="input-container">
                <label for="dummy">{#if status_checker(status["replegal_contribuyente"]["rfc"])}<i class="fas fa-check-circle" style="margin-right: 5px; color: #50a829;"></i>{/if}{`Clave en el RFC`}</label>
                <input bind:value={root["replegal_contribuyente"]["rfc"]} class:valid={status["replegal_contribuyente"]["rfc"] === FieldStatus.Correct} class:invalid={status["replegal_contribuyente"]["rfc"] === FieldStatus.Failed} type="text" on:keyup={() => {
                    if(root["replegal_contribuyente"]["rfc"] != undefined) {
                        status["replegal_contribuyente"]["rfc"] = validation["replegal_contribuyente"]["rfc"].is_valid(root["replegal_contribuyente"]["rfc"]) ? FieldStatus.Correct : FieldStatus.Failed;
                    } else {
                        status["replegal_contribuyente"]["rfc"] = FieldStatus.Unchecked;
                    }
                }}/>
            </div>
        </div>
    {/if}
    <div class="root-level-1">
        <div class="group-label">
            <div>Motivo por el que el esquema no es reportable</div>
        </div>
        <div class="input-container">
            <label for="dummy">{#if status_checker(status["motivo"]["tipo_motivo"])}<i class="fas fa-check-circle" style="margin-right: 5px; color: #50a829;"></i>{/if}{`Motivo señalado`}</label>
            <RadioGroup bind:value={root["motivo"]["tipo_motivo"]} change={() => {
                if(root["motivo"]["tipo_motivo"] != undefined) {
                    status["motivo"]["tipo_motivo"] = validation["motivo"]["tipo_motivo"].is_valid(root["motivo"]["tipo_motivo"]) ? FieldStatus.Correct : FieldStatus.Failed;
                } else {
                    status["motivo"]["tipo_motivo"] = FieldStatus.Unchecked;
                }
            }} name="tipo_motivo" options={[["M01", "El esquema genera o generará, directa o indirectamente, la obtención de un beneficio fiscal en México, pero no es reportable de conformidad con el artículo 199 del Código Fiscal de la Federación"], ["M02", "El esquema no supera el monto máximo establecido en el Acuerdo Secretarial, publicado en el Diario Oficial de la Federación el 2 de febrero de 2021"], ["M03", "Existe un impedimento legal para su revelación"]]}/>
        </div>
        {#if ((evaluations["motivo"]["all_of"][0] || evaluations["motivo"]["all_of"][1] || evaluations["motivo"]["all_of"][2]) && (((status["motivo"]["justificacion"] == FieldStatus.Irrelevant || status["motivo"]["justificacion"] == undefined) ? status["motivo"]["justificacion"] = FieldStatus.Unchecked : null) !== undefined)) || (((root["motivo"]["justificacion"] != undefined ? root["motivo"]["justificacion"] = undefined : undefined) === agctiftw) || ((status["motivo"]["justificacion"] != undefined ? status["motivo"]["justificacion"] = undefined : undefined) === agctiftw))}
            <div class="input-container">
                <label for="dummy">{#if status_checker(status["motivo"]["justificacion"])}<i class="fas fa-check-circle" style="margin-right: 5px; color: #50a829;"></i>{/if}{`${root["motivo"]["motivo"] == "M01" || root["motivo"]["motivo"] == "M02" ? "Justifique y motive las razones por las cuales el esquema no es reportable" : (root["motivo"]["motivo"] == "M03" ? "Justifique y motive las razones por las cuales considera que existe un impedimento legal" : "Justificación")}`}</label>
                <input bind:value={root["motivo"]["justificacion"]} on:keyup={() => {
                    if(root["motivo"]["justificacion"] != undefined) {
                        status["motivo"]["justificacion"] = validation["motivo"]["justificacion"].is_valid(root["motivo"]["justificacion"]) ? FieldStatus.Correct : FieldStatus.Failed;
                    } else {
                        status["motivo"]["justificacion"] = FieldStatus.Unchecked;
                    }
                }} class:valid={status["motivo"]["justificacion"] === FieldStatus.Correct} type="text" class:invalid={status["motivo"]["justificacion"] === FieldStatus.Failed}/>
            </div>
        {/if}
        {#if (evaluations["motivo"]["all_of"][2] && (((root["motivo"]["doc_justificacion"] == undefined ? root["motivo"]["doc_justificacion"] = { filename: undefined, id: undefined, hash: undefined,} : null) !== undefined) && ((status["motivo"]["doc_justificacion"] == undefined ? status["motivo"]["doc_justificacion"] = FieldStatus.Unchecked : null) !== undefined))) || (((root["motivo"]["doc_justificacion"] != undefined ? root["motivo"]["doc_justificacion"] = undefined : undefined) === agctiftw) || ((status["motivo"]["doc_justificacion"] != undefined ? status["motivo"]["doc_justificacion"] = undefined : undefined) === agctiftw))}
            <FileInput hash_object={root["motivo"]} label={`Documento que originó el impedimento legal manfiestado`} valid={status["motivo"]["doc_justificacion"] == FieldStatus.Correct} hash_field_name="doc_justificacion" field_id="root_motivo_doc_justificacion" accept="pdf" on_recover_file={on_recover_file} deal_with_file={async (e, hash_object, hash_field_name, field_id) => {status["motivo"]["doc_justificacion"] = await deal_with_file(e, hash_object, hash_field_name, field_id);}}/>
        {/if}
        {#if (evaluations["motivo"]["all_of"][1] && (((status["motivo"]["monto_bebeficio_fiscal"] == FieldStatus.Irrelevant || status["motivo"]["monto_bebeficio_fiscal"] == undefined) ? status["motivo"]["monto_bebeficio_fiscal"] = FieldStatus.Unchecked : null) !== undefined)) || (((root["motivo"]["monto_bebeficio_fiscal"] != undefined ? root["motivo"]["monto_bebeficio_fiscal"] = undefined : undefined) === agctiftw) || ((status["motivo"]["monto_bebeficio_fiscal"] != undefined ? status["motivo"]["monto_bebeficio_fiscal"] = undefined : undefined) === agctiftw))}
            <div class="input-container">
                <label for="dummy">{#if status_checker(status["motivo"]["monto_bebeficio_fiscal"])}<i class="fas fa-check-circle" style="margin-right: 5px; color: #50a829;"></i>{/if}{`Indicar el importe del beneficio fiscal que se genera o generará, directa o indirectamente, en México`}</label>
                <input type="number" on:keyup={() => {
                    if(root["motivo"]["monto_bebeficio_fiscal"] != undefined) {
                        status["motivo"]["monto_bebeficio_fiscal"] = validation["motivo"]["monto_bebeficio_fiscal"].is_valid(root["motivo"]["monto_bebeficio_fiscal"]) ? FieldStatus.Correct : FieldStatus.Failed;
                    } else {
                        status["motivo"]["monto_bebeficio_fiscal"] = FieldStatus.Unchecked;
                    }
                }} class:valid={status["motivo"]["monto_bebeficio_fiscal"] === FieldStatus.Correct} bind:value={root["motivo"]["monto_bebeficio_fiscal"]} class:invalid={status["motivo"]["monto_bebeficio_fiscal"] === FieldStatus.Failed}/>
            </div>
        {/if}
    </div>
    <div class="input-container">
        <label for="dummy">{#if status_checker(status["protesta_verdad"])}<i class="fas fa-check-circle" style="margin-right: 5px; color: #50a829;"></i>{/if}{`A efecto de dar cumplimiento con lo dispuesto en el artículo 199 del Código Fiscal de la Federación, en mi carácter de asesor fiscal, manifiesto bajo protesta de decir verdad, que se expide la siguiente constancia sin dolo ni mala fe, y que se tiene conocimiento de que en el supuesto de que la autoridad fiscal determine que el esquema objeto de la constancia sí es reportable, procederán las sanciones correspondientes conforme al Código Fiscal de la Federación.`}</label>
        <RadioGroup options={[["S", "Sí"]]} name="protesta_verdad" bind:value={root["protesta_verdad"]} change={() => {
            if(root["protesta_verdad"] != undefined) {
                status["protesta_verdad"] = validation["protesta_verdad"].is_valid(root["protesta_verdad"]) ? FieldStatus.Correct : FieldStatus.Failed;
            } else {
                status["protesta_verdad"] = FieldStatus.Unchecked;
            }
        }}/>
    </div>
</div>

<style>
    
</style>