import { instanceOfOF, instanceOfUI, schema } from "./objects";
import * as cs from "cryptosat-ts";
import { ApiCore } from "./apicore";
const API_VERSION = "/api/v1";
// Interfaz de comunicación con el Api del servicio
export default class RealApi extends ApiCore {
    constructor() {
        super();
        // Variable que indica si el usuario está loggeado, pero es INSEGURO de usar, puesto que si
        // el usuario olvida llamar is_logged o no se acaba de realizar el inicio de sesión, la
        // estructura estará vacía (undefined).
        this.user_info_unsafe = undefined;
    }
    // Esta liga hace la redirección con los parámetros adecuados para realizar la autenticación por OAuth.
    async do_oauth() {
        try {
            let response = await fetch(`${API_VERSION}/get_oauth_parameters`);
            if (response.status == 200) {
                // Todo bien
                let json = await response.json();
                if (instanceOfOF(json)) {
                    // Ahora, creamos la URL para mandar al portal de autenticación
                    const reducer = (accumulator, currentValue) => {
                        return accumulator + (accumulator.length == 0 ? "?" : "&") + encodeURIComponent(currentValue) + "=" + encodeURIComponent(json[currentValue]);
                    };
                    let keys = Object.keys(json);
                    const index = keys.indexOf("authentication_url");
                    keys.splice(index, 1);
                    // Por falta de homogenizar los parámetros del oauth, se tiene que hacer un replace
                    let target_url = json["authentication_url"] + keys.reduce(reducer, "").replace("resource_server", "resourceServer");
                    window.location.href = target_url;
                    Promise.resolve(); // Este punto nunca se alcanza
                }
                else {
                    return Promise.reject("la estructura no contiene los campos mínimos necesarios XX");
                }
            }
            else {
                return Promise.reject("código de respuesta insatisfactorio (" + String(response.status) + ")");
            }
        }
        catch (err) {
            return Promise.reject(String(err));
        }
    }
    // Pide hacer un login con la aplicación. Esta función debe llamarse exclusivamente desde el punto definido
    // como login, pues la función automáticamente leerá los parámetros del query.
    //
    // Un "true" implica un inicio de sesión exitoso. Un false implica un inicio fallido.
    async login() {
        try {
            // We parse the query
            const reducer = (accumulator, currentValue) => {
                let toks = currentValue.split(/=(.+)/, 2);
                if (toks.length < 2)
                    throw new Error(`la uri no tiene una estructura correcta (componentes no empatados, ${toks.length})`);
                accumulator[decodeURIComponent(toks[0])] = decodeURIComponent(toks[1]);
                return accumulator;
            };
            let tokens = window.location.search.slice(1).split("&");
            // console.log(tokens);
            let token_length = tokens.length;
            let second = tokens.reduce(reducer, {});
            if (token_length != 2 && token_length != 3)
                throw new Error("la uri no tiene la estructura correcta");
            let response = await fetch(`${API_VERSION}/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(second)
            });
            if (response.status == 401) {
                // El inicio de sesión fue incorrecto
                this.user_info_unsafe = null;
                return Promise.resolve(null);
            }
            else if (response.status == 200) {
                // Dado que tenemos un 400, pedimos la info del usuario
                let response = await this.is_logged();
                if (response == null) {
                    // el inicio no fue correcto
                    this.user_info_unsafe = null;
                    return Promise.resolve(null);
                }
                else {
                    this.user_info_unsafe = response;
                    return Promise.resolve(response);
                }
            }
            else {
                return Promise.reject("código de respuesta inesperado (" + String(response.status) + ")");
            }
        }
        catch (err) {
            return Promise.reject(String(err));
        }
    }
    async logout() {
        try {
            let response = await fetch(`${API_VERSION}/logout`);
            if (response.status == 200) {
                // Ahora la redirección
                this.user_info_unsafe = null;
                window.location.href = "https://login.cloudb.sat.gob.mx/nidp/app/logout";
                // Línea inalcanzable
                return Promise.resolve(true);
            }
            else if (response.status == 401) {
                this.user_info_unsafe = null;
                return Promise.resolve(false);
            }
            else {
                return Promise.reject("el código de respuesta es inesperado (" + String(response.status) + ")");
            }
        }
        catch (err) {
            return Promise.reject(String(err));
        }
    }
    // Función que te dice si el usuario tiene una cookie válida
    async is_logged() {
        try {
            let response = await fetch(`${API_VERSION}/user_info`);
            if (response.status == 401) {
                this.user_info_unsafe = null;
                return Promise.resolve(null);
            }
            else if (response.status == 200) {
                // Verificamos que el texto sea lo que creemos que debe ser
                let json = await response.json();
                if (instanceOfUI(json)) {
                    this.user_info_unsafe = json;
                    return Promise.resolve(json);
                }
                else {
                    return Promise.reject("la estructura no contiene la información adecuada");
                }
            }
            else {
                return Promise.reject("código de respuesta inesperado (" + String(response.status) + ")");
            }
        }
        catch (err) {
            return Promise.reject(String(err));
        }
    }
    // Pide la información del usuario. Requiere que haya una sesión válida iniciada, en caso de no contar con una,
    // se obtendrá null.
    async user_info() {
        try {
            let response = await fetch(`${API_VERSION}/user_info`);
            if (response.status == 401) {
                // El inicio de sesión fue incorrecto
                this.user_info_unsafe = null;
                return Promise.resolve(null);
            }
            else if (response.status == 200) {
                let json = await response.json();
                if (instanceOfUI(json)) {
                    this.user_info_unsafe = json;
                    return Promise.resolve(json);
                }
                else {
                    return Promise.reject("la estructura no contiene los campos mínimos necesarios");
                }
            }
            else {
                return Promise.reject("código de respuesta inesperado (" + String(response.status) + ")");
            }
        }
        catch (err) {
            return Promise.reject(String(err));
        }
    }
    // Guarda un formulario en base de datos
    async save_form(form, type_schema, related_folio, type_taxpayer, folio) {
        return new Promise(async (resolve, reject) => {
            try {
                // console.warn(form.serialize())
                const user = await this.is_logged();
                if (user) {
                    if (folio != undefined) {
                        this.apiJsonPatch(`${API_VERSION}/forms/${folio}`, {
                            type: related_folio != undefined ? 2 : 1,
                            status: 1,
                            type_schema,
                            fiscal_exercise: 2024,
                            type_taxpayer,
                            json_declaracion: form.serialize(),
                            folio_related: related_folio
                        }).then((response) => {
                            if (response.status == 200 && response.result) {
                                resolve(folio);
                            }
                            else {
                                resolve(null);
                            }
                        }).catch((response) => {
                            if (response.status == 401) {
                                // El inicio de sesión fue incorrecto
                                this.user_info_unsafe = null;
                                reject("");
                            }
                            else if (response.status == 403) {
                                // No se permite la actualización
                                this.user_info_unsafe = null;
                                reject("");
                            }
                            else {
                                reject(reject(response.messageError));
                            }
                            reject(`Código de respuesta inesperado ( ${JSON.stringify(response)})`);
                        });
                    }
                    else {
                        this.apiJsonPost(`${API_VERSION}/forms`, {
                            type: related_folio != undefined ? 2 : 1,
                            status: 1,
                            type_schema,
                            type_taxpayer,
                            fiscal_exercise: 2024,
                            json: form.serialize(),
                            folio_related: related_folio
                        }).then((response) => {
                            if (response.status == 200) {
                                resolve(response.result["folio"]);
                            }
                            else {
                                resolve(null);
                            }
                        }).catch((response) => {
                            if (response.status == 401) {
                                // El inicio de sesión fue incorrecto
                                this.user_info_unsafe = null;
                                reject("");
                            }
                            else if (response.status == 403) {
                                // console.warn(response);
                                // No se permite la actualización
                                this.user_info_unsafe = null;
                                reject("");
                            }
                            else {
                                reject(reject(response.messageError));
                            }
                            reject(`Código de respuesta inesperado ( ${JSON.stringify(response)})`);
                        });
                    }
                }
                else {
                    reject("No existe una sesión o esta ha expirado");
                }
            }
            catch (err_1) {
                reject(`código de respuesta inesperado ( ${JSON.stringify(err_1)})`);
            }
        });
    }
    async get_form(folio) {
        // Convierte una cadena (que debe ser un json) en un diccionario
        function from_string(target) {
            let js = JSON.parse(target);
            let myMap = new cs.SecureMap();
            // Todo lo que parezca una llave va a hacer match
            let myRegex = /\"([^"]+)\":/g;
            let foundKeys = target.match(myRegex);
            let keys = [];
            // Descartar las llaves que no se encuentran en el primer nivel
            foundKeys.forEach(function (key, idx, arr) {
                let k = key.split('"')[1];
                if (js.hasOwnProperty(k)) {
                    keys.push(k);
                }
            });
            const array_splitter = (content) => {
                content = content.trim();
                const ICONS = {
                    "{": "}",
                    "[": "]"
                };
                let idx = 0;
                let stack = [];
                let items = [];
                let quoted = false;
                if (content.length < 2 || content[0] != "[" || content[content.length - 1] != "]") {
                    // console.log("Error", content);
                    throw "La cadena no es un array válido";
                }
                content = content.slice(1, content.length - 1).trim();
                while (content.length != 0 && idx < content.length) {
                    if (content[idx] == "\\") {
                        idx += 1;
                        if (idx == content.length)
                            throw "El json tiene un caracter de escape sin consecuencia";
                    }
                    else if (content[idx] == "\"") {
                        quoted = !quoted;
                    }
                    else if (!quoted) {
                        let curr_icon = ICONS[content[idx]];
                        if (curr_icon != undefined)
                            stack.push(curr_icon);
                        else if (content[idx] == stack[stack.length - 1])
                            stack.pop();
                        else if (content[idx] == "," && stack.length == 0) {
                            items.push(content.slice(0, idx));
                            content = content.slice(idx + 1).trim();
                            idx = -1;
                        }
                    }
                    idx += 1;
                }
                ;
                if (quoted)
                    throw "Parece que el json contiene comillas sin cerrar";
                if (stack.length != 0)
                    throw "La cadena no parece ser un json válido";
                if (content.length != 0)
                    items.push(content.trim());
                return items;
            };
            //Alimentamos el map. En caso de que el valor de una llave sea un objeto, verifica si, asu vez, se tiene que convertir en map
            keys.forEach(function (key, idx) {
                let first_cut = '"' + key + '":';
                let second_cut = keys[idx + 1];
                let vk = target.split(first_cut)[1].split(second_cut)[0].slice(0, -1).replace(/,*$/, "");
                // console.log(`Cadena original  ${target}`)
                // console.log(`Partiendo la cadena original entre '${first_cut}' y '${second_cut}'`);
                // console.log(`Cadena resultado  ${vk}`)
                if (Object.prototype.toString.call(js[key]) == "[object Object]") {
                    let mm = from_string(vk);
                    myMap.set(key, mm);
                }
                else if (Object.prototype.toString.call(js[key]) == "[object Array]") {
                    for (var i = 0; i < js[key].length; i++) {
                        if (Object.prototype.toString.call(js[key][i]) == "[object Object]") {
                            js[key] = array_splitter(vk).map(target => from_string(target));
                        }
                    }
                    myMap.set(key, js[key]);
                }
                else {
                    myMap.set(key, js[key]);
                }
            });
            return myMap;
        }
        try {
            let response = await fetch(`/api/v1/forms/${folio}`, {
                method: "GET"
            });
            if (response.status == 401) {
                // El inicio de sesión fue incorrecto
                this.user_info_unsafe = null;
                return Promise.reject("");
            }
            else if (response.status == 200) {
                let form_json = await response.json();
                let result = schema.from_map(form_json.result);
                if (result) {
                    if (result._json_declaracion) {
                        let final = from_string(result._json_declaracion.slice(1, result._json_declaracion.length - 1).replace(/\\"/g, "\""));
                        if (cs.utils.isError(final)) {
                            return Promise.reject(final);
                        }
                        else {
                            return Promise.resolve(final);
                        }
                    }
                    else {
                        return Promise.resolve(null);
                    }
                }
                else {
                    return Promise.reject("La respuesta no tiene la estructura adecuada");
                }
            }
            else {
                return Promise.reject("código de respuesta inesperado (" + String(response.status) + ")");
            }
        }
        catch (err) {
            return Promise.reject(String(err));
        }
    }
    async delete_form(folio) {
        try {
            let response = await fetch(`/api/v1/forms/${folio}`, {
                method: "DELETE"
            });
            if (response.status == 401) {
                // El inicio de sesión fue incorrecto
                this.user_info_unsafe = null;
                return Promise.reject("");
            }
            else if (response.status == 200) {
                return Promise.resolve(null);
            }
            else {
                return Promise.reject("código de respuesta inesperado (" + String(response.status) + ")");
            }
        }
        catch (err) {
            return Promise.reject(String(err));
        }
    }
    async upload_file(folio, id, file) {
        try {
            let formData = new FormData();
            formData.append("archivo", file);
            formData.append("tipo_documento", id);
            let response = await fetch(`${API_VERSION}/forms/${folio}/file`, {
                method: "POST",
                body: formData
            });
            if (response.status == 401) {
                // El inicio de sesión fue incorrecto
                this.user_info_unsafe = null;
                return Promise.reject("");
            }
            else if (response.status == 200) {
                let body_json = await response.json();
                return Promise.resolve(body_json["archivo"]["_name_file"]);
            }
            else {
                return Promise.reject("código de respuesta inesperado (" + String(response.status) + ")");
            }
        }
        catch (err) {
            return Promise.reject(String(err));
        }
    }
    async get_file(folio, id) {
        var _a, _b;
        try {
            let response = await fetch(`${API_VERSION}/forms/${folio}/file?type=${id}`);
            if (response.status == 401) {
                // El inicio de sesión fue incorrecto
                this.user_info_unsafe = null;
                return Promise.reject("");
            }
            else if (response.status == 200) {
                let content = await response.blob();
                let file_name = (_b = (_a = response.headers.get("Content-Disposition")) === null || _a === void 0 ? void 0 : _a.split(";")[1]) === null || _b === void 0 ? void 0 : _b.split("=")[1];
                if (file_name) {
                    let file = new File([content], file_name);
                    return Promise.resolve(file);
                }
                else {
                    return Promise.resolve(new File([content], "documento.pdf"));
                }
            }
            else {
                return Promise.reject("código de respuesta inesperado (" + String(response.status) + ")");
            }
        }
        catch (err) {
            return Promise.reject(String(err));
        }
    }
    /**
     * Si regresa la información se verifica la existencia de RFC en el regristro
     * @param rfc que se desea verificar en idC
     * @returns Pomise<any> Promesa de un valor any (provisional para pruebas)
     */
    async get_rfc_validation(rfc) {
        try {
            let response = await fetch(encodeURIComponent(`${API_VERSION}/rfc_validation/${rfc}`));
            if (response.status == 401) {
                return Promise.reject("Sesión no valida");
            }
            else if (response.status == 200) {
                return Promise.resolve(true);
            }
            else {
                return Promise.reject(false);
            }
        }
        catch (err) {
            return Promise.reject(String(err));
        }
    }
    /**
     * Si regresa la información se verifica la existencia de varios RFC en el regristro
     * @param rfcArray Lista de RFC que se desea verificar en IdC
     * @returns Pomise<Array<string>> Promesa de un valor arreglo de rfc, tipo string
     */
    async get_rfcs_validation(rfcArray) {
        let blackList = [];
        try {
            for (let rfc of rfcArray) {
                let encodedRFC = encodeURIComponent(rfc);
                let response = await fetch(encodeURIComponent(`${API_VERSION}/rfc_validation/${encodedRFC}`));
                if (response.status == 401) {
                    return Promise.reject("Sesión no valida");
                }
                else if (response.status == 200) {
                    // console.log(`El RFC: ${rfc} ha sido validado`)
                }
                else {
                    // console.log(`El RFC: ${rfc} No ha sido validado`)
                    blackList.push(rfc);
                }
            }
            return Promise.resolve(blackList);
        }
        catch (err) {
            return Promise.reject(String(err));
        }
    }
    /**
        * Entrega el nombre completo para un rfc dado
        * @param rfc RFC del que se desea obtener el nombre
        * @returns Pomise<FullName> Promesa que entrega una interface con el nombre completo
     */
    async get_name_from_rfc(rfc) {
        function validate_name_response(str) {
            let js = JSON.parse(str);
            let FullName = {
                Nombre: js["Nombre"],
                Ap_Paterno: js["Ap_Paterno"],
                Ap_Materno: js["Ap_Materno"],
                Razon_Soc: js["Razon_Soc"],
                t_Sociedad: js["t_Sociedad"]
            };
            return FullName;
        }
        try {
            let encodedRFC = encodeURIComponent(rfc);
            let response = await fetch(`${API_VERSION}/rfc_validation/${encodedRFC}/name`);
            if (response.status == 401) {
                return Promise.reject("Sesión invalida");
            }
            if (response.status == 404) {
                return Promise.resolve(null);
            }
            if (response.status == 200) {
                let jsonResponse = await response.json();
                return Promise.resolve(validate_name_response(JSON.stringify(jsonResponse)));
            }
        }
        catch (error) {
            return Promise.reject(String(error));
        }
    }
    /**
     * Obtiene la lista de esquemas presentados por el usuario en caso de que el rol sea Solicitant
     * Requiere que haya una sesión válida iniciada, en caso de no contar con una devuleve un error 403
     *
     * @param folio Folio de la solicitud a buscar
     * @param type_schema Tipo de esquema
     * @param year Año en que se presenta el esquema
     * @param page Página a obtener
     * @param fetch Cantidad de elementos por página
     * @returns Promise<DataTable<schema>
     */
    async get_all_tax(folio, type_schema, year, page, fetch) {
        return new Promise(async (resolve, reject) => {
            try {
                const user = await this.is_logged();
                if (user) {
                    this.apiJsonGet('/api/v1/all_tax_scheme?'
                        .concat(folio ? `&folio=${folio}` : '')
                        .concat(type_schema ? `&type_schema=${type_schema}` : '')
                        .concat(year ? `&year=${year}` : '')
                        .concat(page ? `&page=${page}` : '')
                        .concat(fetch ? `&fetch=${fetch}` : '')).then((response) => {
                        if (response.status == 200) {
                            // console.log(response.result)
                            resolve(response.result);
                        }
                        else {
                            reject(reject(response.messageError));
                        }
                    }).catch((err) => {
                        reject(err);
                    });
                }
                else {
                    return reject("No existe una sesión o esta ha expirado");
                }
            }
            catch (err_1) {
                reject(String(err_1));
            }
        });
    }
    /**
     * Obtiene la lista de esquemas presentados por el usuario en caso de que el rol sea Solicitant
     * Requiere que haya una sesión válida iniciada, en caso de no contar con una devuleve un error 403
     *
     * @param folio Folio de la solicitud a buscar
     * @param type_schema Tipo de esquema
     * @param year Año en que se presenta el esquema
     * @param page Página a obtener
     * @param fetch Cantidad de elementos por página
     * @returns Promise<DataTable<schema>
     */
    async get_all_tax_schema(folio, type_schema, year, page, fetch) {
        return new Promise(async (resolve, reject) => {
            try {
                const user = await this.is_logged();
                if (user) {
                    this.apiJsonGet('/api/v1/tax_scheme?'
                        .concat(folio ? `&folio=${folio}` : '')
                        .concat(type_schema ? `&type_schema=${type_schema}` : '')
                        .concat(year ? `&year=${year}` : '')
                        .concat(page ? `&page=${page}` : '')
                        .concat(fetch ? `&fetch=${fetch}` : '')).then((response) => {
                        if (response.status == 200) {
                            // console.log(response.result)
                            resolve(response.result);
                        }
                        else {
                            reject(reject(response.messageError));
                        }
                    }).catch((err) => {
                        reject(err);
                    });
                }
                else {
                    return reject("No existe una sesión o esta ha expirado");
                }
            }
            catch (err_1) {
                reject(String(err_1));
            }
        });
    }
    /**
     * Obtiene la lista de formularios guardados por el usuario usuario en caso de que el rol sea Solicitant
     * Requiere que haya una sesión válida iniciada, en caso de no contar con una devuleve un error 403
     *
     * @param folio Folio de la solicitud a buscar
     * @param type_schema Tipo de esquema
     * @param year Año en que se presenta el esquema
     * @param page Página a obtener
     * @param fetch Cantidad de elementos por página
     * @returns Promise<DataTable<schema>
     */
    async get_all_forms_schema(folio, type_schema, year, page, fetch) {
        return new Promise(async (resolve, reject) => {
            try {
                const user = await this.is_logged();
                if (user) {
                    this.apiJsonGet('/api/v1/forms?'
                        .concat(folio ? `&folio=${folio}` : '')
                        .concat(type_schema ? `&type_schema=${type_schema}` : '')
                        .concat(year ? `&year=${year}` : '')
                        .concat(page ? `&page=${page}` : '')
                        .concat(fetch ? `&fetch=${fetch}` : '')).then((response) => {
                        if (response.status == 200) {
                            resolve(response.result);
                        }
                        else {
                            reject(reject(response.messageError));
                        }
                    }).catch((err) => {
                        reject(err);
                    });
                }
                else {
                    return reject("No existe una sesión o esta ha expirado");
                }
            }
            catch (err_1) {
                reject(String(err_1));
            }
        });
    }
    /**
     * Obtiene la lista de formularios guardados por el usuario usuario en caso de que el rol sea Solicitant
     * Requiere que haya una sesión válida iniciada, en caso de no contar con una devuleve un error 403
     *
     * @param folio Folio de la solicitud a buscar
     * @param type_schema Tipo de esquema
     * @param year Año en que se presenta el esquema
     * @param page Página a obtener
     * @param fetch Cantidad de elementos por página
     * @returns Promise<DataTable<schema>
     */
    async get_tax_schema_json(folio) {
        return new Promise(async (resolve, reject) => {
            try {
                const user = await this.is_logged();
                if (user) {
                    this.apiJsonGet(`/api/v1/tax_scheme/${folio}/sing`).then((response) => {
                        if (response.status == 200) {
                            resolve(response.result._json_firmado || null);
                        }
                        else {
                            reject(reject(response.messageError));
                        }
                    }).catch((err) => {
                        reject(err);
                    });
                }
                else {
                    return reject("No existe una sesión o esta ha expirado");
                }
            }
            catch (err_1) {
                reject(String(err_1));
            }
        });
    }
    async get_tax(folio) {
        // Convierte una cadena (que debe ser un json) en un diccionario
        function from_string(target) {
            let js = JSON.parse(target);
            let myMap = new cs.SecureMap();
            // Todo lo que parezca una llave va a hacer match
            let myRegex = /\"([^"]+)\":/g;
            let foundKeys = target.match(myRegex);
            let keys = [];
            // Descartar las llaves que no se encuentran en el primer nivel
            foundKeys.forEach(function (key, idx, arr) {
                let k = key.split('"')[1];
                if (js.hasOwnProperty(k)) {
                    keys.push(k);
                }
            });
            const array_splitter = (content) => {
                content = content.trim();
                const ICONS = {
                    "{": "}",
                    "[": "]"
                };
                let idx = 0;
                let stack = [];
                let items = [];
                let quoted = false;
                if (content.length < 2 || content[0] != "[" || content[content.length - 1] != "]") {
                    // console.log("Error", content);
                    throw "La cadena no es un array válido";
                }
                content = content.slice(1, content.length - 1).trim();
                while (content.length != 0 && idx < content.length) {
                    if (content[idx] == "\\") {
                        idx += 1;
                        if (idx == content.length)
                            throw "El json tiene un caracter de escape sin consecuencia";
                    }
                    else if (content[idx] == "\"") {
                        quoted = !quoted;
                    }
                    else if (!quoted) {
                        let curr_icon = ICONS[content[idx]];
                        if (curr_icon != undefined)
                            stack.push(curr_icon);
                        else if (content[idx] == stack[stack.length - 1])
                            stack.pop();
                        else if (content[idx] == "," && stack.length == 0) {
                            items.push(content.slice(0, idx));
                            content = content.slice(idx + 1).trim();
                            idx = -1;
                        }
                    }
                    idx += 1;
                }
                ;
                if (quoted)
                    throw "Parece que el json contiene comillas sin cerrar";
                if (stack.length != 0)
                    throw "La cadena no parece ser un json válido";
                if (content.length != 0)
                    items.push(content.trim());
                return items;
            };
            //Alimentamos el map. En caso de que el valor de una llave sea un objeto, verifica si, asu vez, se tiene que convertir en map
            keys.forEach(function (key, idx) {
                let first_cut = '"' + key + '":';
                let second_cut = keys[idx + 1];
                let vk = target.split(first_cut)[1].split(second_cut)[0].slice(0, -1).replace(/,*$/, "");
                // console.log(`Cadena original  ${target}`)
                // console.log(`Partiendo la cadena original entre '${first_cut}' y '${second_cut}'`);
                // console.log(`Cadena resultado  ${vk}`)
                if (Object.prototype.toString.call(js[key]) == "[object Object]") {
                    let mm = from_string(vk);
                    myMap.set(key, mm);
                }
                else if (Object.prototype.toString.call(js[key]) == "[object Array]") {
                    for (var i = 0; i < js[key].length; i++) {
                        if (Object.prototype.toString.call(js[key][i]) == "[object Object]") {
                            js[key] = array_splitter(vk).map(target => from_string(target));
                        }
                    }
                    myMap.set(key, js[key]);
                }
                else {
                    myMap.set(key, js[key]);
                }
            });
            return myMap;
        }
        try {
            let response = await fetch(`/api/v1/tax_scheme/${folio}/sign`, {
                method: "GET"
            });
            if (response.status == 401) {
                // El inicio de sesión fue incorrecto
                this.user_info_unsafe = null;
                return Promise.reject("");
            }
            else if (response.status == 200) {
                let tax_json = await response.json();
                let result = schema.from_map(tax_json.result);
                if (result) {
                    if (result._json_declaracion) {
                        let final = from_string(result._json_declaracion.slice(1, result._json_declaracion.length - 1).replace(/\\"/g, "\""));
                        if (cs.utils.isError(final)) {
                            return Promise.reject(final);
                        }
                        else {
                            return Promise.resolve(final);
                        }
                    }
                    else {
                        return Promise.resolve(null);
                    }
                }
                else {
                    return Promise.reject("La respuesta no tiene la estructura adecuada");
                }
            }
            else {
                return Promise.reject("código de respuesta inesperado (" + String(response.status) + ")");
            }
        }
        catch (err) {
            return Promise.reject(String(err));
        }
    }
    /**
     * Obtiene la información necesaria para determinar si el usuario tiene permitidio presentar un esquema complementario. En cualquier situación nos dice el nivel de autorización que tiene con los niveles:
     *  NOT_ALLOWED => No está permitido para presentar la complementaria ni la precarga
     *  HALF_ALLOWED => Está permitido para la precarga, pero no la complementaria
     *  FULL_ALLOWED => Está permitido para la precarga y para la complementaria
     *
     * Requiere que haya una sesión válida iniciada.
     *
     * @param folio Folio de la solicitud a buscar
     * @returns Promise<ResultAllow>
     */
    async get_tax_schema_allow(folio, nier) {
        return new Promise(async (resolve, reject) => {
            try {
                const user = await this.is_logged();
                if (user) {
                    this.apiJsonPost(`/api/v1/tax_scheme/${folio}/allow-complementary`, {
                        nier
                    }).then((response) => {
                        if (response.status == 200) {
                            // console.log(response.result)
                            resolve(response.result);
                        }
                        else {
                            reject(reject(response.messageError));
                        }
                    }).catch((err) => {
                        reject(err);
                    });
                }
                else {
                    return reject("No existe una sesión o esta ha expirado");
                }
            }
            catch (err_1) {
                reject(String(err_1));
            }
        });
    }
    // async sign_form(form: cs.SecureMap, folio: string, original?: string, firmada?: string): Promise<string> {
    async sign_form(form, folio, originalChain, signedChain) {
        return new Promise(async (resolve, reject) => {
            try {
                const user = await this.is_logged();
                if (user) {
                    this.apiJsonPost(`${API_VERSION}/forms/${folio}/sign?originalChain=${encodeURIComponent(originalChain)}&signedChain=${encodeURIComponent(signedChain)}`, {
                        json: form.serialize()
                    }).then((response) => {
                        if (response.status == 200) {
                            // resolve(response.result._numero_identificacion_esquema);
                            resolve(response.result);
                        }
                        else {
                            reject(response.messageError);
                        }
                    }).catch((err) => {
                        reject(`Código de respuesta inesperado ( ${String(err)})`);
                    });
                }
                else {
                    return reject("No existe una sesión o esta ha expirado");
                }
            }
            catch (err_1) {
                reject(`código de respuesta inesperado ( ${String(err_1)})`);
            }
        });
    }
    solicitude_exists(type, typeSchema, fiscalExerise) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await this.apiJsonPost(`${API_VERSION}/forms/exist`, {
                    type,
                    type_schema: typeSchema,
                    fiscal_exercise: fiscalExerise
                });
                if (res.result)
                    resolve(res.result);
                resolve(null);
            }
            catch (e) {
                // console.log('clean')
                reject(e);
            }
        });
    }
    delete_solicitude(folio) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await this.apiJsonDelete(`${API_VERSION}/forms/${folio}`);
                // console.log(res)
                resolve(null);
            }
            catch (e) {
                reject(e);
            }
        });
    }
}
