<script lang="ts">
    import { Router, Route, navigate, useParams } from "svelte-navigator";
    import { fly, fade } from 'svelte/transition';

    import { api } from "../Api";
    // Screens
    import NewStatement from './Statements/New.svelte';
    import PreviousStatement from "./Statements/Previous.svelte";
    import CurrentStatment from "./Statements/Current.svelte";
    import ENR from "../components/enr/ENR.svelte";
    import ERG from "../components/erg/ERG.svelte";
    import ERA from "../components/era/ERA.svelte";
    import ERP from "../components/erp/ERP.svelte";
    import Temp from "../Temp.svelte";

    import Navbar from '../components/ui/Navbar.svelte';
    import type { NavbarData, OptionsData } from '../components/ui/Navbar.svelte';
import Empleado from "./Admin/Empleado.svelte";
    
    let nbdata: NavbarData = {
      rfc: "N/A",
      name: "N/A"
    }
    let navbarOptions: Array<OptionsData> = [
      { display: 'Inicio' },
      { display: 'Declaraciones y constancias',
        sub: [ 
          {display: 'Nueva', icon: 'fas fa-plus-circle'},
          {display: 'Pendientes', icon: 'fas fa-adjust'},
          {display: 'Presentadas', icon: 'fas fa-history'}
        ] 
      },
      { display: 'Cerrar sesión' },
    ];

    const navigatorHandler = (optionText) => {
      if(optionText === "Inicio" || optionText === "Nueva") {
        navigate("/")
      } else if ( optionText === "Pendientes") {
        navigate("/pending")
      } else if ( optionText === "Presentadas") {
        navigate("/previous")
      } else if ( optionText === "Cerrar sesión") {
        api().logout();
      }
    }

    api().user_info().then(response => {
      if(response != null) {
        nbdata = {
          rfc: response.rfc,
          name: response.nombre
        }
      }
    })

</script>

<main style="height:100%;">
  <Router primary={false}>
      <Navbar 
        data={nbdata} 
        onClick={ navigatorHandler }
        options={ navbarOptions }
      />

      <div class="content">
        <Route path="/">
          <NewStatement />
        </Route>
        <Route path="/empleado">
          <Empleado />
        </Route>
        <Route path="/previous">
          <PreviousStatement />
        </Route>
        <Route path="/pending">
          <CurrentStatment/>
        </Route>
        <Route path="/enr/:declarationid" let:params>
          <ENR declarationid={params.declarationid}/>
        </Route>
        <Route path="/enr">
          <ENR/>
        </Route>
        <Route path="/erg/:declarationid" let:params>
          <ERG declarationid={params.declarationid}/>
        </Route>
        <Route path="/erg" >
          <ERG/>
        </Route>
        <Route path="/erp/:declarationid" let:params>
          <ERP declarationid={params.declarationid}/>
        </Route>
        <Route path="/erp" >
          <ERP/>
        </Route>
        <Route path="/era/:declarationid" let:params>
          <ERA declarationid={params.declarationid}/>
        </Route>
        <Route path="/era">
          <ERA/>
        </Route>
        <Route path="/pdf">
          <Temp/>
        </Route>
      </div>
  </Router>
</main>

<style>

    main .content {
        box-sizing: border-box;
        padding: 40px 2em;
        margin: 0 auto;
        max-width: 1920px;
    }

</style>
