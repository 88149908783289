<script lang="ts">
	import Button4 from './Button4.svelte';
	
	export let showDeclarations: boolean = false;
</script>


<div class="admin-aside">
	<Button4
		label="Mostrar todas las declaraciones"
		on:click={() => {
			showDeclarations = !showDeclarations;
		}}
	>
		<i class="fa-solid fa-bars"></i>
	</Button4>
</div>

<style>
	.admin-aside {
		display: flex;
		flex-direction: column;
		/* justify-content: column; */
		gap: 10px;
	}
</style>