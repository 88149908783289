import App from "./App.svelte";
var app = new App({
    target: document.body,
});
console.log(`%c
░█▀▀█ ▒█▀▀█ ▒█▀▀█ ▀▀█▀▀ ▀█▀ 　 ░░ 　 █▀█ █▀▀█ █▀█ ▄█░ 
▒█▄▄█ ▒█░▄▄ ▒█░░░ ░▒█░░ ▒█░ 　 ▀▀ 　 ░▄▀ █▄▀█ ░▄▀ ░█░ 
▒█░▒█ ▒█▄▄█ ▒█▄▄█ ░▒█░░ ▄█▄ 　 ░░ 　 █▄▄ █▄▄█ █▄▄ ▄█▄

» 🄲🄶🄼🄵
» 🄻🄷🄲🄱
» 🄹🄵🅀🅅
» 🄴🅂🄷
» 🄰🅁🄰🄿

`, 'color: #054987; ');
export default app;
