<!--D Componente de muestra de pasos a seguir -->
<script lang="ts">
    export let tags: string[] = [] // Textos de cada paso
    export let step: number = 0 // Número de paso en el que se encuentra
</script>

<div class="Stepper">
    <ul class="progressbar">
        {#each tags as tag, i}
            <li 
              class:active={step + 1> i} >
              <div class='number' >
                <div class="circle">
                  {i + 1}
                </div>
              </div>
              <div class='tag' > {tag} </div>          
            </li>
        {/each}
    </ul>
</div>

<style>
    .progressbar li:first-child:after {
        content: none;
    }


</style>

<!--E
<Stepper step={2} tags={['Formulario', 'Vista preliminar', 'Firma', 'Acuse' ]}  />
 -->
