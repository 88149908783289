<script lang="ts">
    export let label: string = "";
    export let fontSize: string = "13px";

    let activateHover: boolean = false;
</script>

<div 
    class="button"
    on:click 
    on:mouseleave 
    on:mousemove
    on:mousemove={() => activateHover = true}    
    on:mouseleave={() => activateHover = false}    
>
    <div class="wrapper">
        <div class="img-container" class:activateHover>
            <slot/>
        </div>
        <p style="font-size: {fontSize};">{label}</p>
    </div>
</div>

<style>

    .button {
        cursor: pointer;
        color: var(--text-color);
        transition: all 0.3s;
    }

    .button:hover {
        color: var(--dark-red);
    }

    .button div {
        display: flex;
        gap: 20px;
    }

    .button .wrapper {
        display: flex;

        align-items: center;
        justify-content: center;
    }

    .wrapper p {
        font-size: 15px;
    }

    .img-container {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s;
        color: #fff;
        width: 30px;
        height: 30px;
        background: #9d2449;
        border-radius: 50%;
    }

    .activateHover {
        box-shadow: 0 2px 4px #000;
    }

    .img-container img {
        width: 35px;
        height: 35px;
    }
</style>