// Función para determinar si un objeto acata la interfaz OAuthFirst
export function instanceOfOF(object) {
    return 'authentication_url' in object && 'response_type' in object && 'resource_server' in object && 'state' in object && 'client_id' in object && 'redirect_uri' in object && 'scope' in object;
}
// Función para determinar si un objeto acata la interfaz OAuthFirst
export function instanceOfOS(object) {
    return 'code' in object && 'state' in object;
}
export var schema;
(function (schema) {
    function from_map(value) {
        if (value._folio_declaracion == undefined) {
            throw "No se encontró el campo _folio_declaracion";
        }
        if (value._rfc == undefined) {
            throw "No se encontró el campo _rfc";
        }
        if (value._id_tipo_declaracion == undefined) {
            throw "No se encontró el campo _id_tipo_declaracion";
        }
        if (value._id_estado_declaracion == undefined) {
            throw "No se encontró el campo _id_estado_declaracion";
        }
        if (value._id_ejercicio == undefined) {
            throw "No se encontró el campo _id_ejercicio";
        }
        if (value._json_declaracion == undefined) {
            throw "No se encontró el campo _json_declaracion";
        }
        if (value._archivos == undefined) {
            throw "No se encontró el campo _archivos";
        }
        if (value._fecha_actualizacion == undefined) {
            throw "No se encontró el campo _fecha_actualizacion";
        }
        if (value._fecha_registro == undefined) {
            throw "No se encontró el campo _fecha_registro";
        }
        return {
            _folio_declaracion: value._folio_declaracion,
            _rfc: value._rfc,
            _id_tipo_declaracion: value._id_tipo_declaracion,
            _id_estado_declaracion: value._id_estado_declaracion,
            _id_ejercicio: value._id_ejercicio,
            _json_declaracion: value._json_declaracion,
            _archivos: value._archivos,
            _fecha_actualizacion: value._fecha_actualizacion,
            _fecha_registro: value._fecha_registro
        };
    }
    schema.from_map = from_map;
})(schema || (schema = {}));
// Función para determinar si un objeto acata la interfaz OAuthFirst
export function instanceOfUI(object) {
    return 'rfc' in object && 'nombre' in object && 'tipo' in object && 'role' in object;
}
//Tipos de declaración
export const TIPO_DECLARACION = {
    "1": "Normal",
    "2": "Complementaria"
};
//Tipos de esquema reportable
export const TIPO_ESQUEMA = {
    "ENR": 'ENR',
    "ERG": 'ERG',
    "ERP": 'ERP',
    "ERA": 'ERA',
};
//Tipos de esquema reportable
export var CODIGO_PERSONA;
(function (CODIGO_PERSONA) {
    CODIGO_PERSONA[CODIGO_PERSONA["CON"] = 1] = "CON";
    CODIGO_PERSONA[CODIGO_PERSONA["ASF"] = 2] = "ASF";
})(CODIGO_PERSONA || (CODIGO_PERSONA = {}));
export var CODIGO_TIPO_ESQUEMA;
(function (CODIGO_TIPO_ESQUEMA) {
    CODIGO_TIPO_ESQUEMA[CODIGO_TIPO_ESQUEMA["ENR"] = 1] = "ENR";
    CODIGO_TIPO_ESQUEMA[CODIGO_TIPO_ESQUEMA["ERG"] = 2] = "ERG";
    CODIGO_TIPO_ESQUEMA[CODIGO_TIPO_ESQUEMA["ERP"] = 3] = "ERP";
    CODIGO_TIPO_ESQUEMA[CODIGO_TIPO_ESQUEMA["ERA"] = 4] = "ERA";
})(CODIGO_TIPO_ESQUEMA || (CODIGO_TIPO_ESQUEMA = {}));
//Tipos de permiso para presentar la complementaria
export var ResultAllow;
(function (ResultAllow) {
    ResultAllow["NOT_ALLOWED"] = "not_allowed";
    ResultAllow["HALF_ALLOWED"] = "half_allowed";
    ResultAllow["FULL_ALLOWED"] = "full_allowed";
})(ResultAllow || (ResultAllow = {}));
