<script lang="ts">
    import { onMount } from "svelte";
    import { api } from "../../Api";
    import { FullName, TIPO_ESQUEMA, UserInfo, viewSchema } from "../../Api/objects";
    import Empty from "../../components/ui/Empty.svelte";
    import Loader from "../../components/ui/Loader.svelte";
    import type { PaginatorSchema } from "../../components/ui/Paginator.svelte";
    import Paginator from "../../components/ui/Paginator.svelte";
    import Table from "../../components/ui/Table.svelte";
    import Title from "../../components/ui/Title.svelte";
    import AdminAside from "./admin-aside/AdminAside.svelte";

    import { write as writeENR } from "../../components/enr/pdf";
	import { write as writeERA } from "../../components/era/pdf";
	import { write as writeERG } from "../../components/erg/pdf";
	import { write as writeERP } from "../../components/erp/pdf";

	import type * as cs from "cryptosat-ts";
    import Copia from "../../components/pdfs/copia";
    import Constancia from "../../components/pdfs/constancia";
    import Certificado from "../../components/pdfs/certificado";
    import Acuse from "../../components/pdfs/acuse";


    let selectedRows = 10;
    let loading = false;
    let schema: PaginatorSchema = { page: 1, pages: 0, total: 0 };
    let list_schema: viewSchema[] = [];
    let admin: boolean = false;

    admin = true;
    let showDeclarations: boolean = false;

    async function fetchSchemas() {
        loading = true;
        try {
            let data = await api().get_all_tax(
                null,
                null,
                null,
                schema.page,
                selectedRows
            );

            list_schema = data.results;
            schema = data.pager;
            loading = false;
        } catch (error) {
            console.log("Error al intereactuar con los esquemas");
        }
    }

    fetchSchemas();

    const onSchemaChange = (updatedSchema: PaginatorSchema) => {
		loading = true;
		schema = updatedSchema;
		fetchSchemas();
	};

	const onRowsChange = (nRows) => {
		if (selectedRows !== nRows) {
			loading = true;
			selectedRows = nRows;   
			fetchSchemas();
		}
	};

    // Esta funcion tiene parametros adicionales que no son usados por estar en una version de prueba
	async function create_from_json(
		downloadType: string,
		schema: viewSchema
        ) {
        
        // TODO: Usar el nombre correcto para el que crea el PDF, sujeto_revela no es el correcto

        let fullName: FullName | undefined = undefined;
        fullName = await api().get_name_from_rfc(schema._rfc);

		let tipo_esquema: string
        let jsonableString: cs.SecureMap;
        try {
            jsonableString = await api().get_form(schema._folio_declaracion);
        } catch (error) {
            console.log("Ha ocurrido un error con get_tax")
        }
		
        
        // let jsonableString: cs.SecureMap = await api().get_form(schema._folio_declaracion);


		if (schema._codigo_esquema == "ERG") {
			tipo_esquema = "Generalizado"
		} else if (schema._codigo_esquema == "ERP") {
			tipo_esquema = "Personalizado" 
		} else if (schema._codigo_esquema == "ERA") {
			tipo_esquema = "Declaración Anual"
        }

		if (downloadType == "acuse") {

			// console.log("Estas visitando el acuse");
			let doc = new Acuse({
				rfc: schema._rfc,
				denominacion_social: schema._rfc.length == 12 ? fullName.Razon_Soc : undefined,
				nombre: schema._rfc.length == 13 ? `${fullName.Nombre} ${fullName.Ap_Paterno} ${fullName.Ap_Materno}` : undefined,
				tipo: "Normal",
				esquema: tipo_esquema,
				fecha: new Date(schema._fecha_registro),
				cadena: schema._cadena_original,
				num_operacion: schema._folio_declaracion,
				id_operacion: schema._numero_identificacion_esquema,
				ejercicio: schema._ejercicio,
				firma: schema._cadena_firmada
			});

			doc.agrega_qr("https://esquemas.sat.gob.mx");
			doc.printHeader(false);
			doc.save(`${downloadType}_${tipo_esquema}.pdf`);

		} else if (downloadType == "constancia") {
			let doc = new Constancia({
				rfc: schema._rfc,
				denominacion_social: schema._rfc.length == 12 ? fullName.Razon_Soc : undefined,
				nombre: schema._rfc.length == 13 ? `${fullName.Nombre} ${fullName.Ap_Paterno} ${fullName.Ap_Materno}` : undefined,
				tipo: "Normal",
				esquema: tipo_esquema,
				fecha: new Date(schema._fecha_registro),
				cadena: schema._cadena_original,
				num_operacion: schema._folio_declaracion,
				id_operacion: schema._numero_identificacion_esquema,
				ejercicio: schema._ejercicio,
				firma: schema._cadena_firmada
			});
			writeENR(doc, jsonableString)
			doc.agrega_qr("https://esquemas.sat.gob.mx");
			doc.printHeader(false);
			doc.save(`${downloadType}_${tipo_esquema}.pdf`);

		} else if (downloadType == "certificado") {
			let doc = new Certificado({
				rfc: schema._rfc,
				denominacion_social: schema._rfc.length == 12 ? fullName.Razon_Soc : undefined,
				nombre: schema._rfc.length == 13 ? `${fullName.Nombre} ${fullName.Ap_Paterno} ${fullName.Ap_Materno}` : undefined,
				tipo: "Normal",
				esquema: tipo_esquema,
				fecha: new Date(schema._fecha_registro),
				cadena: schema._cadena_original,
				num_operacion: schema._folio_declaracion,
				id_operacion: schema._numero_identificacion_esquema,
				ejercicio: schema._ejercicio,
				firma: schema._cadena_firmada
			});
			doc.printHeader(false);
			doc.save(`${downloadType}_${tipo_esquema}.pdf`);
		} 
	}

    /* Función para poder descargar el Acuse o Constancia en PDF, según los parámetros ingresados */
	const download_tax = (
		schema: viewSchema,
		downloadType: string
	) => {
		let tipo_esquema: string
		api().get_all_tax(schema._folio_declaracion).then((jsonForm) => {
			// jsonForm ya es el JsonableMap/cs.SecureMap que escribe un archivo PDF
			if (downloadType == "acuse") {
				create_from_json(downloadType, schema);
			} else if (downloadType == "constancia") {
				create_from_json(downloadType, schema);
			} else if (downloadType == "certificado") {
				create_from_json(downloadType, schema);
			}
		});
	};

    async function download_Copia(
		schema: viewSchema,
		previous?: boolean
	) {
        let tipo_esquema: string;
		let fullName: FullName | undefined = undefined;
        fullName = await api().get_name_from_rfc(schema._rfc);
		let jsonableString: cs.SecureMap = await api().get_form(schema._folio_declaracion);

		if (schema._codigo_esquema == "ERG") {
			tipo_esquema = "Generalizado"
		} else if (schema._codigo_esquema == "ERP") {
			tipo_esquema = "Personalizado" 
		} else if (schema._codigo_esquema == "ERA") {
			tipo_esquema = "Declaración Anual"
		}		
		


		let doc = new Copia({
			rfc: schema._rfc,
			denominacion_social: schema._rfc.length == 12 ? fullName.Razon_Soc : undefined,
			nombre: schema._rfc.length == 13 ? `${fullName.Nombre} ${fullName.Ap_Paterno} ${fullName.Ap_Materno}` : undefined,
			tipo: "Normal",
			esquema: tipo_esquema,
			fecha: new Date(),
			cadena: schema._cadena_original,
            num_operacion: schema._folio_declaracion,
            id_operacion: schema._numero_identificacion_esquema,
            ejercicio: schema._ejercicio,
			copia: true,
			firma: "cadenaFirmada",
			previous: previous,
		});

		switch (schema._codigo_esquema) {
			case TIPO_ESQUEMA.ENR:
				writeENR(doc, jsonableString);
				break;
			case TIPO_ESQUEMA.ERA:
				writeERA(doc, jsonableString);
				break;
			case TIPO_ESQUEMA.ERG:
				writeERG(doc, jsonableString);
				break;
			case TIPO_ESQUEMA.ERP:
				writeERP(doc, jsonableString);
				break;
			default:
				alert(`Descargando la copia`);
				break;
		}
		doc.agrega_qr("https://esquemas.sat.gob.mx");
		doc.printHeader(false);
		doc.save(`Copia_${tipo_esquema}.pdf`);
	}

    let userInfo: UserInfo | undefined = undefined;

    onMount(async () => {
        userInfo = await api().user_info();
    });
</script>


{#if userInfo && userInfo.role == "2"}
    <Title 
        icon="fas fa-solid fa-user-tie"
        size="normal"
    >
        Panel de empleados
    </Title>

    <div class="main-admin">
        <div class="admin-aside">
            <AdminAside bind:showDeclarations/>
        </div>

        <div class="admin-section">
            {#if showDeclarations}
                <div id="PreviousStatement">
                    {#if loading}
                        <div class="fewContent">
                            <Loader />
                        </div>
                    {:else if list_schema.length > 0}
                        <div class="table" style="width:100%">
                            <Table>
                                <tr slot="header">
                                    <th style="width:20%"> Documento </th>
                                    <th> Número de identificación </th>
                                    <th> Número de operación </th>
                                    <th> Fecha de presentación </th>
                                    <th style="width:10%"> RFC </th>
                                    <th> Acciones (Descarga)</th>
                                </tr>
                                {#each list_schema as schema}
                                    <tr>
                                        {#if schema._codigo_esquema == "ENR"}
                                            <td>
                                                Constancia de Esquema no
                                                reportable
                                            </td>
                                        {:else if schema._codigo_esquema == "ERP" || schema._codigo_esquema == "ERG"}
                                            {#if schema._tipo_declaracion == "Complementaria"}
                                                <td>
                                                    Declaración informativa
                                                    complementaria para revelar
                                                    un {schema._codigo_esquema}
                                                </td>
                                            {:else}
                                                <td>
                                                    Declaración informativa
                                                    para revelar un {schema._codigo_esquema}
                                                </td>
                                            {/if}
                                        {:else if schema._codigo_esquema == "ERA"}
                                            {#if schema._tipo_declaracion == "Complementaria"}
                                                <td>
                                                    Declaración anual
                                                    complementaria
                                                </td>
                                            {:else}
                                                <td>Declaración anual</td>
                                            {/if}
                                        {/if}

                                        <td>
                                            {schema._codigo_esquema == "ERA"
                                                ? "N/A"
                                                : schema._codigo_esquema ==
                                                  "ENR"
                                                ? "N/A"
                                                : schema._numero_identificacion_esquema}
                                        </td>
                                        <td> {schema._folio_declaracion} </td>
                                        <td>
                                            {new Date(
                                                schema._fecha_registro
                                            ).toLocaleDateString()}
                                        </td>
                                        <td> {schema._rfc} </td>
                                        <td style="display: flex; justify-content: center; gap: 10%">
                                            {#if schema._codigo_esquema == "ENR"}
                                                <div
                                                    class="down-button"
                                                    style="cursor: pointer;"
                                                    on:click={() => {
                                                        download_tax(schema, "constancia");
                                                    }}
                                                >
                                                    <i class="fas fa-download"/>
                                                    <div class="button-label">
                                                        Constancia
                                                    </div>
                                                </div>
                                            {:else if schema._codigo_esquema == "ERP" || schema._codigo_esquema == "ERG"}
                                                {#if schema._tipo_declaracion == "Complementaria"}
                                                    <div
                                                        class="down-button"
                                                        style="cursor: pointer;"
                                                        on:click={() => {
                                                            download_tax(schema, "acuse");
                                                        }}
                                                    >
                                                        <i class="fas fa-download"/>
                                                        <div class="button-label">
                                                            Acuse
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="down-button"
                                                        style="cursor: pointer;"
                                                        on:click={() => {
                                                            download_Copia(
                                                                schema,
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        <i class="fas fa-download"/>
                                                        <div class="button-label">
                                                            Copia
                                                        </div>
                                                    </div>
                                                {:else}
                                                    <div
                                                        class="down-button"
                                                        style="cursor: pointer;"
                                                        on:click={() => {
                                                            // console.log(schema._cadena_firmada)
                                                            download_tax(
                                                                schema,
                                                                "acuse"
                                                            );
                                                        }}
                                                    >
                                                        <i class="fas fa-download"/>
                                                        <div class="button-label">
                                                            Acuse
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="down-button"
                                                        style="cursor: pointer;"
                                                        on:click={() => {
                                                            download_tax(
                                                                schema,
                                                                "certificado"
                                                            );
                                                        }}
                                                    >
                                                        <i class="fas fa-download"/>
                                                        <div class="button-label">
                                                            Certificado
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="down-button"
                                                        style="cursor: pointer;"
                                                        on:click={() => {
                                                            download_Copia(
                                                                schema,
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        <i class="fas fa-download"/>
                                                        <div class="button-label">
                                                            Copia
                                                        </div>
                                                    </div>
                                                {/if}
                                            {:else if schema._codigo_esquema == "ERA"}
                                                {#if schema._tipo_declaracion == "Complementaria"}
                                                    <div
                                                        class="down-button"
                                                        style="cursor: pointer;"
                                                        on:click={() => {
                                                            download_tax(
                                                                schema,
                                                                "acuse"
                                                            );
                                                        }}
                                                    >
                                                        <i class="fas fa-download"/>
                                                        <div class="button-label">
                                                            Acuse
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="down-button"
                                                        style="cursor: pointer;"
                                                        on:click={() => {
                                                            download_Copia(
                                                                schema,
                                                                true,
                                                            );
                                                        }}
                                                    >
                                                        <i class="fas fa-download" />
                                                        <div class="button-label">
                                                            Copia
                                                        </div>
                                                    </div>
                                                {:else}
                                                    <div
                                                        class="down-button"
                                                        style="cursor: pointer;"
                                                        on:click={() => {
                                                            download_tax(
                                                                schema,
                                                                "acuse"
                                                            );
                                                        }}
                                                    >
                                                        <i class="fas fa-download"/>
                                                        <div class="button-label">
                                                            Acuse
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="down-button"
                                                        style="cursor: pointer;"
                                                        on:click={() => {
                                                            download_Copia(
                                                                schema,
                                                                true,
                                                            );
                                                        }}
                                                    >
                                                        <i class="fas fa-download"/>
                                                        <div class="button-label">
                                                            Copia
                                                        </div>
                                                    </div>
                                                {/if}
                                            {/if}
                                        </td>
                                    </tr>
                                {/each}
                            </Table>

                            <div style="margin: 15px;"/>

                            <Paginator
                                {schema}
                                {selectedRows}
                                {onSchemaChange}
                                {onRowsChange}
                            />
                        </div>
                    {:else}
                        <div class="fewContent">
                            <Empty size="small">No se encontraron registros</Empty>
                        </div>
                    {/if}
                </div>
            {:else}
                <!-- <div class="empleado-loader">
                    <Loader message="Panel de empleados" load={false} />
                </div> -->
            {/if}
        </div>
    </div>
{:else}
    <!-- <div class="error-message">Error de autenticación</div> -->
{/if}

<style>
    .main-admin {
        width: calc(100vw - 5%);
        display: grid;
        grid-template-columns: 0.9fr 3fr;
    }

    .admin-section {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        justify-content: center;
    }
    .admin-aside {
        padding: 20px;
    }
</style>
