<script>
	import { getContext } from 'svelte';
	import { TABS } from './Tabs.svelte';

	const tab = {};
	const { registerTab, selectTab, selectedTab } = getContext(TABS);

	registerTab(tab);
</script>

<button class:selected="{$selectedTab === tab}" on:click="{() => selectTab(tab)}">
	<slot></slot>
</button>

<style>
	button {
		background: none;
		padding: 0.5rem 1rem;
		border: none;
		border-top: 2px solid transparent;
		margin: 5px;
		color: rgb(75, 70, 70);
		border: 1px solid #6e246e;
		border-radius: 5px;
		flex-grow: 1;
		transition-duration: 300ms;
	}

	button:hover {
		background-color: #f0eae0;
	}
	
	.selected {
		background-color:#9e2151;
		color: #ebddd3;
	}

	.selected:hover {
		background-color: #9e2151;
	}
</style>